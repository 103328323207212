    import React, { useEffect, useState } from 'react';

    import styles from "./TournamentPage.module.scss"

    import { useIntl } from 'react-intl';
    import { useDispatch } from 'react-redux';
    import commonMessages from "../../common/messages";

    import Card from 'react-bootstrap/Card';
    import Breadcrumb from 'react-bootstrap/Breadcrumb'

    import Jumbotron from 'react-bootstrap/Jumbotron';
    import Container from 'react-bootstrap/Container';
    import Spinner from 'react-bootstrap/Spinner';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
    import { faHome } from '@fortawesome/free-solid-svg-icons'

    import { fetchTournamentPlayers, openNewPlayerModal } from "../../redux/actions";

    import firebase from '../../Firebase';
    import NewPlayerModal from './NewPlayerModal';

    function TournamentPage(props) {
        const intl = useIntl();
        const dispatch = useDispatch();
        const [players, setPlayers] = useState([]);
        const [tournament, setTournament] = useState([]);
        const [teams, setTeams] = useState([]);
        const [admins, setAdmins] = useState([]);
        const [loading, setLoading] = useState(false);
        const user = firebase.auth().currentUser;
        const tournamentId = props && props.match && props.match.params && parseInt(props.match.params.tournamentId);

        function handleOpenModal() {
            dispatch(openNewPlayerModal());
        };

        function renderPlayer(playerlist, index) {
            if (!playerlist) return null;
            return <Card key={index} className={styles.Card}>
                {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
                <Card.Body>
                    <Card.Text>
                        {"・Player 1 : "}
                        <a target='_blank' href={`/players/${playerlist.player_id_1}`}>{players[playerlist.player_id_1].name}</a>
                    </Card.Text>
                    <Card.Text>
                        {"・Player 2 : "}
                        <a target='_blank' href={`/players/${playerlist.player_id_2}`}>{players[playerlist.player_id_2].name}</a>
                    </Card.Text>
                    <Card.Text>
                        &nbsp;&nbsp;&nbsp;{"Club : "}
                        {getTeamFromPlayeId(playerlist.player_id_1, playerlist.player_id_2)}
                    </Card.Text>
                </Card.Body>
            </Card>;
        }

        function renderNationalPlayer(playerlist, index) {
            if (!playerlist) return null;
            return <Card key={index} className={styles.Card}>
                {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
                <Card.Body>
                    <Card.Text>
                        {"・Player 1 : "}
                        <a target='_blank' href={`/players/${playerlist.player_id_1}`}>{players[playerlist.player_id_1].name}</a>
                    </Card.Text>
                    <Card.Text>
                        {"・Player 2 : "}
                        <a target='_blank' href={`/players/${playerlist.player_id_2}`}>{players[playerlist.player_id_2].name}</a>
                    </Card.Text>
                    <Card.Text>
                        &nbsp;&nbsp;&nbsp;{"Nation : "}
                        {players[playerlist.player_id_1].nation}
                    </Card.Text>
                </Card.Body>
            </Card>;
        }

        function getTeamFromPlayeId(id_1, id_2) {
            if(!teams.length) {return 'unknow'}

            if(players[id_1].team_id == players[id_2].team_id) {
                return teams[players[id_1].team_id].name
            }

            return 'Liên Quân'
        }

        function renderLevel(level, index) {
            if (!level) return null;

            level.draw = level.draw.filter(elm => elm);
            if (index == 0) {
                return ;
            }

            if (tournamentId == 1 && index == 1) {
                return <div key={index}>
                        <span className={styles.LevelTitle}>{level.name} ({level.draw.length} pairs)</span>
                        <div className={styles.LevelPage}>
                        {level.draw.map((e, index) => renderNationalPlayer(e, index))}
                        </div>
                </div>;
            }

            return <div key={index}>
                    <span className={styles.LevelTitle}>{level.name} ({level.draw.length} pairs)</span>
                    <div className={styles.LevelPage}>
                    {level.draw.map((e, index) => renderPlayer(e, index))}
                    </div>
            </div>;
        }

        useEffect(() => {
            setLoading(true);
            dispatch(fetchTournamentPlayers(tournamentId))
                .then(res => {
                    setLoading(false);
                    const results = res && res.payload && res.payload.response
                        && res.payload.response.result || {};

                    setPlayers(results[0]);
                    setTournament(results[1]);
                    setTeams(results[2]);
                    setAdmins(results[3]);
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                });
        }, []);

        if (loading) {
            return <Spinner animation="border" variant="primary" />;
        }

        if (tournament && tournament.level && user && admins.includes(user.email)) {
            return <div className={styles.PlayerPage}>
                <Breadcrumb className={styles.Breadcrumb}>
                    <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHome} />{" "}{intl.formatMessage(commonMessages.home)}</Breadcrumb.Item>
                    <Breadcrumb.Item active>{tournament.name + ' ' + intl.formatMessage(commonMessages.players)}</Breadcrumb.Item>
                </Breadcrumb>
                <Jumbotron fluid>
                    <Container>
                        <button onClick={handleOpenModal}>Add new player</button>
                        <div className={styles.TournamentPage}>
                            { tournament.level.map((e, index) => renderLevel(e, index))}
                        </div>
                    </Container>
                </Jumbotron>
                <NewPlayerModal />
            </div>;
        } else if (tournament && tournament.level ) {
            return <div className={styles.PlayerPage}>
                <Breadcrumb className={styles.Breadcrumb}>
                    <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHome} />{" "}{intl.formatMessage(commonMessages.home)}</Breadcrumb.Item>
                    <Breadcrumb.Item active>{tournament.name + ' ' + intl.formatMessage(commonMessages.players)}</Breadcrumb.Item>
                </Breadcrumb>
                <Jumbotron fluid>
                    <Container>
                        <div className={styles.TournamentPage}>
                            { tournament.level.map((e, index) => renderLevel(e, index))}
                        </div>
                    </Container>
                </Jumbotron>
                <NewPlayerModal />
            </div>;
        } else {
        return <div className={styles.PlayerPage}>
            <Breadcrumb className={styles.Breadcrumb}>
                <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHome} />{" "}{intl.formatMessage(commonMessages.home)}</Breadcrumb.Item>
                <Breadcrumb.Item active>{intl.formatMessage(commonMessages.tournamentPage)}</Breadcrumb.Item>
            </Breadcrumb>
            <Jumbotron fluid>
                <Container className={styles.TournamentDetail}>
                    <div className={styles.TournamentImage}>
                        <img className={styles.img} 
                            src='images/bavija_championships_2023.jpeg' 
                            alt="BAVIJA BADMINTON CHAMPIONSHIPS 2023" />
                    </div>
                    <div className={styles.TournamentDesc}>
                        📣📣📣🏸🏸🏸🇻🇳🇻🇳🇻🇳<br/>
                        Thông báo tổ chức<br/>
                        Giải cầu lông toàn quốc người Việt tại Nhật năm 2023<br/>
                        BAVIJA BADMINTON CHAMPIONSHIPS 2023<br/>
                        Trong chuỗi hoạt động kỷ niệm 50 năm thiết lập quan hệ ngoại giao Việt Nam - Nhật Bản.<br/>
                        Giải cầu lông toàn quốc người Việt tại Nhật năm 2023 sẽ diễn ra vào ngày 15 tháng 10 năm 2023 tại Nhà thi đấu đa năng HASUDA tỉnh Saitama, Nhật Bản.<br/>
                        Thay mặt Ban Tổ chức, Hội cầu lông Việt Nam tại Nhật Bản - BAVIJA xin gửi lời chào trân trọng nhất tới các Vận động viên, cổ động viên, Quý nhà tài trợ, Quý mạnh thường quân và toàn thể các bạn yêu cầu lông trên toàn Nhật Bản.<br/>
                        Giải cầu lông toàn quốc người Việt tại Nhật năm 2023 là giải thi đấu thường niên, được sự phối hợp tổ chức và truyền thông bởi BAVIJA, Tổ chức giao lưu quốc tế Việt Nam Nhật Bản - FAVIJA và Đại sứ quán Việt Nam tại Nhật Bản nhằm mang đến một sân chơi giải trí sôi nổi, hấp dẫn, giúp mỗi vận động viên phát huy được khả năng tiềm ẩn của bản thân.<br/>
                        Với chủ đề “Việt Nhật đồng hành, Hướng tới tương lai - Vươn tầm thế giới”, giải đấu lần này dự kiến quy tụ khoảng 300 vận động viên xuất sắc và cổ động viên nhiệt tình người Việt Nam trên khắp nước Nhật. Giải đấu năm nay sẽ có chất lượng chuyên môn và tính cạnh tranh cao, hứa hẹn những bất ngờ khi tiếp tục có sự tham gia của nhiều cây vợt trẻ đầy tiềm năng. Nội dung thi đấu 3 set 21, gồm các hạng mục đôi nam, đôi nam nữ và đôi hạng 4 dành cho người mới tập chơi, với tổng giải thưởng dự kiến lên tới hơn 350.000 (ba trăm năm mươi ngàn) yên Nhật.<br/>
                        Giải đấu sẽ góp phần lan toả những hình ảnh đẹp về con người Việt Nam, những giây phút thăng hoa bên trái cầu, thu hút những người yêu thể thao nói chung và yêu cầu lông nói riêng xích lại gần nhau hơn.<br/>
                        🏸 Link điều lệ giải: https://shorturl.at/zMPT5<br/>
                        1. 🏢 Đơn vị tổ chức: Hội cầu lông người Việt Nam tại Nhật Bản (BAVIJA)<br/>
                        Tổ chức giao lưu quốc tế Việt Nam Nhật Bản (FAVIJA)<br/>
                        2. 🇻🇳 Đơn vị bảo trợ: Đại Sứ Quán Việt Nam tại Nhật Bản<br/>
                        3. 🗓️ Thời gian: 9:00 - 19:00 Chủ nhật ngày 15 tháng 10 năm 2023<br/>
                        Báo danh: 8:30 - 9:30<br/>
                        Thi đấu: 9:30 - 12:00<br/>
                        Khai mạc: 12:00 - 13:00<br/>
                        Thi đấu: 13:00 - 19:00<br/>
                        4. ⏰ Thời gian đăng ký: 15/07/2023 - 01/10/2023<br/>
                        5. 🏟️ Địa điểm: 蓮田総合市民体育館パルシー<br/>
                        〒349-0133 埼玉県蓮田市閏戸2343<br/>
                        Hasuda City Gymnasium Palthy<br/>
                        Saitama-ken, Hasuda-shi, Uruido 2343<br/>
                        https://goo.gl/maps/92y9ZabQ49sy5PQL8<br/>
                        6. 🧑‍🤝‍🧑 Hạng mục thi đấu🏸<br/>
                        Đôi nam hạng 1: 12-18 cặp vđv<br/>
                        Đôi nam hạng 2: 12-24 cặp vđv<br/>
                        Đôi nam hạng 3: 12-36 cặp vđv<br/>
                        Đôi nam nữ hạng 1: 12-18 cặp vđv<br/>
                        Đôi nam nữ hạng 2: 12-18 cặp vđv<br/>
                        Đôi hạng 4 (tương đương các bạn mới tập chơi khoảng 1 năm): 12-24 cặp vđv<br/>
                        　　※ Có thể ghép đôi nam, đôi nữ, đôi nam nữ tuỳ ý và không chấp điểm<br/>
                        　　※Nội dung hạng 4 ưu tiên nhận đăng ký qua câu lạc bộ<br/>
                        Nội dung giao lưu quốc tế đôi nam hạng 1: 6-8 cặp vđv<br/>
                        　　※BTC sẽ lựa chọn 2-3 cặp vđv nam của Việt Nam<br/>
                        　　※BTC sẽ mời các cặp vđv nam hạng 1 của các nước khác<br/>
                        Lưu ý: 1 vận động viên được đăng ký tối đa 2 nội dung ở cùng 1 hạng (VD: Đôi nam hạng 1 + Đôi nam nữ hạng 1 hoặc Đôi nam hạng 2 + Đôi nam nữ hạng 2)<br/>
                        Chi tiết về cách xét hạng được ghi trong <a href='https://drive.google.com/file/d/1IeEibaWT5LYnNGRxd_LyvfB2esIALCSu/view?usp=sharing' target='_blank'>file điều lệ</a><br/>
                        7. 📝 Phương thức đăng ký<br/>
                        7.1 Đăng ký theo đoàn🏸<br/>
                        Lãnh đoàn liên hệ theo link Facebook sau để nhận mẫu đăng ký theo câu lạc bộ<br/>
                        <a href='https://www.facebook.com/mrtongthanhdao' target='_blank'>Tống Thành Đạo</a><br/>
                        <a href='https://www.facebook.com/sonvuhong9fury' target='_blank'>Vũ Hồng Sơn</a><br/>
                        7.2 Đăng ký cá nhân theo <a href='https://forms.gle/C6jzpjb8bpcJAcSs5' target='_blank'>form</a> hoặc liên hệ theo link Facebook dưới : <br/>
                        <a href='https://www.facebook.com/trunghieu.nguyen.3572846' target='_blank'>Nguyễn Trung Hiếu</a><br/>
                        8. 💰 Lệ phí tham gia giải :<br/>
                        Hạng 1,2,3 : 1500¥/1 vđv<br/>
                        Hạng 4: 1000¥/1 vđv<br/>
                        9. 🏆 Khen thưởng, kỷ luật và khiếu nại<br/>
                        9.1 Đôi nam hạng 1, Đôi nam hạng 2, Đôi nam hạng 3, Đôi nam nữ hạng 1, Đội nam nữ<br/>
                        hạng 2<br/>
                        Nhất : Huy chương + bằng khen + tiền mặt (Dự kiến 3man)<br/>
                        Nhì : Huy chương + bằng khen + tiền mặt (Dự kiến 1.5man)<br/>
                        Đồng giải 3 : Huy chương + bằng khen + tiền mặt (Dự kiến 1man)<br/>
                        9.2 Đôi hạng 4<br/>
                        Nhất : Huy chương + bằng khen + tiền mặt (Dự kiến 1man)<br/>
                        Nhì : Huy chương + bằng khen + tiền mặt (Dự kiến 8sen)<br/>
                        Đồng giải 3 : Huy chương + bằng khen + tiền mặt (Dự kiến 6sen)<br/>
                        9.3 Lưu ý: Giải thưởng có thể được điều chỉnh sau tuỳ vào kinh phí và tài trợ<br/>
                        10. Luật, thể thức thi đấu<br/>
                        10.1 Luật<br/>
                        Dựa trên quy định của liên đoàn cầu lông thế giới<br/>
                        10.2 Thể thức thi đấu<br/>
                        Tất cả các trận đấu diễn ra theo hình thức 3 set chạm 21 điểm, set 3 đổi sân sau điểm 11.<br/>
                        Dựa trên số lượng vận động viên đăng ký, BTC chia bảng đấu phù hợp<br/>
                        Các đội cùng bảng thi đấu vòng tròn và chọn 1 đội nhất bảng đi tiếp<br/>
                        Trong trường hợp 2 đôi có cùng số trận thắng, thua trong 1 bảng, xét thứ hạng theo các yếu tố sau:<br/>
                        Hiệu số trận thắng → Hiệu số set thắng → Hiệu số điểm → Thành tích đối đầu<br/>
                        10.3 Cầu thi đấu<br/>
                        YONEX AEROSENSA 500 (AS-500) được cung cấp bởi BTC<br/>
                        11. Thông tin liên hệ:<br/>
                        <a href='https://www.facebook.com/mrtongthanhdao' target='_blank'>Tống Thành Đạo</a><br/>
                        <a href='https://www.facebook.com/sonvuhong9fury' target='_blank'>Vũ Hồng Sơn</a><br/>
                        <a href='https://www.facebook.com/trunghieu.nguyen.3572846' target='_blank'>Nguyễn Trung Hiếu</a><br/>
                        <a href='https://www.facebook.com/tudamminh.itchiba' target='_blank'>Đàm Minh Tú</a><br/>
                        <a href='https://www.facebook.com/bui.d.binh.9' target='_blank'>Bùi Đức Bình</a><br/>
                        Xin cám ơn và hẹn gặp lại vào ngày 15 tháng 10 năm 2023 tại nhà thi đấu HASUDA.<br/>
                        Trân trọng,<br/>
                        Ban Tổ chức.<br/>
                        #bavijabadmintonchampionships2023 #bavija #favija<br/>
                        📣📣📣🏸🏸🏸🇻🇳🇻🇳🇻🇳<br/>
                    </div>
                </Container>

            </Jumbotron>
        </div>;
        }
    }

    export default TournamentPage;
