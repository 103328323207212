import axios from "axios";
import { API_URL } from "../../lib/constants";
export const getProfile = async () => {
    try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const result = await axios.get("https://raw.githubusercontent.com/figfofu/js-quizzes/master/a-1.md");
        return result;
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。
        console.log("error!!");
        return error;
    }
};

export const getTotal = async () => {
    const result = { quiz: 0, voca: 0 };
    try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const result = await axios.get("https://raw.githubusercontent.com/figfofu/vietnamese-quiz/master/config.md");
        console.log(result.data);
        const text = result && result.data || "";
        const configs = text.split("\n");
        const quiz = configs[0];
        const voca = configs[1];
        const [name, val] = quiz.split(":");
        const [vocaName, vocaVal] = voca.split(":");
        if (vocaVal) {
            result.quiz = parseInt(val.trim()) || 0;
        }
        if (val) {
            result.voca = parseInt(vocaVal.trim()) || 0;
        }
        return result;
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。
        console.log("error!!");
        return result;
    }
};

export const getEntry = async (id, mode) => {
    try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const result = await axios.get(`${API_URL}/${mode}/${id}.md`);
        console.log(result.data);
        return { [mode]: { [id]: result.data } };
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。
        console.log("error!!");
        return error;
    }
};

export const getTeams = async () => {
    try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const result = await _getTeamsCSV();
        console.log(result);
        console.log(result.data);
        return { result };
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。
        console.log("error!!");
        return error;
    }
};

const _getTeamsCSV = async () => {
    try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const response = await fetch("/data/team.csv");
        const reader = response.body.getReader()
        const result = await reader.read() // raw array
        const decoder = new TextDecoder('utf-8')
        const csv = decoder.decode(result.value) // the csv text
        console.log(csv);
        return csv;
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。
        console.log("error!!");
        return error;
    }
};