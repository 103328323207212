const entries = (state = {}, action) => {
    switch (action.type) {
        case 'TOTAL/pending':
            return {
                ...state,
                total: {
                    pending: true,
                    completed: false,
                    error: false
                }
            }
        case 'TOTAL/fulfilled':
            return {
                ...state,
                total: {
                    pending: false,
                    completed: true,
                    error: false,
                    data: action.payload
                }
            }
        case 'ENTRY/pending':
            return {
                ...state,
                entry: {
                    pending: true,
                    completed: false,
                    error: false
                }
            }
        case 'ENTRY/fulfilled':
            return {
                ...state,
                entry: {
                    pending: false,
                    completed: true,
                    error: false,
                    data: action.payload
                }
            }
        default:
            return state
    }
}

export default entries