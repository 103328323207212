import React, { useEffect, useState } from 'react';

import styles from "./EventsPage.module.scss"

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import commonMessages from "../../common/messages";

import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker, faCalendarAlt, faHome } from '@fortawesome/free-solid-svg-icons'

import { fetchEvents } from "../../redux/actions";

function EventsPage(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        dispatch(fetchEvents())
            .then(res => {
                setLoading(false);
                const results = res && res.payload && res.payload.response
                    && res.payload.response.result || {};
                setEvents(results);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }, []);
    if (loading) {
        return <Spinner animation="border" variant="primary" />;
    }
    function renderEvent(event, index) {
        if (!event) return null;
        return <Card key={index} className={styles.Card}>
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <Card.Body>
                <Card.Title>{event.name}</Card.Title>
                <Card.Text>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    {" "}
                    {intl.formatDate(event.start, {
                        year: 'numeric', month: 'numeric', day: 'numeric',
                        hour: 'numeric', minute: 'numeric', hour12: false
                    })} ~ {intl.formatDate(event.end, {
                        year: 'numeric', month: 'numeric', day: 'numeric',
                        hour: 'numeric', minute: 'numeric', hour12: false
                    })}
                </Card.Text>
                <Card.Text>
                    <FontAwesomeIcon icon={faMapMarker} />
                    {" "}
                    {intl.formatMessage(commonMessages.location)}
                    {String(event.location_name || "")
                        .split("\n")
                        .map((l, index) => <div key={"location:" + index}>{l}</div>)}
                    {String(event.location_map_jp || "")
                        .split("\n")
                        .map((l, index) => <div key={"location:" + index}>{l}</div>)}
                    {String(event.location_map_en || "")
                        .split("\n")
                        .map((l, index) => <div key={"location:" + index}>{l}</div>)}
                </Card.Text>
                <Card.Link href={`/events/${index + 1}`}>{intl.formatMessage(commonMessages.view_details)}</Card.Link>
            </Card.Body>
        </Card>;
    }
    return <div className={styles.EventsPage}>
        <Breadcrumb className={styles.Breadcrumb}>
            <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHome} />{" "}{intl.formatMessage(commonMessages.home)}</Breadcrumb.Item>
            <Breadcrumb.Item active>{intl.formatMessage(commonMessages.events)}</Breadcrumb.Item>
        </Breadcrumb>
        <Jumbotron fluid>
            <Container>
                <CardDeck className={styles.CardDeck}>
                    {events.map((e, index) => renderEvent(e, index))}
                </CardDeck>

            </Container>

        </Jumbotron>
    </div>;

}

export default EventsPage;