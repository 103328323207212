import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'

import styles from "./TeamTable.module.scss"
import commonMessages from "../../common/messages";

import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faMapMarker, faCalendarAlt, faAddressCard, faSearch, faInfoCircle } from '@fortawesome/free-solid-svg-icons'


function TeamTable(props) {
    const intl = useIntl();
    const history = useHistory();
    const [show, setShow] = useState(false);
    const [content, setContent] = useState({});
    const teams = props.teams || [];

    function handleClose() {
        setShow(false);
    }

    function onViewDetails(index) {
        history.push("/homepage/" + teams[index].team_code);
    }

    function onSelect(index) {
        setShow(true);
        const body = <ListGroup variant="flush">
            <ListGroup.Item><FontAwesomeIcon icon={faMapMarker} /> {" "} {intl.formatMessage(commonMessages.location)}
                <br />{teams[index].location}
            </ListGroup.Item>
            <ListGroup.Item>
                <FontAwesomeIcon icon={faCalendarAlt} /> {" "} {intl.formatMessage(commonMessages.schedule)}
                <br />
                {teams[index].schedule}
            </ListGroup.Item>
            <ListGroup.Item>
                <Form>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label><FontAwesomeIcon icon={faAddressCard} /> {" "} {intl.formatMessage(commonMessages.contact)}</Form.Label>
                        <br />
                        <Button variant="link"
                            onClick={() => window.open("https://www.facebook.com/groups/803523007104821/about", "vban_about")}>
                            <FontAwesomeIcon icon={faFacebook} /> Facebook
                        </Button>
                        <br />
                        <Button variant="danger">
                            <FontAwesomeIcon icon={faGoogle} /> Mail: vban.contact@gmail.com
                    </Button>
                    </Form.Group>
                </Form>
            </ListGroup.Item>
        </ListGroup>;
        setContent(
            {
                header: teams[index].team_name,
                body: body
            }
        );
    }
    const modal = <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>{content.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content.body}</Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
                {intl.formatMessage(commonMessages.close)}
            </Button>
        </Modal.Footer>
    </Modal>;

    return (
        <>
            {modal}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>{intl.formatMessage(commonMessages.club_name)}</th>
                        <th>{intl.formatMessage(commonMessages.actions)}</th>
                    </tr>
                </thead>
                <tbody>
                    {teams.map((l, index) => {
                        return <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{l.team_name}</td>
                            <td>
                                <FontAwesomeIcon icon={faSearch} onClick={() => onSelect(index)} className={styles.Btn} />
                                {"  "}
                                <FontAwesomeIcon icon={faInfoCircle} onClick={() => onViewDetails(index)} className={styles.Btn} />
                            </td>
                        </tr>;
                    })}
                </tbody>
            </Table>
        </>
    );
}

export default TeamTable;