import React from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { closeNewPlayerModal } from '../../redux/actions';
import styles from "./TournamentPage.module.scss";
import { addNewPlayer } from "../../redux/actions";
import { toast } from 'react-toastify';

function NewPlayerModal() {
  const modalIsOpen = useSelector((state) => state.modals.newPlayerModalIsOpen);
  const dispatch = useDispatch();
  const customStyles = {
    content: {
      width: '50%',
      margin: 'auto',
      maxHeight: '77%'
    }
  };

  function handleCloseModal() {
    dispatch(closeNewPlayerModal()); 
  };

  function handleSavePlayer() {
    const name = document.getElementById('name').value || '';
    const email = document.getElementById('email').value || '';
    const birthday = document.getElementById('birthday').value || '';
    const avatar = document.getElementById('avatar').value || '';
    const description = document.getElementById('description').value || '';
    const equipment = document.getElementById('equipment').value || '';
    const facebook = document.getElementById('facebook').value || '';
    const level = document.getElementById('level').value || 4;
    const nation = document.getElementById('nation').value || 'Vietnam🇻🇳';
    const point = document.getElementById('point').value || 0;
    const racket = document.getElementById('racket').value || '';
    const shoe = document.getElementById('shoe').value || '';
    const string = document.getElementById('string').value || '';
    const team_id = document.getElementById('team_id').value || 0;
    const tension = document.getElementById('tension').value || '';
    const title = document.getElementById('title').value || '';

    const playerInfo = {
      name: name,
      email: email,
      birthday: birthday,
      avatar: avatar,
      description: description,
      equipment: equipment,
      facebook: facebook,
      level: level,
      nation: nation,
      point: point,
      racket: racket,
      shoe: shoe,
      string: string,
      team_id: team_id,
      tension: tension,
      title: title
    };

    dispatch(addNewPlayer(playerInfo))
      .then(res => {
        toast.success("Player created successfully!");
        handleCloseModal();
      })
      .catch(error => {
        toast.error('Player creation failed!');
        console.log(error);
      });
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleCloseModal}
      contentLabel="New player modal"
      style={customStyles}
      appElement={document.getElementById('root')}
    >
      <h2 className={styles.ModalHeader}>Add new player</h2>

      <div className={styles.ModalBody}>
        <div className={styles.PlayerInfo}>
          <label className={styles.InfoLabel}>Name</label>
          <input id='name' className={styles.InfoValue} type="text"/>
        </div>

        <div className={styles.PlayerInfo}>
          <label className={styles.InfoLabel}>Email</label>
          <input id='email' className={styles.InfoValue} type="email" />
        </div>

        <div className={styles.PlayerInfo}>
          <label className={styles.InfoLabel}>Birthday</label>
          <input id='birthday' className={styles.InfoValue} type="date" />
        </div>

        <div className={styles.PlayerInfo}>
          <label className={styles.InfoLabel}>Avatar</label>
          <input id='avatar' className={styles.InfoValue} type="text" />
        </div>

        <div className={styles.PlayerInfo}>
          <label className={styles.InfoLabel}>Description</label>
          <input id='description' className={styles.InfoValue} type="text" />
        </div>

        <div className={styles.PlayerInfo}>
          <label className={styles.InfoLabel}>Equipment</label>
          <input id='equipment' className={styles.InfoValue} type="text" />
        </div>

        <div className={styles.PlayerInfo}>
          <label className={styles.InfoLabel}>Facebook</label>
          <input id='facebook' className={styles.InfoValue} type="text" />
        </div>

        <div className={styles.PlayerInfo}>
          <label className={styles.InfoLabel}>Level</label>
          <input id='level' className={styles.InfoValue} type="number"/>
        </div>

        <div className={styles.PlayerInfo}>
          <label className={styles.InfoLabel}>Nation</label>
          <input id='nation' className={styles.InfoValue} type="text" />
        </div>

        <div className={styles.PlayerInfo}>
          <label className={styles.InfoLabel}>Point</label>
          <input id='point' className={styles.InfoValue} type="number" />
        </div>

        <div className={styles.PlayerInfo}>
          <label className={styles.InfoLabel}>Racket</label>
          <input id='racket' className={styles.InfoValue} type="text" />
        </div>

        <div className={styles.PlayerInfo}>
          <label className={styles.InfoLabel}>Shoe</label>
          <input id='shoe' className={styles.InfoValue} type="text" />
        </div>

        <div className={styles.PlayerInfo}>
          <label className={styles.InfoLabel}>String</label>
          <input id='string' className={styles.InfoValue} type="text" />
        </div>

        <div className={styles.PlayerInfo}>
          <label className={styles.InfoLabel}>Team Id</label>
          <input id='team_id' className={styles.InfoValue} type="number" />
        </div>

        <div className={styles.PlayerInfo}>
          <label className={styles.InfoLabel}>Tension</label>
          <input id='tension' className={styles.InfoValue} type="text" />
        </div>

        <div className={styles.PlayerInfo}>
          <label className={styles.InfoLabel}>Title</label>
          <input id='title' className={styles.InfoValue} type="text" />
        </div>
      </div>

      <div className={styles.ModalFooter}>
        <button className={styles.SaveButton} onClick={handleSavePlayer}>Save</button>
        <button className={styles.CloseButton} onClick={handleCloseModal}>Cancel</button>
      </div>
    </Modal>
  );
};

export default NewPlayerModal;
