import React from 'react';
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from "./PlayerBox.module.scss";
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { faMale, faFemale } from '@fortawesome/free-solid-svg-icons'
import { GENDER, PlayerModel } from '../../models/Player';

function PlayerBox({ player = {} }) {

    return <Card className={classnames("text-center", styles.PlayerBox)}>
        <Card.Body className={styles.CardBody}>
            <div className={styles.Body}>
                <div className={styles.gender}>
                    {player.gender === GENDER.MALE ? <FontAwesomeIcon icon={faMale} color="blue" /> :
                        <FontAwesomeIcon icon={faFemale} color="magenta" />}
                </div>
                <div className={styles.name}>
                    {player.name}
                </div>
            </div>
        </Card.Body>
    </Card>;

}

PlayerBox.propTypes = {
    player: PlayerModel,
};

export default PlayerBox;