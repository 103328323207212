import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from "./BadmintonBoard.module.scss";
import PropTypes from 'prop-types';
import {
    isMobile
} from "react-device-detect";
import classnames from 'classnames';
import { faFutbol, faUserCircle, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons'
import Draggable from 'react-draggable';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import Button from 'react-bootstrap/Button';
import { useIntl } from 'react-intl';
import commonMessages from "./../../../../common/messages";

import cn from "classnames";
const W = 610;
const H = 1340;
const W_CORNER = 42;
const H_CORNER = 72;
const W_BLOCK = 253;
const H_BLOCK = 388;
const RATIO = H / W;
const THICKNESS = 4;
const PADDING = 48;
function BadmintonBoard() {
    const [width, setWidth] = useState(500);
    const [height, setHeight] = useState(500 * RATIO);
    const [lock, setLock] = useState(false);
    const intl = useIntl();
    function onClick(e) {
        const element = e && (e.tartget || e.toElement);
        const id = element && element.id;
        if (id === "controlled-tab-tab-LANDING_PAGE"
            || id === "controlled-tab-tab-SCOREBOARD"
            || id === "controlled-tab-tab-RANDOM_TABLE") {
            setLock(false);
        }
    }
    useEffect(() => {
        window.addEventListener('click', onClick);
        window.addEventListener("scroll", preventMotion, false);
        window.addEventListener("touchmove", preventMotion, false);
        let h = (window.innerHeight
            || document.documentElement.clientHeight
            || document.body.clientHeight);
        let w = (window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth);
        if (isMobile) {
            w = window.screen.width - 2 * PADDING;
            h = window.screen.height - 2 * PADDING;
        } else {
            h = h - 100;
            w = w - 100;
        }
        if (h < w * H / W) {
            setHeight(h);
            setWidth(h * W / H);
        } else {
            setHeight(w * H / W);
            setWidth(w);
        }
        return () => {
            window.removeEventListener('click', onClick);
        };
    }, []);
    function preventMotion(event) {
        // window.scrollTo(0, document.body.scrollHeight);
        event.preventDefault();
        event.stopPropagation();
    }
    const vLine = {
        position: "absolute",
        height: height,
        width: THICKNESS,
        backgroundColor: "white"
    };
    const hLine = {
        position: "absolute",
        height: THICKNESS,
        width: width,
        backgroundColor: "white"
    };
    const vLineLeftMost = {
        ...vLine,
        left: 0,
        top: 0,
    };
    const vLineLeft = {
        ...vLine,
        left: width * W_CORNER / W - THICKNESS / 2,
        top: 0,
    };
    const vLineCenterTop = {
        ...vLine,
        height: height * (THICKNESS + H_CORNER + H_BLOCK) / H,
        left: width / 2 - THICKNESS / 2,
        top: 0
    };
    const vLineCenterBottom = {
        ...vLine,
        left: width / 2 - THICKNESS / 2,
        height: height * (THICKNESS + H_CORNER + H_BLOCK) / H,
        top: height - height * (THICKNESS + H_CORNER + H_BLOCK) / H
    };
    const vLineRight = {
        ...vLine,
        left: width - width * W_CORNER / W - THICKNESS / 2,
        top: 0
    };
    const vLineRightMost = {
        ...vLine,
        left: width - THICKNESS / 2,
        top: 0
    };
    const hLineTopMost = {
        ...hLine,
        left: 0,
        top: 0
    };
    const hLineTop = {
        ...hLine,
        left: 0,
        top: height * H_CORNER / H - THICKNESS / 2
    };
    const hLineTopCenter = {
        ...hLine,
        left: 0,
        top: height * (H_CORNER + THICKNESS + H_BLOCK) / H - THICKNESS / 2
    };
    const hLineCenter = {
        ...hLine,
        left: 0,
        top: height / 2 - THICKNESS / 2
    };
    const hLineBottomCenter = {
        ...hLine,
        left: 0,
        top: height - height * (H_CORNER + THICKNESS + H_BLOCK) / H - THICKNESS / 2
    };
    const hLineBottom = {
        ...hLine,
        left: 0,
        top: height - height * H_CORNER / H + THICKNESS / 2
    };
    const hLineBottomMost = {
        ...hLine,
        left: 0,
        top: height - THICKNESS
    };
    return <Card className={classnames("text-center", styles.BadmintonBoard)}>
        <Card.Body className={styles.BoardBody}>

            <div className={styles.BoardContainer} style={{ height: height + PADDING, width: width + PADDING }}>

                <ScrollLock isActive={lock}>
                    <div className={styles.Board} style={{ height: height, width: width }}>
                        <Button variant="danger"
                            onClick={() => { setLock(!lock) }}>
                            <FontAwesomeIcon icon={lock ? faLock : faLockOpen} /> {" "}
                            {intl.formatMessage(lock ? commonMessages.unlock : commonMessages.lock)}
                        </Button>
                        <div style={vLineLeftMost}></div>
                        <div style={vLineLeft}></div>
                        <div style={vLineCenterTop}></div>
                        <div style={vLineCenterBottom}></div>
                        <div style={vLineRight}></div>
                        <div style={vLineRightMost}></div>
                        <div style={hLineTopMost}></div>
                        <div style={hLineTop}></div>
                        <div style={hLineTopCenter}></div>
                        <div style={hLineCenter}></div>
                        <div style={hLineBottomCenter}></div>
                        <div style={hLineBottom}></div>
                        <div style={hLineBottomMost}></div>
                        <Draggable
                            defaultClassName={styles.Drag}
                            axis="both"
                            handle=".handle"
                            defaultPosition={{ x: 100, y: 100 }}
                            position={null}
                            grid={[1, 1]}
                            scale={1}>
                            <div>
                                <div className={cn("handle", styles.Move)}>
                                    <FontAwesomeIcon icon={faFutbol} className={styles.IconPink}>
                                    </FontAwesomeIcon></div>
                            </div>
                        </Draggable>
                        <Draggable
                            defaultClassName={styles.Drag}
                            axis="both"
                            handle=".handle"
                            defaultPosition={{ x: 100, y: 100 }}
                            position={null}
                            grid={[1, 1]}
                            scale={1}>
                            <div>
                                <div className={cn("handle", styles.Move)}>
                                    <FontAwesomeIcon icon={faUserCircle} className={styles.IconBlue}>
                                    </FontAwesomeIcon></div>
                            </div>
                        </Draggable>
                        <Draggable
                            defaultClassName={styles.Drag}
                            axis="both"
                            handle=".handle"
                            defaultPosition={{ x: 100, y: 100 }}
                            position={null}
                            grid={[1, 1]}
                            scale={1}>
                            <div>
                                <div className={cn("handle", styles.Move)}>
                                    <FontAwesomeIcon icon={faUserCircle} className={styles.IconBlue}>
                                    </FontAwesomeIcon></div>
                            </div>
                        </Draggable>
                        <Draggable
                            defaultClassName={styles.Drag}
                            axis="both"
                            handle=".handle"
                            defaultPosition={{ x: W_CORNER, y: 0 }}
                            position={null}
                            grid={[1, 1]}
                            scale={1}>
                            <div>
                                <div className={cn("handle", styles.Move)}>
                                    <FontAwesomeIcon icon={faUserCircle} className={styles.IconRed}>
                                    </FontAwesomeIcon></div>
                            </div>
                        </Draggable>
                        <Draggable
                            defaultClassName={styles.Drag}
                            axis="both"
                            handle=".handle"
                            defaultPosition={{ x: W_CORNER, y: 0 }}
                            position={null}
                            grid={[1, 1]}
                            scale={1}>
                            <div>
                                <div className={cn("handle", styles.Move)}>
                                    <FontAwesomeIcon icon={faUserCircle} className={styles.IconRed}>
                                    </FontAwesomeIcon></div>
                            </div>
                        </Draggable>
                    </div>
                </ScrollLock>
            </div>
        </Card.Body>
    </Card>;

}

BadmintonBoard.propTypes = {
};

export default BadmintonBoard;