export const availableLanguages = ["VN", 'ES', 'PT', 'FR', 'SL']

export const Languages = {
  VN: {
    MONTHS: [
      "Tháng 1",
      "Tháng 2",
      "Tháng 3",
      "Tháng 4",
      "Tháng 5",
      "Tháng 6",
      "Tháng 7",
      "Tháng 8",
      "Tháng 9",
      "Tháng 10",
      "Tháng 11",
      "Tháng 12",
    ],
    DAYS: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
  },
  EN: {
    MONTHS: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    DAYS: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  },
  ES: {
    MONTHS: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Nombre",
      "Diciembre",
    ],
    DAYS: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
  },
  PT: {
    MONTHS: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Nome",
      "Dezembro",
    ],
    DAYS: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
  },
  FR: {
    MONTHS: [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Nom",
      "Décembre",
    ],
    DAYS: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  },
  SL: {
    MONTHS: [
      "Januar",
      "Februar",
      "Marec",
      "April",
      "Maj",
      "Junij",
      "Julij",
      "Avgust",
      "September",
      "Oktober",
      "November",
      "December",
    ],
    DAYS: ["Ned", "Pon", "Tor", "Sre", "Čet", "Pet", "Sob"],
  },
}
