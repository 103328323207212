import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

import CardDeck from 'react-bootstrap/CardDeck'
import Card from 'react-bootstrap/Card'

import styles from "./DashboardPage.module.scss"
import commonMessages from "../../common/messages";
import TeamTable from "../TeamTable";
import AdditionalTable from "../AdditionalTable";

import { fetchPlayers, fetchTeams } from "../../redux/actions";
import { useDispatch } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCubes, faUserFriends, faMale, faFemale, faChartBar,
    faSortNumericUp, faTable, faHome
} from '@fortawesome/free-solid-svg-icons'

import { COLORS, COLORS_ARRAY } from "../../lib/constants";

import {
    PieChart, Pie, ResponsiveContainer,
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';

function DashboardPage() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [players, setPlayers] = useState([]);
    const [teams, setTeams] = useState([]);
    useEffect(() => {
        dispatch(fetchPlayers())
            .then(res => {
                console.log(res);
                const results = (res && res.payload && res.payload.response
                    && res.payload.response.result) || [];
                setPlayers(results);
            });
        dispatch(fetchTeams())
            .then(res => {
                const results = (res && res.payload && res.payload.response
                    && res.payload.response.result) || [];
                setTeams(results);
            });
    }, []);

    function getGenders(players = []) {
        const males = players.filter(p => p.gender === "M") || [];
        const females = players.filter(p => p.gender === "F") || [];
        return [
            {
                name: intl.formatMessage(commonMessages.male), value: males.length, color: COLORS.Indigo,
                percent: (players.length > 0 ? (100 * males.length / players.length) : 0).toFixed(0),
                content: intl.formatMessage(commonMessages.no_of_people, { no: males.length }),
            },
            {
                name: intl.formatMessage(commonMessages.female), value: females.length, color: COLORS.Pink,
                percent: (players.length > 0 ? (100 * females.length / players.length) : 0).toFixed(0),
                content: intl.formatMessage(commonMessages.no_of_people, { no: females.length }),
            }
        ];
    }

    function getAges(players = []) {
        const tracking = new Set();
        players.forEach(p => tracking.add(p.birthday));
        const keys = Array.from(tracking).sort((a, b) => a - b) || [];
        return keys.map(age => {
            const filter = players.filter(p => p.birthday === age) || [];
            return {
                name: String(age),
                value: filter.length,
                content: intl.formatMessage(commonMessages.no_of_people, { no: filter.length }),
                percent: (players.length > 0 ? (100 * filter.length / players.length) : 0).toFixed(0)
            }
        });
    }

    function getLevels(players = []) {
        const tracking = new Set();
        players.forEach(p => tracking.add(p.level));
        const keys = Array.from(tracking).sort((a, b) => a - b) || [];
        return keys.map((level, index) => {
            const filter = players.filter(p => p.level === level) || [];
            return {
                name: "L" + String(level),
                value: filter.length,
                content: intl.formatMessage(commonMessages.no_of_people, { no: filter.length }),
                percent: (players.length > 0 ? (100 * filter.length / players.length) : 0).toFixed(0),
                color: COLORS_ARRAY[index]
            }
        });
    }

    function getNoOfPlayers(players = []) {
        return players.length;
    }

    function getNoOfClubs(teams = []) {
        return teams.length;
    }

    const genders = getGenders(players);
    const levels = getLevels(players);
    const ages = getAges(players);
    return (
        <div className={styles.DashboardPage}>
            <Breadcrumb className={styles.Breadcrumb}>
                <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHome} />{" "}{intl.formatMessage(commonMessages.home)}</Breadcrumb.Item>
                <Breadcrumb.Item active>{intl.formatMessage(commonMessages.dashboard)}</Breadcrumb.Item>
            </Breadcrumb>
            <Jumbotron fluid>
                <Container>
                    <h1>{intl.formatMessage(commonMessages.dashboard)}</h1>
                    <CardDeck>
                        <Card>
                            <Card.Body>
                                <Card.Title><FontAwesomeIcon icon={faUserFriends} /> {" "} {intl.formatMessage(commonMessages.total_members)}</Card.Title>
                                <Card.Text className={styles.CardText}>
                                    {intl.formatMessage(commonMessages.no_of_people, { no: getNoOfPlayers(players) })}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Card.Title><FontAwesomeIcon icon={faCubes} /> {" "} {intl.formatMessage(commonMessages.total_clubs)}</Card.Title>
                                <Card.Text className={styles.CardText}>
                                    {intl.formatMessage(commonMessages.no_of_clubs, { no: getNoOfClubs(teams) })}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </CardDeck>
                </Container>
                <Container className={styles.Container}>
                    <CardDeck>
                        <Card>
                            <Card.Body style={{ width: '100%', height: 520 }} >
                                <Card.Title>
                                    <FontAwesomeIcon icon={faMale} />
                                    <FontAwesomeIcon icon={faFemale} />
                                    {" "} {intl.formatMessage(commonMessages.view_by_gender)}
                                </Card.Title>
                                <ResponsiveContainer className={styles.ResponsiveContainer}
                                    width='100%' height={320} >
                                    <PieChart width={320} height={320}>
                                        <Pie
                                            isAnimationActive={false}
                                            data={genders}
                                            cx={"50%"}
                                            cy={"50%"}
                                            innerRadius={64}
                                            outerRadius={96}
                                            fill="#8884d8"
                                            paddingAngle={5}
                                            labelLine={false}
                                            label
                                        >
                                            {
                                                genders.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)
                                            }
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                                <AdditionalTable values={genders}></AdditionalTable>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body style={{ width: '100%', height: 520 }} >
                                <Card.Title>
                                    <FontAwesomeIcon icon={faSortNumericUp} />
                                    {" "} {intl.formatMessage(commonMessages.view_by_level)}
                                </Card.Title>
                                <ResponsiveContainer className={styles.ResponsiveContainer}
                                    width='100%' height={320} >
                                    <PieChart width={320} height={320}>
                                        <Pie
                                            isAnimationActive={false}
                                            data={levels}
                                            cx={"50%"}
                                            cy={"50%"}
                                            outerRadius={96}
                                            fill="#8884d8"
                                            paddingAngle={5}
                                            labelLine={false}
                                            label
                                        >
                                            {
                                                levels.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)
                                            }
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                                <AdditionalTable values={levels}></AdditionalTable>
                            </Card.Body>
                        </Card>
                    </CardDeck>

                </Container>
                <Container className={styles.Container}>
                    <Card>
                        <Card.Body style={{ width: '100%', height: ages.length * 40 }}>
                            <Card.Title>
                                <FontAwesomeIcon icon={faChartBar} />
                                {" "} {intl.formatMessage(commonMessages.view_by_age)}
                            </Card.Title>
                            <ResponsiveContainer className={styles.ResponsiveContainer}>
                                <BarChart layout="vertical"
                                    width={500}
                                    height={320}
                                    data={ages}
                                    margin={{
                                        top: 32, right: 8, left: 8, bottom: 32,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis type="number" />
                                    <YAxis dataKey="name" type="category" />
                                    <Tooltip />
                                    <Bar isAnimationActive={false} dataKey="value" fill={COLORS.Indigo} label={{ fill: 'white', fontSize: 20 }} />
                                </BarChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Container>
                <Container className={styles.Container}>
                    <Card>
                        <Card.Body style={{ width: '100%' }}>
                            <Card.Title>
                                <FontAwesomeIcon icon={faTable} />
                                {" "} {intl.formatMessage(commonMessages.club_list)}
                            </Card.Title>
                            <TeamTable teams={teams} />
                        </Card.Body>
                    </Card>
                </Container>
            </Jumbotron>

        </div >
    );
}
// const renderActiveShape = (props) => {
//     const RADIAN = Math.PI / 180;
//     const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
//         fill, payload, percent, value } = props;
//     const sin = Math.sin(-RADIAN * midAngle);
//     const cos = Math.cos(-RADIAN * midAngle);
//     const sx = cx + (outerRadius + 10) * cos;
//     const sy = cy + (outerRadius + 10) * sin;
//     const mx = cx + (outerRadius + 30) * cos;
//     const my = cy + (outerRadius + 30) * sin;
//     const ex = mx + (cos >= 0 ? 1 : -1) * 22;
//     const ey = my;
//     const textAnchor = cos >= 0 ? 'start' : 'end';

//     return (
//         <g>
//             <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>{payload.name}</text>
//             <Sector
//                 cx={cx}
//                 cy={cy}
//                 innerRadius={innerRadius}
//                 outerRadius={outerRadius}
//                 startAngle={startAngle}
//                 endAngle={endAngle}
//                 fill={fill}
//             />
//             <Sector
//                 cx={cx}
//                 cy={cy}
//                 startAngle={startAngle}
//                 endAngle={endAngle}
//                 innerRadius={outerRadius + 6}
//                 outerRadius={outerRadius + 10}
//                 fill={fill}
//             />
//             <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
//             <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
//             <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
//             <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
//                 {`(Rate ${(percent * 100).toFixed(2)}%)`}
//             </text>
//         </g>
//     );
// };

const renderCustomizedLabel = (props, intl) => {
    const RADIAN = Math.PI / 180;
    const { name, cx, cy, midAngle, innerRadius, outerRadius, value, index, fill, percent } = props;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN) - (index === 0 ? 32 : -32);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <g>
            <text x={x} y={y} fill={fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${name}: ${(percent * 100).toFixed(0)}%`}
            </text>
            <text x={x} y={y + 20} fill={fill} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {intl.formatMessage(commonMessages.no_of_people, { no: value })}
            </text>
        </g>
    );
};

const renderCustomizedLabelLevel = (props, intl) => {
    const RADIAN = Math.PI / 180;
    const { name, cx, cy, midAngle, innerRadius, outerRadius, fill, value, percent } = props;
    const radius = innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN) * 1.25;
    const y = cy + radius * Math.sin(-midAngle * RADIAN) * 1.25;
    return <g>
        <text x={x} y={y} textAnchor={(midAngle > 180 && midAngle < 300) ? "end" : "start"} fill={fill}>
            {intl.formatMessage(commonMessages.level_no, { level: name })} : {`${(percent * 100).toFixed(0)}%`}
        </text>
        <text x={x} y={y + 20} textAnchor={(midAngle > 180 && midAngle < 300) ? "end" : "start"} fill={fill}>
            {intl.formatMessage(commonMessages.no_of_people, { no: value })}
        </text>
    </g>;
};

export default DashboardPage;