import React from 'react';
import { useIntl } from 'react-intl';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import cn from 'classnames';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Scoreboard from "../Scoreboard";
import RandomTable from "../RandomTable";
import StrategyBoard from "../StrategyBoard";
import TournamentPage from "../TournamentPage";
import LandingPage from "../LandingPage";
import RealtimeScoreboard from '../RealtimeScoreboard';

import styles from "./HomePage.module.scss"
import messages from "./messages";

const MODES = {
    SCOREBOARD: "SCOREBOARD",
    RANDOM_TABLE: "RANDOM_TABLE",
    STRATEGY_BOARD: "STRATEGY_BOARD",
    LANDING_PAGE: "LANDING_PAGE",
    TOURNAMENT_PAGE: "TOURNAMENT_PAGE",
    REALTIME_SCOREBOARD: "REALTIME_SCOREBOARD"
};

function HomePage() {
    const intl = useIntl();

    return (
        <div>
            {/* <Header /> */}
            <Container fluid className={styles.HomePage}>
                <Row className={cn("flex-xl-nowrap", styles.Body)}>
                    {/* <Col xs={12} md={3} xl={2} className={cn("bd-sidebar", styles.Sidebard)}>
                        <Accordion className={styles.SidebardItem}>
                            <Card>
                                <Card.Header>
                                    <Button variant="link" eventKey="0"
                                        onClick={() => setMode(MODES.SCOREBOARD)}>
                                        {intl.formatMessage(messages.scoreboard)}
                                    </Button>
                                </Card.Header>
                            </Card>
                        </Accordion>
                        <Accordion className={styles.SidebardItem}>
                            <Card>
                                <Card.Header>
                                    <Button variant="link" eventKey="0"
                                        onClick={() => setMode(MODES.RANDOM_TABLE)}>
                                        {intl.formatMessage(messages.randomTable)}
                                    </Button>
                                </Card.Header>
                            </Card>
                        </Accordion>
                        <Accordion className={styles.SidebardItem}>
                            <Card>
                                <Card.Header>
                                    <Button variant="link" eventKey="0"
                                        onClick={() => setMode(MODES.STRATEGY_BOARD)}>
                                        {intl.formatMessage(messages.strategyBoard)}
                                    </Button>
                                </Card.Header>
                            </Card>
                        </Accordion>
                    </Col> */}
                    <Col xs={12} className="bd-content">
                        {/* {renderMode(mode)} */}
                        <Tabs defaultActiveKey={MODES.LANDING_PAGE} id="controlled-tab" variant={"tabs"}>
                            <Tab eventKey={MODES.LANDING_PAGE} title={intl.formatMessage(messages.landingPage)}>
                                <LandingPage />
                            </Tab>
                            <Tab eventKey={MODES.SCOREBOARD} title={intl.formatMessage(messages.scoreboard)}>
                                <Scoreboard />
                            </Tab>
                            <Tab eventKey={MODES.RANDOM_TABLE} title={intl.formatMessage(messages.randomTable)}>
                                <RandomTable />
                            </Tab>
                            <Tab eventKey={MODES.STRATEGY_BOARD} title={intl.formatMessage(messages.strategyBoard)}>
                                <StrategyBoard />
                            </Tab>
                            <Tab eventKey={MODES.TOURNAMENT_PAGE} title={intl.formatMessage(messages.tournamentPage)}>
                                <TournamentPage />
                            </Tab>
                            <Tab eventKey={MODES.REALTIME_SCOREBOARD} title={intl.formatMessage(messages.realtimeScoreboard)}>
                                <RealtimeScoreboard />
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default HomePage;
