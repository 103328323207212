export const API_URL = "https://raw.githubusercontent.com/figfofu/vietnamese-quiz/master";

export const LANG_KEY = "vban_lang";

export const COLORS = {
    Purple: "#4A148C",
    Red: "#B71C1C",
    Cyan: "#006064",
    Indigo: "#1A237E",
    Teal: "#004D40",
    Magenta: "#Magenta",
    Green: "#1B5E20",
    Blue: "#0D47A1",
    Yellow: "#F57F17",
    Orange: "#E65100",
    Pink: "#880E4F"
}

export const COLORS_ARRAY = Object.keys(COLORS).map(k => COLORS[k]);