    import React, { useEffect, useState } from 'react';
    import "./TournamentDraw.scss"
    import { useDispatch } from 'react-redux';
    import Spinner from 'react-bootstrap/Spinner';
    import { fetchTournamentPlayers } from "../../redux/actions";

    function TournamentDraw(props) {
        const dispatch = useDispatch();
        const [players, setPlayers] = useState([]);
        const [tournament, setTournament] = useState([]);
        const [teams, setTeams] = useState([]);
        const [loading, setLoading] = useState(false);
        const tournamentId = props && props.match && props.match.params && parseInt(props.match.params.tournamentId);

        function renderPlayer(playerlist, index) {
            if (!playerlist) return null;

            return <div class="pair-wrapper">
                    <div class="pair-index">
                        {index}
                    </div>
                    <div class="pair-info">
                        <div class="pair-name">
                        {players[playerlist.player_id_1].name} / {players[playerlist.player_id_2].name}
                        </div>
                        <div class="pair-club">
                        {getTeamFromPlayeId(playerlist.player_id_1, playerlist.player_id_2)}
                        </div>
                        <div class="pair-border"></div>
                    </div>
                </div>
            
        }

        function getTeamFromPlayeId(id_1, id_2) {
            if(!teams.length) {return 'unknow'}

            if(players[id_1].team_id == players[id_2].team_id) {
                return teams[players[id_1].team_id].name
            }

            return 'Liên Quân'
        }

        function renderLevel(level, index) {
            if (!level) return null;

            level.draw = level.draw.filter(elm => elm);

            if ([0, 1, 2, 3, 4, 6, 7].includes(index)) {
                return ;
            }

            return <div class="draw-wrapper">
                    <div class="tourname-info">
                        <div class="tourname-level-wrapper">
                            <div class="tourname-name">
                                {tournament.name.toUpperCase()}
                            </div>  
                            <div class="tourname-level">
                                MIXED DOUBLES LEVEL 4
                            </div>
                        </div>
                        <div class="tourname-logo">
                            <img class="bavija-logo" src='images/bavija.png' alt="bavija logo" />
                            <img class="favija-logo" src='images/favija.png' alt="favija logo" />
                            <img class="lotus-logo" src='images/lotus.png' alt="lotus logo" />
                        </div>
                    </div>
                    <div class="draw">
                        <div class="left">
                            {level.draw.slice(0, 12).map((e, index) => renderPlayer(e, index + 1))}
                        </div>
                    
                        <div class="center">
                            {level.draw.slice(12, 24).map((e, index) => renderPlayer(e, index + 13))}
                        </div>

                        <div class="center-two">
                            {level.draw.slice(24, 36).map((e, index) => renderPlayer(e, index + 25))}
                        </div>
                    
                        <div class="right"> 
                            {level.draw.slice(36, 48).map((e, index) => renderPlayer(e, index + 37))}
                        </div>
                    </div>
                </div>
        }

        function renderForPrint(level, index) {
            if (!level) return null;

            if (index == 0) {
                return ;
            }

            if (tournamentId == 1 && index == 1) {
                return <div class="print-draw-wrapper">
                    <div class="level-name">
                        {level.name}
                    </div>
                    <div class="draw">
                        {level.draw.map((e, pair_index) => renderNationPlayerForPrint(index, e, pair_index))}
                    </div>
                </div>
            }

            return <div class="print-draw-wrapper">
                    <div class="level-name">
                        {level.name}
                    </div>
                    <div class="draw">
                        {level.draw.map((e, pair_index) => renderPlayerForPrint(index, e, pair_index))}
                    </div>
                </div>
        }

        function levelSign(level) {
            switch (level) {
                case 1:
                    return '🄋';
                case 2:
                    return '➀';
                case 3:
                    return '➁';
                case 4:
                    return '➂';
                case 5:
                    return '➃';
                case 6:
                    return '➄';
                case 7:
                    return '➅';
            }
        }

        function renderPlayerForPrint(level, playerlist, pair_index) {
            if (!playerlist) return null;

            return <div class="draw-detail">
                    <div class={`tournament-sign tournament-${tournamentId}`}>
                        <img src="images/tournament-cover.png" />
                    </div>
                    <div class="pair-wrapper">
                        <div class="pair-info">
                            <div class="pair-name">
                                {players[playerlist.player_id_1].name}
                            </div>
                            <div class="pair-name">
                                {players[playerlist.player_id_2].name}
                            </div>
                            <div class="pair-club" hidden={tournamentId === 2 ? true : false}>
                                ({getTeamFromPlayeId(playerlist.player_id_1, playerlist.player_id_2)})
                            </div>
                        </div>
                        <div class="level-sign">
                            {levelSign(level)} - {pair_index}
                        </div>
                    </div>
                </div>
            
        }

        function renderNationPlayerForPrint(level, playerlist, pair_index) {
            if (!playerlist) return null;

            return <div class="draw-detail">
                    <div class={`tournament-sign tournament-${tournamentId}`}>
                        <img src="images/tournament-cover.png" />
                    </div>
                    <div class="pair-wrapper">
                        <div class="pair-info">
                            <div class="pair-name">
                                {players[playerlist.player_id_1].name}
                            </div>
                            <div class="pair-name">
                                {players[playerlist.player_id_2].name}
                            </div>
                            <div class="pair-club" hidden={tournamentId === 2 ? true : false}>
                                ({players[playerlist.player_id_1].nation})
                            </div>
                        </div>
                        <div class="level-sign">
                            {levelSign(level)}-{pair_index}
                        </div>
                    </div>
                </div>
            
        }

        useEffect(() => {
            setLoading(true);
            dispatch(fetchTournamentPlayers(tournamentId))
                .then(res => {
                    setLoading(false);
                    const results = res && res.payload && res.payload.response
                        && res.payload.response.result || {};

                    setPlayers(results[0]);
                    setTournament(results[1]);
                    setTeams(results[2]);
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                });
        }, []);

        if (loading) {
            return <Spinner animation="border" variant="primary" />;
        }

        if (tournament && tournament.level ) {
            // return <div>
            //     { tournament.level.map((e, index) => renderLevel(e, index))}
            // </div>
            return <div class='tournament-draw'>
                { tournament.level.map((e, index) => renderForPrint(e, index))}
            </div>
        } else {
            return <div>
                Nothing here !
            </div>;
        }
    }

    export default TournamentDraw;
