import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';

import styles from "./LandingPage.module.scss"
import commonMessages from "./../../common/messages";
import TeamTable from "../TeamTable";

import { fetchTeams } from "../../redux/actions";
import { useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faChartBar, faTrophy, faCalendar } from '@fortawesome/free-solid-svg-icons'



function LandingPage() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const history = useHistory();
    const [teams, setTeams] = useState([]);
    useEffect(() => {
        dispatch(fetchTeams())
            .then(res => {
                const results = (res && res.payload && res.payload.response
                    && res.payload.response.result) || [];
                setTeams(results);
            });
    }, []);

    function onViewDashboard() {
        history.push("/dashboard");
    }

    function onViewCal() {
        history.push("/calendar");
    }

    function onViewEvents() {
        history.push("/events");
    }

    const carousel = <Container><Carousel indicators={false}>
        <Carousel.Item interval={2000}>
            <img
                className={styles.img}
                src="images/vban_all.jpg"
                alt="First slide"
            />
            <Carousel.Caption>
                {/* <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
            </Carousel.Caption>
        </Carousel.Item>
    </Carousel>
    </Container>;
    return (
        <div className={styles.LandingPage}>
            <Container>
                <CardDeck>
                    <Card>
                        <Card.Body>
                            <Card.Title><FontAwesomeIcon icon={faChartBar} />{" "}Thống kê</Card.Title>
                            <Button onClick={onViewDashboard} variant="primary">{intl.formatMessage(commonMessages.view_details)}</Button>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Card.Title><FontAwesomeIcon icon={faTrophy} />{" "}{intl.formatMessage(commonMessages.events)}</Card.Title>
                            <Button onClick={onViewEvents} variant="primary">{intl.formatMessage(commonMessages.view_details)}</Button>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Card.Title><FontAwesomeIcon icon={faCalendar} />{" "}Lịch tập</Card.Title>
                            <Button onClick={onViewCal} variant="primary">{intl.formatMessage(commonMessages.view_details)}</Button>
                        </Card.Body>
                    </Card>
                </CardDeck>
                <hr></hr>
            </Container>
            {carousel}
            <Jumbotron fluid>
                <Container>
                    <h1>Hội cầu lông Việt Nam tại Nhật Bản</h1>
                    <div>
                        <p>
                            <p>Hội cầu lông Việt Nam tại Nhật Bản thành lập trên cơ sở tự nguyện, tự giác tham gia sinh hoạt của các thành viên có cùng năng khiếu, sở thích đam mê cầu lông. Hội mong muốn xây dựng một cộng đồng người Việt Nam khỏe mạnh, đoàn kết với những hoạt động vui vẻ, bổ ích nhằm nâng cao hình ảnh của người Việt Nam với bạn bè quốc tế tại Nhật Bản.
                                Hoạt động:</p>
                            <ul>
                                <li>1. Nâng cao phong trào và trình độ cầu lông của người Việt Nam tại Nhật Bản.</li>
                                <li>2. Phát huy năng khiếu, sở trường của hội viên, tăng cường mối quan hệ đoàn kết cộng đồng: tham gia cầu nối hoặc tổ chức các buổi tập luyện giao lưu giữa các câu lạc bộ cầu lông tại Nhật Bản.</li>
                                <li>3. Tổ chức các sự kiện thường niên nhằm góp phần tạo ra sân chơi bổ ích lành mạnh cho người Việt Nam tại Nhật Bản.</li>
                            </ul></p>
                        <p>Các hội viên tham gia với tinh thần xây dựng, với niềm đam mê thể thao chung. Hội không chấp nhận các hình thức câu kéo, lợi dụng trục lợi cá nhân. Hội không mang tính thương mại.</p>
                    </div>
                    <p>
                        <Button variant="link" onClick={() => window.open("https://www.facebook.com/groups/803523007104821/about", "vban_about")}>
                            <FontAwesomeIcon icon={faFacebook} /> Facebook
                        </Button>
                        <Button variant="danger" onClick={() => { }}><FontAwesomeIcon icon={faGoogle} />
                            Mail: vban.contact@gmail.com
                        </Button>
                    </p>
                    <p>
                        <Button variant="primary"
                            onClick={() => window.open("https://www.facebook.com/groups/803523007104821/permalink/803573793766409/", "vban_policy")}>
                            {intl.formatMessage(commonMessages.policy)}
                        </Button>
                    </p>


                </Container>
                <Container>
                    <p>
                        <Button variant="primary"
                            onClick={onViewDashboard}>
                            {intl.formatMessage(commonMessages.dashboard)}
                        </Button>
                    </p>
                    <h1>{intl.formatMessage(commonMessages.club_list)}</h1>
                    <p>
                        <TeamTable teams={teams} />
                    </p>
                </Container>
            </Jumbotron>
        </div>
    );
}

export default LandingPage;
