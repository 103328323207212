    import React, { useEffect, useState } from 'react';
    import "./TournamentDrawInternational.scss"
    import { useDispatch } from 'react-redux';
    import Spinner from 'react-bootstrap/Spinner';
    import { fetchTournamentPlayers } from "../../redux/actions";
    import firebase from '../../Firebase';
    import { toast } from 'react-toastify';
    import { addNewMatch } from "../../redux/actions";

    function TournamentDrawInternational() {
        const dispatch = useDispatch();
        const [players, setPlayers] = useState([]);
        const [tournament, setTournament] = useState([]);
        const [admins, setAdmins] = useState([]);
        const [loading, setLoading] = useState(false);
        const user = firebase.auth().currentUser;

        function renderNationResult() {
            const level = tournament.level[1];

            return <div class="nation-result-wrapper">
                    <div class="header-wrapper">
                        <img src="images/header.png" />
                    </div>
                    <div class="body-wrapper">
                        <div class="draw-title">
                            { level.name }
                        </div>

                        <div class="result-wrapper">
                            { level.group.map((group, _) => renderNationGroupDetail(group)) }
                        </div>
                    </div>
                    <div class="footer-wrapper">
                        <img src="images/footer.png" />
                    </div>
                </div>
        }

        function renderNationGroupDetail(group) {
            const groupName = 'Group ' + group.name;

            return <div class="group-wrapper">
                    <div class="group-name">
                        { groupName }
                    </div>
                    <div class="pair-wrapper">
                        { group.pair_ids.split(',').map((pair_id, index) => renderNationPairDetail(pair_id, index)) }
                    </div>
                </div>
        }

        function renderNationPairDetail(pair_id, index) {
            const level = tournament.level[1];

            if (pair_id == '') {
                return '';
            }

            const pair = level.draw[pair_id];

            const nation = getFlagFromPairId(pair_id);

            let player_name_1 = players[pair.player_id_1].name;

            if (user && admins.includes(user.email)) {
                player_name_1 += ' (' + pair_id + ')';
            }

            return <div class="pair-detail">
                    <div class="nation-flag">
                        <img src={'images/' + nation + '.png'} />
                    </div>
                    <div class="player-wrapper">
                        <div class="player-name">
                            { player_name_1 }
                        </div>
                        <div class="player-name">
                            { players[pair.player_id_2].name }
                        </div>
                    </div>
                </div>
        }

        function getFlagFromPairId(pair_id) {
            switch(pair_id) {
                case '3':
                    return 'japan';
                case '4':
                    return 'america';
                case '5':
                case '6':
                    return 'china';
                case '8':
                    return 'indonesia';
                default:
                    return 'vietnam';
            }
        }


        useEffect(() => {
            setLoading(true);
            dispatch(fetchTournamentPlayers(1))
                .then(res => {
                    setLoading(false);
                    const results = res && res.payload && res.payload.response
                        && res.payload.response.result || {};

                    setPlayers(results[0]);
                    setTournament(results[1]);
                    setAdmins(results[3]);
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                });
        }, []);

        if (loading) {
            return <Spinner animation="border" variant="primary" />;
        }

        const reloadPair = () => {
            dispatch(fetchTournamentPlayers(1))
                .then(res => {
                    setLoading(false);
                    const results = res && res.payload && res.payload.response
                        && res.payload.response.result || {};

                    setTournament(results[1]);
                })
                .catch(error => {
                    console.log(error);
                    setLoading(false);
                });
        }

        function handleCreateMatch() {
            let pair_id_1 = document.getElementById('pair-id-1').value
            let pair_id_2 = document.getElementById('pair-id-2').value

            if (pair_id_1 == '' || pair_id_2 == '') {
                toast.error('Please input pair id!');
                return;
            }

            pair_id_1 = Number(pair_id_1);
            pair_id_2 = Number(pair_id_2);

            const matchInfo = {
                games: {
                    0: {
                        pair_1: {
                            id: pair_id_1,
                            score: 0,
                            win: 0
                        },
                        pair_2: {
                            id: pair_id_2,
                            score: 0,
                            win: 0
                        },
                    },
                    1: {
                        pair_1: {
                            id: pair_id_1,
                            score: 0,
                            win: 0
                        },
                        pair_2: {
                            id: pair_id_2,
                            score: 0,
                            win: 0
                        },
                    },
                    2: {
                        pair_1: {
                            id: pair_id_1,
                            score: 0,
                            win: 0
                        },
                        pair_2: {
                            id: pair_id_2,
                            score: 0,
                            win: 0
                        },
                    }
                }
            };

            const levelId = 1;
            const tournamentId = 1;
            dispatch(addNewMatch({tournamentId, levelId, matchInfo}))
                .then(res => {
                    toast.success("Match created successfully!");

                })
                .catch(error => {
                    toast.error('Match creation failed!');
                    console.log(error);
                });


        }

        if (tournament && tournament.level && user && admins.includes(user.email)) {
            return <div class='result-draw'>
                <div class="create-match">
                    <input type="text" id="pair-id-1" placeholder="ID cặp 1" />
                    <input type="text" id="pair-id-2" placeholder="ID cặp 2" />
                    <button class="btn btn-primary" onClick={handleCreateMatch}>Create Match</button>
                </div>
                { renderNationResult() }
                <div class="reload-draw">
                    <button class="btn btn-primary" onClick={reloadPair}>Reload Pair</button>
                </div>
            </div>
        } else if (tournament && tournament.level ) {
            return <div class='result-draw'>
                { renderNationResult() }
                <div class="reload-draw">
                    <button class="btn btn-primary" onClick={reloadPair}>Reload Pair</button>
                </div>
            </div>
        } else {
            return <div>
                Nothing here !
            </div>;
        }
    }

    export default TournamentDrawInternational;
