import React from 'react';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Card from 'react-bootstrap/Card'
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from "react-router-dom";
import commonMessages from "../../common/messages";
import { useIntl } from 'react-intl';

const Profile = () => {
    const history = useHistory();
    const intl = useIntl();

    const user = useSelector((state) => {
        const u = state && state.auth && state.auth.user && state.auth.user.data;
        return u || {};
    });

    function toHome() {
        history.push("/");
    }

    return (
        <div className="container">
            <Card>
                <Card.Header>
                    {intl.formatMessage(commonMessages.profile)}
                </Card.Header>
                <Card.Body>
                    <Table striped bordered hover>
                        {/* <thead>
                        <tr>
                            <th>#</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Username</th>
                        </tr>
                    </thead> */}
                        <tbody>
                            <tr>
                                <td>{intl.formatMessage(commonMessages.name)}</td>
                                <td>{(user && user.email) === "binh@gmail.com" ?
                                    intl.formatMessage(commonMessages.guest) : (user && user.email)}</td>
                            </tr>
                            <tr>
                                <td>{intl.formatMessage(commonMessages.last_logged_in)}</td>
                                <td>{user && user.metadata && user.metadata.lastSignInTime}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <Button variant="primary" onClick={toHome}>
                        <FontAwesomeIcon icon={faHome} />
                    </Button>
                </Card.Body>
            </Card>
        </div>
    );
}

export default Profile;