import { defineMessages } from 'react-intl';

export default defineMessages({
    scoreboard: {
        id: 'src.components.HomePage.scoreboard',
        defaultMessage: 'Score Board'
    },
    randomTable: {
        id: 'src.components.HomePage.randomTable',
        defaultMessage: 'Random Table'
    },
    strategyBoard: {
        id: 'src.components.HomePage.strategyBoard',
        defaultMessage: 'Strategy Board'
    },
    landingPage: {
        id: 'src.components.HomePage.landingPage',
        defaultMessage: 'Introduction'
    },
    tournamentPage: {
        id: 'src.components.HomePage.tournamentPage',
        defaultMessage: 'Bavija Championships 2023'
    },
    realtimeScoreboard: {
        id: 'src.components.HomePage.realtimeScoreboard',
        defaultMessage: 'Realtime Scoreboard'
    },
});
