import React from 'react';

import Table from 'react-bootstrap/Table';


function AdditionalTable(props) {
    const values = props.values || [];
    return (
        <>
            <Table striped bordered hover size="sm">
                <tbody>
                    {values.map((v, index) => {
                        return <tr key={index} style={{ color: v.color }}>
                            <td>{v.name}</td>
                            <td>{v.content}</td>
                            <td>{v.percent}%</td>
                        </tr>;
                    })}
                </tbody>
            </Table>
        </>
    );
}

export default AdditionalTable;