import React, { useEffect, useState } from 'react';

import styles from "./TeamPage.module.scss"

import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux'
import commonMessages from "./../../common/messages";
import Breadcrumb from 'react-bootstrap/Breadcrumb'

import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faMapMarker, faCalendarAlt, faAddressCard, faUsers, faTrophy, faHome } from '@fortawesome/free-solid-svg-icons'

import { fetchTeamById } from "../../redux/actions";

function TeamPage(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [team, setTeam] = useState({});
    const [loading, setLoading] = useState(false);
    const teamId = props && props.match && props.match.params && props.match.params.teamId;

    useEffect(() => {
        console.log(props);
        setLoading(true);
        dispatch(fetchTeamById(teamId))
            .then(res => {
                setLoading(false);
                console.log(res);
                const results = res && res.payload && res.payload.response
                    && res.payload.response.result || {};
                setTeam(results);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }, []);
    if (loading) {
        return <Spinner animation="border" variant="primary" />;
    }
    if (!team || !team.team_name) {
        return <div className={styles.LandingPage}>
            <Jumbotron fluid>
                <Container>
                    <h1>{intl.formatMessage(commonMessages.team_not_found)}</h1>
                    <Table striped bordered hover>
                        <tbody>
                            <tr>
                                <td><FontAwesomeIcon icon={faAddressCard} /> {" "} {intl.formatMessage(commonMessages.contact)}</td>
                                <td>
                                    <p>
                                        <Button variant="link"
                                            onClick={() => window.open("https://www.facebook.com/groups/803523007104821/about", "vban_about")}>
                                            <FontAwesomeIcon icon={faFacebook} /> Facebook
                                    </Button>
                                        <br />
                                        <Button variant="danger">
                                            <FontAwesomeIcon icon={faGoogle} /> Mail: vban.contact@gmail.com
                                    </Button>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                </Container>

            </Jumbotron>
        </div>;
    }
    return <div className={styles.LandingPage}>
        <Breadcrumb className={styles.Breadcrumb}>
            <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHome} />{" "}{intl.formatMessage(commonMessages.home)}</Breadcrumb.Item>
            <Breadcrumb.Item active>{intl.formatMessage(commonMessages.details)}</Breadcrumb.Item>
        </Breadcrumb>
        <Jumbotron fluid>
            <Container>
                <h1>{String(team.team_name).toUpperCase()}</h1>
                <Table striped bordered hover>
                    <tbody>
                        <tr>
                            <td><FontAwesomeIcon icon={faUsers} /> {" "} {intl.formatMessage(commonMessages.members)}</td>
                            <td>{Number.isFinite(team.volume) ?
                                intl.formatMessage(commonMessages.no_of_people, { no: Number(team.volume) })
                                : String(team.volume)}</td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faMapMarker} /> {" "} {intl.formatMessage(commonMessages.location)}</td>
                            <td>{team.location}</td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faCalendarAlt} /> {" "} {intl.formatMessage(commonMessages.schedule)}</td>
                            <td>{team.schedule}</td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faTrophy} /> {" "} {intl.formatMessage(commonMessages.awards)}</td>
                            <td>{String(team.records || "").split("\\n").map(a => <p>{a}</p>)}</td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faAddressCard} /> {" "} {intl.formatMessage(commonMessages.contact)}</td>
                            <td>
                                <p>
                                    <Button variant="link"
                                        onClick={() => window.open("https://www.facebook.com/groups/803523007104821/about", "vban_about")}>
                                        <FontAwesomeIcon icon={faFacebook} /> Facebook
                                    </Button>
                                    <br />
                                    <Button variant="danger">
                                        <FontAwesomeIcon icon={faGoogle} /> Mail: vban.contact@gmail.com
                                    </Button>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </Table>

                <Image src={`/images/teams/${String(team.team_code).toLowerCase()}.jpg`} fluid className={styles.Image} />

            </Container>

        </Jumbotron>
    </div>;

}

export default TeamPage;