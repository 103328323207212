import React from 'react';
// import Button from 'react-bootstrap/Button'
// import Form from 'react-bootstrap/Form';
// import FormControl from 'react-bootstrap/FormControl';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import Modal from 'react-bootstrap/Modal';
// import firebase from '../../Firebase';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
// import ReactCountryFlag from "react-country-flag"

// import { useSelector, useDispatch } from "react-redux";
// import styles from "./Footer.module.scss"

// import { useHistory } from "react-router-dom";
// import { useIntl } from "react-intl";
// import { validateUser } from "../../redux/actions";
// import commonMessages from "../../common/messages";
// import { LANG_KEY } from "../../lib/constants";
import { MDBContainer } from "mdbreact";
function Footer() {
    return <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
            &copy; {new Date().getFullYear()} Copyright: VBAN
    </MDBContainer>
    </div>;
    //return <MDBFooter color="blue" className="font-small pt-4 mt-4">
    {/* <MDBContainer fluid className="text-center text-md-left">
            <MDBRow>
                <MDBCol md="6">
                    <h5 className="title">VBAN</h5>
                    <p>
                        Here you can use rows and columns here to organize your footer
                        content.
          </p>
                </MDBCol>
                <MDBCol md="6">
                    <h5 className="title">Links</h5>
                    <ul>
                        <li className="list-unstyled">
                            <a href="#!">Link 1</a>
                        </li>
                        <li className="list-unstyled">
                            <a href="#!">Link 2</a>
                        </li>
                        <li className="list-unstyled">
                            <a href="#!">Link 3</a>
                        </li>
                        <li className="list-unstyled">
                            <a href="#!">Link 4</a>
                        </li>
                    </ul>
                </MDBCol>
            </MDBRow>
        </MDBContainer> */}

    //</MDBFooter>;
}
export default Footer;