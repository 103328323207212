import { DATABASE_ACTIONS, DATABASE_FIELDS } from "../actions/constants";

const database = (state = [], action) => {
    if (!action.type) {
        return state;
    }
    const [database, status] = String(action.type).split("/");
    if (!DATABASE_ACTIONS[database]) {
        return state;
    }
    return {
        ...state,
        [DATABASE_FIELDS[database]]: {
            data: action.payload,
            pending: status === "pending",
            completed: status === "fulfilled",
            error: status === "rejected"
        }
    }
    // switch (action.type) {
    //     case 'DATABASE_TEAM/pending':
    //         return {
    //             ...state,
    //             team: {
    //                 data: action.payload,
    //                 pending: true,
    //                 completed: false,
    //                 error: false
    //             }
    //         }
    //     case 'DATABASE_TEAM/fulfilled':
    //         return {
    //             ...state,
    //             team: {
    //                 data: action.payload,
    //                 pending: true,
    //                 completed: false,
    //                 error: false
    //             }
    //         }
    //     case 'DATABASE_TEAM_ID/pending':
    //         return {
    //             ...state,
    //             team_id: {
    //                 data: action.payload,
    //                 pending: true,
    //                 completed: false,
    //                 error: false
    //             }
    //         }
    //     case 'DATABASE_TEAM_ID/fulfilled':
    //         return {
    //             ...state,
    //             team_id: {
    //                 data: action.payload,
    //                 pending: true,
    //                 completed: false,
    //                 error: false
    //             }
    //         }
    //     case 'DATABASE_PLAYERS/fulfilled':
    //         return {
    //             ...state,
    //             players: {
    //                 data: action.payload,
    //                 pending: false,
    //                 completed: true,
    //                 error: false
    //             }
    //         }
    //     case 'DATABASE_PLAYERS/pending':
    //         return {
    //             ...state,
    //             players: {
    //                 data: action.payload,
    //                 pending: true,
    //                 completed: false,
    //                 error: false
    //             }
    //         }
    //     case 'DATABASE_PLAYERS/rejected':
    //         return {
    //             ...state,
    //             players: {
    //                 data: action.payload,
    //                 pending: false,
    //                 completed: false,
    //                 error: true
    //             }
    //         }
    //     default:
    //         return state
    // }
}

export default database