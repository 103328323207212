import { defineMessages } from 'react-intl';

export default defineMessages({
    new: {
        id: 'src.components.Scoreboard.new',
        defaultMessage: 'New'
    },
    reset: {
        id: 'src.components.Scoreboard.reset',
        defaultMessage: 'Reset'
    },
    change: {
        id: 'src.components.Scoreboard.change',
        defaultMessage: 'Change End'
    },
    resetScore: {
        id: 'src.components.Scoreboard.resetScore',
        defaultMessage: 'Reset Score'
    },
    keepScore: {
        id: 'src.components.Scoreboard.keepScore',
        defaultMessage: 'Keep Score'
    },
    changeServe: {
        id: 'src.components.Scoreboard.changeServe',
        defaultMessage: 'Change Serve'
    },
    editNames: {
        id: 'src.components.Scoreboard.editNames',
        defaultMessage: 'Edit Names'
    },
    playerName: {
        id: 'src.components.Scoreboard.playerName',
        defaultMessage: 'Player Name'
    },
    team: {
        id: 'src.components.Scoreboard.team',
        defaultMessage: 'Team {team}'
    }
});