import React, { useState } from 'react';
// import Card from 'react-bootstrap/Card'
import Dropdown from 'react-bootstrap/Dropdown'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cn from 'classnames';
import styles from "./StrategyBoard.module.scss";
// import PropTypes from 'prop-types';
// import classnames from 'classnames';
// import { faFutbol } from '@fortawesome/free-solid-svg-icons'
import BadmintonBoard from "./components/BadmintonBoard";
import commonMessages from "../../common/messages";
import { useIntl } from "react-intl";
const SPORTS = ["badminton"];

function StrategyBoard() {
    const intl = useIntl();
    const [sport, setSport] = useState(SPORTS[0]);
    function onSelectSport(s) {
        setSport(s);
    }
    function renderBoard(s) {
        switch (s) {
            case "badminton":
                return <BadmintonBoard />;
            default:
                return <BadmintonBoard />;
        }
    }
    return <div className={styles.StrategyBoard}>
        <div className={cn("mb-2", styles.Control)}>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {sport}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {SPORTS.map((s, i) => {
                        return <Dropdown.Item key={i} onSelect={() => { onSelectSport(s) }} >
                            {intl.formatMessage(commonMessages[s])}
                        </Dropdown.Item>;
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
        {renderBoard(sport)}
    </div>;
}

StrategyBoard.propTypes = {
};

export default StrategyBoard;