export const GENDER = { MALE: "MALE", FEMALE: "FEMALE" }

export const MAX_LEVEL = 10;
export const MIN_LEVEL = 1;

export const PROPERTIES = {
    NAME: "name",
    GENDER: "gender",
    LEVEL: "level"
}

export class PlayerModel {
    /**
     * 
     * @param {String} name 
     * @param {String} gender 
     * @param {number} level 
     */
    constructor(name, gender = GENDER.MALE, level = 5) {
        this._name = name;
        this._gender = gender;
        this._level = level;
    }

    get name() {
        return this._name;
    }
    get gender() {
        return this._gender;
    }
    get level() {
        return this._level;
    }
    set name(_name) {
        this._name = _name;
    }
    set gender(_gender) {
        this._gender = _gender;
    }
    set level(_level) {
        this._level = _level;
    }

    static clone(player) {
        return new PlayerModel(player.name, player.gender, player.level);
    }
    cloneInstance() {
        return new PlayerModel(this._name, this._gender, this._level);
    }
}