import React, { useEffect, useState } from 'react';

import styles from "./CourtManagePage.scss"

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux'
import commonMessages from "../../common/messages";
import Breadcrumb from 'react-bootstrap/Breadcrumb'

import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faShoePrints, faRocket, faVial, faGamepad, faGuitar, faUserTie, faCalendar, faGraduationCap, faAudioDescription, faEnvelope, faStar, faFlag, faAddressCard, faUsers, faTrophy, faHome, faUser } from '@fortawesome/free-solid-svg-icons'

import { fetchCourts, updateCourt } from "../../redux/actions";
import firebase from '../../Firebase';
import { toast } from 'react-toastify';

function CourtManagePage(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [courtOneCurrentMatchId, setCourtOneCurrentMatchId] = useState([]);
    const [courtOneNextMatchId, setCourtOneNextMatchId] = useState([]);
    const [courtTwoCurrentMatchId, setCourtTwoCurrentMatchId] = useState([]);
    const [courtTwoNextMatchId, setCourtTwoNextMatchId] = useState([]);
    const [courtThreeCurrentMatchId, setCourtThreeCurrentMatchId] = useState([]);
    const [courtThreeNextMatchId, setCourtThreeNextMatchId] = useState([]);
    const [courtFourCurrentMatchId, setCourtFourCurrentMatchId] = useState([]);
    const [courtFourNextMatchId, setCourtFourNextMatchId] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [loading, setLoading] = useState(false);
    const tournamentId = props && props.match && props.match.params && parseInt(props.match.params.tournamentId);

    useEffect(() => {
        console.log(props);
        setLoading(true);
        dispatch(fetchCourts(tournamentId))
            .then(res => {
                setLoading(false);
                console.log(res);
                const results = res && res.payload && res.payload.response
                    && res.payload.response.result || {};
                setCourtOneCurrentMatchId(results[0][1] && results[0][1].current_match_id || '');
                setCourtOneNextMatchId(results[0][1] && results[0][1].next_match_id || '');
                setCourtTwoCurrentMatchId(results[0][2] && results[0][2].current_match_id || '');
                setCourtTwoNextMatchId(results[0][2] && results[0][2].next_match_id || '');
                setCourtThreeCurrentMatchId(results[0][3] && results[0][3].current_match_id || '');
                setCourtThreeNextMatchId(results[0][3] && results[0][3].next_match_id || '');
                setCourtFourCurrentMatchId(results[0][4] && results[0][4].current_match_id || '');
                setCourtFourNextMatchId(results[0][4] && results[0][4].next_match_id || '');
                setAdmins(results[1]);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <Spinner animation="border" variant="primary" />;
    }

    const user = firebase.auth().currentUser;

    if (!admins.includes(user.email)) {
        return <div className={styles.LandingPage}>
            <Jumbotron fluid>
                <Container>
                    <h1>{intl.formatMessage(commonMessages.team_not_found)}</h1>
                    <Table striped bordered hover>
                        <tbody>
                            <tr>
                                <td><FontAwesomeIcon icon={faAddressCard} /> {" "} {intl.formatMessage(commonMessages.contact)}</td>
                                <td>
                                    <p>
                                        <Button variant="link"
                                            onClick={() => window.open("https://www.facebook.com/groups/803523007104821/about", "vban_about")}>
                                            <FontAwesomeIcon icon={faFacebook} /> Facebook
                                    </Button>
                                        <br />
                                        <Button variant="danger">
                                            <FontAwesomeIcon icon={faGoogle} /> Mail: vban.contact@gmail.com
                                    </Button>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                </Container>

            </Jumbotron>
        </div>;
    }

    if (courtOneCurrentMatchId  && courtOneNextMatchId && courtTwoCurrentMatchId && courtTwoNextMatchId && courtThreeCurrentMatchId && courtThreeNextMatchId && courtFourCurrentMatchId && courtFourNextMatchId) {
        function getCurrentMatchIdByCourtNumber(courtNumber) {
            switch (courtNumber) {
                case 1:
                    return courtOneCurrentMatchId;
                case 2:
                    return courtTwoCurrentMatchId;
                case 3:
                    return courtThreeCurrentMatchId;
                case 4:
                    return courtFourCurrentMatchId;
            }
        }

        function getNextMatchIdByCourtNumber(courtNumber) {
            switch (courtNumber) {
                case 1:
                    return courtOneNextMatchId;
                case 2:
                    return courtTwoNextMatchId;
                case 3:
                    return courtThreeNextMatchId;
                case 4:
                    return courtFourNextMatchId;
            }
        }

        function handleUpdateCourt(courtNumber) {
            const currentMatchIdElm = document.getElementById(`current_match_id_${courtNumber}`);
            const nextMatchIdElm = document.getElementById(`next_match_id_${courtNumber}`);

            if (!currentMatchIdElm || !nextMatchIdElm) { return }
            
            const currentMatchId = currentMatchIdElm.value;
            const nextMatchId = nextMatchIdElm.value;
            const courtInfo = {
                current_match_id: currentMatchId,
                next_match_id: nextMatchId
            };

            dispatch(updateCourt({tournamentId, courtNumber, courtInfo}))
                .then(res => {
                    toast.success("Court updated successfully!");
                })
                .catch(error => {
                    toast.error('Court updated failed!');
                    console.log(error);
                });
        }

        function setCurrentMatchIdByCourtNumber(courtNumber, value) {
            switch (courtNumber) {
                case 1:
                    setCourtOneCurrentMatchId(value);
                    break;
                case 2:
                    setCourtTwoCurrentMatchId(value);
                    break;
                case 3:
                    setCourtThreeCurrentMatchId(value);
                    break;
                case 4:
                    setCourtFourCurrentMatchId(value);
                    break;
            }

        }

        function setNextMatchIdByCourtNumber(courtNumber, value) {
            switch (courtNumber) {
                case 1:
                    setCourtOneNextMatchId(value);
                    break;
                case 2:
                    setCourtTwoNextMatchId(value);
                    break;
                case 3:
                    setCourtThreeNextMatchId(value);
                    break;
                case 4:
                    setCourtFourNextMatchId(value);
                    break;
            }
        }
    

        return (
            <div className='court-manage'>
                {Array.from({ length: 4 }, (_, i) => (
                    <div key={i} className='court'>
                        <h2>Court {i + 1}</h2>
                        <label>
                            Current Match ID:
                            <input type='text' id={`current_match_id_${i + 1}`} value={getCurrentMatchIdByCourtNumber(i+1)} onChange={event => setCurrentMatchIdByCourtNumber(i+1, event.target.value)}/>
                        </label>
                        <br />
                        <label>
                            Next Match ID:
                            <input type='text' id={`next_match_id_${i + 1}`} value={getNextMatchIdByCourtNumber(i+1)} onChange={event => setNextMatchIdByCourtNumber(i+1, event.target.value)}/>
                        </label>
                        <br />
                        <button type='button' id={`update_button_${i + 1}`} onClick={() => handleUpdateCourt(i+1)}>Update</button>
                    </div>
                ))}
            </div>
        );
    }
    
    return <div>loading</div>;
}

export default CourtManagePage;
