export const DATABASE_ACTIONS = {
    DATABASE_TEAM: "DATABASE_TEAM",
    DATABASE_TEAM_ID: "DATABASE_TEAM_ID",
    DATABASE_PLAYER_ID: "DATABASE_PLAYER_ID",
    DATABASE_PLAYERS: "DATABASE_PLAYERS",
    DATABASE_COURTS: "DATABASE_COURTS",
    DATABASE_EVENTS: "DATABASE_EVENTS",
    DATABASE_EVENTS_ID: "DATABASE_EVENTS_ID",
    DATABASE_TOURNAMENT_PLAYERS: "DATABASE_TOURNAMENT_PLAYERS",
    DATABASE_TOURNAMENT_MATCHES: "DATABASE_TOURNAMENT_MATCHES",
    DATABASE_ADD_NEW_PLAYER: "DATABASE_ADD_NEW_PLAYER",
    DATABASE_UPDATE_PLAYER: "DATABASE_UPDATE_PLAYER",
    DATABASE_UPDATE_COURT: "DATABASE_UPDATE_COURT",
    DATABASE_ADD_NEW_MATCH: "DATABASE_ADD_NEW_MATCH",
}

export const DATABASE_FIELDS = {
    DATABASE_TEAM: "team",
    DATABASE_TEAM_ID: "team_id",
    DATABASE_PLAYER_ID: "player_id",
    DATABASE_PLAYERS: "players",
    DATABASE_EVENTS: "events",
    DATABASE_EVENTS_ID: "event_id",
    DATABASE_TOURNAMENT_PLAYERS: "tournament_players"
}
