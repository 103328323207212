import React, { useState } from 'react';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form';
// import FormControl from 'react-bootstrap/FormControl';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';
import firebase from '../../Firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faUser, faChartBar, faTrophy, faCalendar } from '@fortawesome/free-solid-svg-icons'
import ReactCountryFlag from "react-country-flag"

import { useSelector, useDispatch } from "react-redux";
import styles from "./Header.module.scss"

import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { validateUser } from "../../redux/actions";
import commonMessages from "../../common/messages";
import { LANG_KEY } from "../../lib/constants";

function Header() {
    const dispatch = useDispatch();
    const intl = useIntl();
    const history = useHistory();
    const [language, setLanguage] = useState(null);
    // const [selectedLanguage, setSelectedLanguage] = useState(null);
    const [show, setShow] = useState(false);
    // useEffect(() => {
    //     const localLang = localStorage.getItem(LANG_KEY);
    //     if (localLang) {
    //         setSelectedLanguage(localLang)
    //     }
    // }, []);
    const user = useSelector((state) => {
        const u = state && state.auth && state.auth.user && state.auth.user.data;
        return u || {};
    });
    function handleLogout() {
        firebase.auth().signOut().then(
            () => dispatch(validateUser({
                data: {
                    signinCheck: true,
                    signedIn: false,
                }
            }))
        );
    }

    function handleLogin() {
        history.push("/signin");
    }

    function toProfile() {
        history.push("/profile");
    }
    function onLangChanged(lang) {
        setLanguage(lang);
    }
    function handleClose() {
        setShow(false);
    }
    function handleSubmit() {
        if (language) {
            // setSelectedLanguage(language);
            localStorage.setItem(LANG_KEY, language);
            window.location.reload();
        }
        setShow(false);
    }
    function toDashboard() {
        history.push("/dashboard");
    }
    function toEvents() {
        history.push("/events");
    }
    function toCalendar() {
        history.push("/calendar");
    }
    // function getFlag(lang) {
    //     if (!lang) {
    //         return "VN";
    //     }
    //     if (lang.toUpperCase().indexOf("JA") > -1 || lang.toUpperCase().indexOf("JP") > -1) {
    //         return "JP";
    //     }
    //     if (lang.toUpperCase().indexOf("VN") > -1) {
    //         return "VN";
    //     }
    //     return "VN";
    // }
    const element = <FontAwesomeIcon icon={faSignOutAlt} />
    const modal = <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>Ngôn ngữ (言語)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <div className="mb-3">
                    <Form.Check onChange={() => onLangChanged("vn")} checked={language === "vn"} label="Tiếng Việt" type={'radio'} id={`inline-${'radio'}-1`} />
                    <Form.Check onChange={() => onLangChanged("jp")} checked={language === "jp"} label="日本語" type={'radio'} id={`inline-${'radio'}-2`} />
                </div>
            </Form>

        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={handleSubmit} disabled={!language}>
                OK
            </Button>
        </Modal.Footer>
    </Modal>;
    return <Navbar bg="light" expand="lg">
        {modal}
        <Navbar.Brand href="/">
            {/* <ReactCountryFlag countryCode="VN" /> */}
            <img
                src="/logo_small.png"
                width="44"
                height="31"
                className="d-inline-block align-top"
                alt="VBAN | Cầu lông | バドミントン | Badminton"
            />
            {/* {intl.formatMessage(commonMessages.appName)} */}
            {/* <ReactCountryFlag countryCode="VN" /> */}
        </Navbar.Brand>
        <Button variant="link" onClick={toDashboard}>
            <FontAwesomeIcon icon={faChartBar} />
        </Button>
        <Button variant="link" onClick={toEvents}>
            <FontAwesomeIcon icon={faTrophy} />
        </Button>
        <Button variant="link" onClick={toCalendar}>
            <FontAwesomeIcon icon={faCalendar} />
        </Button>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">

            {/* <Nav className="mr-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Link</Nav.Link>
            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
            </NavDropdown>
            </Nav>
            <Form inline>
            <FormControl type="text" placeholder="Search" className="mr-sm-2" /> 
            </Form>*/}
            <Nav className="mr-auto">
            </Nav>
            <Form inline>
                <Button variant="outline-light" onClick={() => { setShow(!show); setLanguage(localStorage.getItem(LANG_KEY)) }}>
                    <ReactCountryFlag countryCode={"VN"} />
                    {" "}
                    <ReactCountryFlag countryCode={"JP"} />
                </Button>
                {user.signedIn && <Button variant="primary" onClick={toProfile}>
                    <FontAwesomeIcon icon={faUser} />
                </Button>}
                <div className={styles.space}>{"  "}</div>
                {user.signedIn ? <Button onClick={handleLogout}>{intl.formatMessage(commonMessages.logout)}{" "}{element}</Button> :
                    <Button onClick={handleLogin}>{intl.formatMessage(commonMessages.login)}{" "}{element}</Button>}
            </Form>

        </Navbar.Collapse>
    </Navbar>;
}
export default Header;