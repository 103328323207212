import { combineReducers } from 'redux'
import todos from './todos'
import visibilityFilter from './visibilityFilter'
import entries from './entry'
import auth from './auth'
import database from './database'
import modals from './modal'

export default combineReducers({
    todos,
    visibilityFilter,
    entries,
    auth,
    database,
    modals
})
