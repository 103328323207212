import { createAsyncThunk } from '@reduxjs/toolkit'
import { DATABASE_ACTIONS } from "./constants";

import { getTotal, getEntry } from "./async-actions";
import { getTeams, getTeamById, getPlayerById, getPlayers, getEventById, dbUpdateCourt, getCourts, getEvents, getTournamentPlayers, getTournamentMatches, createNewPlayer, dbUpdatePlayer, createNewMatch } from "./database-actions";

let nextTodoId = 0
export const addTodo = text => ({
    type: 'ADD_TODO',
    id: nextTodoId++,
    text
})

export const setVisibilityFilter = filter => ({
    type: 'SET_VISIBILITY_FILTER',
    filter
})

export const toggleTodo = id => ({
    type: 'TOGGLE_TODO',
    id
})

export const VisibilityFilters = {
    SHOW_ALL: 'SHOW_ALL',
    SHOW_COMPLETED: 'SHOW_COMPLETED',
    SHOW_ACTIVE: 'SHOW_ACTIVE'
}

export const fetchTotal = createAsyncThunk('TOTAL', async () => {
    const response = await getTotal();
    return {
        response
    };
})

export const fetchEntry = createAsyncThunk('ENTRY', async (payload) => {
    const response = await getEntry(payload.id, payload.mode);
    return {
        id: payload.id, response
    };
})

export const fetchTeams = createAsyncThunk(DATABASE_ACTIONS.DATABASE_TEAM, async (payload) => {
    const response = await getTeams();
    return {
        response
    };
})

export const fetchTeamById = createAsyncThunk(DATABASE_ACTIONS.DATABASE_TEAM_ID, async (id) => {
    const response = await getTeamById(id);
    return {
        response
    };
})

export const fetchPlayerById = createAsyncThunk(DATABASE_ACTIONS.DATABASE_PLAYER_ID, async (id) => {
    const response = await getPlayerById(id);
    return {
        response
    };
})

export const fetchPlayers = createAsyncThunk(DATABASE_ACTIONS.DATABASE_PLAYERS, async (payload) => {
    const response = await getPlayers();
    return {
        response
    };
})

export const fetchCourts = createAsyncThunk(DATABASE_ACTIONS.DATABASE_COURTS, async (tournamentId) => {
    const response = await getCourts(tournamentId);
    return {
        response
    };
})

export const fetchTournamentPlayers = createAsyncThunk(DATABASE_ACTIONS.DATABASE_TOURNAMENT_PLAYERS, async (id) => {
    const response = await getTournamentPlayers(id);
    return {
        response
    };
})

export const fetchTournamentMatches = createAsyncThunk(DATABASE_ACTIONS.DATABASE_TOURNAMENT_MATCHES, async (id) => {
    const response = await getTournamentMatches(id);
    return {
        response
    };
})

export const addNewPlayer = createAsyncThunk(DATABASE_ACTIONS.DATABASE_ADD_NEW_PLAYER, async (playerInfo) => {
    const response = await createNewPlayer(playerInfo);
    return {
        response
    };
})

export const updatePlayer = createAsyncThunk(DATABASE_ACTIONS.DATABASE_UPDATE_PLAYER, async ({playerId, playerInfo}) => {
    const response = await dbUpdatePlayer(playerId, playerInfo);
    return {
        response
    };
})

export const updateCourt = createAsyncThunk(DATABASE_ACTIONS.DATABASE_UPDATE_COURT, async ({tournamentId, courtNumber, courtInfo}) => {
    const response = await dbUpdateCourt(tournamentId, courtNumber, courtInfo);
    return {
        response
    };
})

export const addNewMatch = createAsyncThunk(DATABASE_ACTIONS.DATABASE_ADD_NEW_MATCH, async ({tournamentId, levelId, matchInfo}) => {
    const response = await createNewMatch(tournamentId, levelId, matchInfo);
    return {
        response
    };
})

export const validateUser = payload => ({
    type: 'AUTH_USER',
    payload
})

export const fetchEvents = createAsyncThunk(DATABASE_ACTIONS.DATABASE_EVENTS, async (payload) => {
    const response = await getEvents();
    return {
        response
    };
})

export const fetchEventById = createAsyncThunk(DATABASE_ACTIONS.DATABASE_EVENTS_ID, async (id) => {
    const response = await getEventById(id);
    return {
        response
    };
})

export const openNewPlayerModal = () => {
    return {
        type: 'OPEN_NEW_PLAYER_MODAL',
    };
};

export const closeNewPlayerModal = () => {
    return {
        type: 'CLOSE_NEW_PLAYER_MODAL',
    };
};
