import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import styles from "./EventDetailPage.module.scss"
import commonMessages from "./../../common/messages";

import { Document, Page, pdfjs } from 'react-pdf';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import LoadingOverlay from 'react-loading-overlay';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

import { fetchEventById } from "../../redux/actions";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function EventsPage(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [numPages, setNumPages] = useState(0);
    const [numPagesWarning, setNumPagesWarning] = useState(0);
    const [numResults, setNumResults] = useState(0);
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(false);
    const eventId = props && props.match && props.match.params && props.match.params.eventId;

    useEffect(() => {
        setLoading(true);
        dispatch(fetchEventById(eventId))
            .then(res => {
                setLoading(false);
                console.log(res);
                const results = res && res.payload && res.payload.response
                    && res.payload.response.result || {};
                setEvent(results);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    function onDocumentCoronaLoadSuccess({ numPages }) {
        setNumPagesWarning(numPages);
    }
    function onDocumentResultsLoadSuccess({ numPages }) {
        setNumResults(numPages);
    }

    function getWidth() {
        const w = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
        if (w < 600) {
            return w - 30
        }
        else {
            return w / 2;
        }
    }
    function renderAnnouncement(event) {
        if (!event || event.announced !== "yes") {
            return null;
        }
        return <Container>
            <Document
                file={`/events/${eventId}/content.pdf`}
                onLoadError={console.error}
                onLoadSuccess={onDocumentLoadSuccess}
                options={{
                    cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                    cMapPacked: true,
                }}
            >
                {Array(numPages).fill(0).map((a, index) => {
                    return <>
                        <Page pageNumber={index + 1} width={getWidth()} />
                        <hr />
                    </>;
                })}
            </Document>
        </Container>;
    }

    function renderWarningCorona(event) {
        if (!event || event.warning_corona !== "yes") {
            return null;
        }
        return <Container>
            <Document
                file={`/events/${eventId}/warning_corona.pdf`}
                onLoadError={console.error}
                onLoadSuccess={onDocumentCoronaLoadSuccess}
                options={{
                    cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                    cMapPacked: true,
                }}
            >
                {Array(numPagesWarning).fill(0).map((a, index) => {
                    return <>
                        <Page pageNumber={index + 1} width={getWidth()} />
                        <hr />
                    </>;
                })}
            </Document>
        </Container>;
    }

    function renderResults(event) {
        console.log(event);
        if (!event || event.results !== "yes") {
            return null;
        }
        return <Container>
            <Document
                file={`/events/${eventId}/results.pdf`}
                onLoadError={console.error}
                onLoadSuccess={onDocumentResultsLoadSuccess}
                options={{
                    cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
                    cMapPacked: true,
                }}
            >
                {Array(numResults).fill(0).map((a, index) => {
                    return <>
                        <Page pageNumber={index + 1} width={getWidth()} />
                        <hr />
                    </>;
                })}
            </Document>
        </Container>;
    }

    function renderImages(event) {
        if (!event || !Number.isInteger(Number(event.images))) {
            return null;
        }
        const imagesNo = Number(event.images);
        if (imagesNo < 1) {
            return null;
        }
        const images = Array(imagesNo).fill(0).map(
            (val, index) => {
                return {
                    original: `/events/${eventId}/images/${val + index + 1}.jpg`,
                    thumbnail: `/events/${eventId}/images/${val + index + 1}.jpg`
                };
            }
        );
        return <Container>
            <ImageGallery items={images}
                lazyLoad
            />
        </Container>;
    }

    function renderDetail(event) {
        if (loading) {
            return <LoadingOverlay
                active={true} spinner>
                {/* <div style={{ height: '100vh', width: '100%' }}></div> */}
            </ LoadingOverlay>;
        }
        if (!event || !event.name) {
            return <div className={styles.EventDetailPage}>
                {intl.formatMessage(commonMessages.no_data)}
            </div>
        }
        return <div className={styles.EventDetailPage}>
            <Breadcrumb className={styles.Breadcrumb}>
                <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHome} />{" "}{intl.formatMessage(commonMessages.home)}</Breadcrumb.Item>
                <Breadcrumb.Item href="/events">
                    {intl.formatMessage(commonMessages.events)}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>{intl.formatMessage(commonMessages.details)}</Breadcrumb.Item>
            </Breadcrumb>
            <Jumbotron fluid>
                {renderImages(event)}
                {renderResults(event)}
                {renderAnnouncement(event)}
                {renderWarningCorona(event)}
            </Jumbotron>

        </div>;

    }
    return renderDetail(event);

}

export default EventsPage;