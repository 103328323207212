import React, { useEffect, useState } from 'react';

import styles from "./PlayerDetailPage.module.scss"

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux'
import commonMessages from "./../../common/messages";
import Breadcrumb from 'react-bootstrap/Breadcrumb'

import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faShoePrints, faRocket, faVial, faGamepad, faGuitar, faUserTie, faCalendar, faGraduationCap, faAudioDescription, faEnvelope, faStar, faFlag, faAddressCard, faUsers, faTrophy, faHome, faUser } from '@fortawesome/free-solid-svg-icons'

import { fetchPlayerById, updatePlayer } from "../../redux/actions";
import firebase from '../../Firebase';
import { toast } from 'react-toastify';

function PlayerDetailPage(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [player, setPlayer] = useState([]);
    const [teams, setTeams] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);
    const playerId = props && props.match && props.match.params && parseInt(props.match.params.playerid);

    useEffect(() => {
        console.log(props);
        setLoading(true);
        dispatch(fetchPlayerById(playerId))
            .then(res => {
                setLoading(false);
                console.log(res);
                const results = res && res.payload && res.payload.response
                    && res.payload.response.result || {};
                setPlayer(results[0]);
                setTeams(results[1]);
                setAdmins(results[2]);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <Spinner animation="border" variant="primary" />;
    }

    const user = firebase.auth().currentUser;

    function handleEditPlayer() {
        setEditing(true)
    }

    function handleCloseEditPlayer() {
        setEditing(false);
    }

    function handleSavePlayer() {
        const name = document.getElementById('name').innerHTML || '';
        const email = document.getElementById('email').innerHTML || '';
        const birthday = document.getElementById('birthday').innerHTML || '';
        const avatar = document.getElementById('avatar').innerHTML || '';
        const description = document.getElementById('description').innerHTML || '';
        const equipment = document.getElementById('equipment').innerHTML || '';
        const facebook = document.getElementById('facebook').innerHTML || '';
        const level = document.getElementById('level').innerHTML || 4;
        const nation = document.getElementById('nation').innerHTML || 'Vietnam🇻🇳';
        const point = document.getElementById('point').innerHTML || 0;
        const racket = document.getElementById('racket').innerHTML || '';
        const shoe = document.getElementById('shoe').innerHTML || '';
        const string = document.getElementById('string').innerHTML || '';
        const team_id = document.getElementById('team_id').innerHTML || 0;
        const tension = document.getElementById('tension').innerHTML || '';
        const title = document.getElementById('title').innerHTML || '';

        const playerInfo = {
            name: name,
            email: email,
            birthday: birthday,
            avatar: avatar,
            description: description,
            equipment: equipment,
            facebook: facebook,
            level: level,
            nation: nation,
            point: point,
            racket: racket,
            shoe: shoe,
            string: string,
            team_id: team_id,
            tension: tension,
            title: title
        };

        dispatch(updatePlayer({playerId, playerInfo}))
            .then(res => {
                player.name = name;
                player.avatar = avatar;
                toast.success("Player updated successfully!");
                setEditing(false);
            })
            .catch(error => {
                toast.error('Player updated failed!');
                console.log(error);
            });
    }

    if (!admins.includes(user.email)) {
        return <div className={styles.LandingPage}>
            <Jumbotron fluid>
                <Container>
                    <h1>{intl.formatMessage(commonMessages.team_not_found)}</h1>
                    <Table striped bordered hover>
                        <tbody>
                            <tr>
                                <td><FontAwesomeIcon icon={faAddressCard} /> {" "} {intl.formatMessage(commonMessages.contact)}</td>
                                <td>
                                    <p>
                                        <Button variant="link"
                                            onClick={() => window.open("https://www.facebook.com/groups/803523007104821/about", "vban_about")}>
                                            <FontAwesomeIcon icon={faFacebook} /> Facebook
                                    </Button>
                                        <br />
                                        <Button variant="danger">
                                            <FontAwesomeIcon icon={faGoogle} /> Mail: vban.contact@gmail.com
                                    </Button>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                </Container>

            </Jumbotron>
        </div>;
    }
    return <div className={styles.LandingPage}>
        <Breadcrumb className={styles.Breadcrumb}>
            <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHome} />{" "}{intl.formatMessage(commonMessages.home)}</Breadcrumb.Item>
            <Breadcrumb.Item active>{intl.formatMessage(commonMessages.details)}</Breadcrumb.Item>
        </Breadcrumb>
        <Jumbotron fluid>
            <Container>
                <div className={styles.PLayerAvatar}>
                    <img src={player.avatar.length ? player.avatar : 'player_avatar.png'} alt={`${player.name} avatar`} className={styles.Avatar} />
                    <div className={styles.PLayerNameWrapper}>
                        <h1 className={styles.PLayerName}>{String(player.name).toUpperCase()}</h1>
                        {editing ? (
                            <div>
                                <button className={`${styles.EditButton} ${styles.SaveButton}`} onClick={handleSavePlayer}>Save</button>
                                <button className={`${styles.EditButton} ${styles.CancelButton}`} onClick={handleCloseEditPlayer}>Cancel</button>
                            </div>
                        ) : (
                            <button className={styles.EditButton} onClick={handleEditPlayer}>Edit</button> 
                        )}
                    </div>
                </div>
                <Table striped bordered hover>
                    <tbody>
                        <tr>
                            <td><FontAwesomeIcon icon={faUser} /> {" "} Name</td>
                            <td contentEditable={editing ? true : false} id='name'>{player.name}</td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faFlag} /> {" "} Nation</td>
                            <td contentEditable={editing ? true : false} id='nation'>{player.nation}</td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faUsers} /> {" "} Team: </td>
                            {editing ? (
                                <td contentEditable id='team_id'>{player.team_id}</td>
                            ) : (
                                <td>{teams[player.team_id].name}</td>
                            )}
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faGraduationCap} /> {" "} Level</td>
                            <td contentEditable={editing ? true : false} id='level'>{player.level}</td>
                        </tr>
                        { editing &&
                            <tr>
                                <td><FontAwesomeIcon icon={faUserTie} /> {" "} Avatar</td>
                                <td contentEditable={editing ? true : false} id='avatar'>{player.avatar}</td>
                            </tr>
                        }
                        <tr>
                            <td><FontAwesomeIcon icon={faStar} /> {" "} Point</td>
                            <td contentEditable={editing ? true : false} id='point'>{player.point}</td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faEnvelope} /> {" "} Email</td>
                            <td contentEditable={editing ? true : false} id='email'>{player.email}</td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faCalendar} /> {" "} Birthday</td>
                            <td contentEditable={editing ? true : false} id='birthday'>{player.birthday}</td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faAudioDescription} /> {" "} Description</td>
                            <td contentEditable={editing ? true : false} id='description'>{player.description}</td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faRocket} /> {" "} Racket</td>
                            <td contentEditable={editing ? true : false} id='racket'>{player.racket}</td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faShoePrints} /> {" "} Shoe</td>
                            <td contentEditable={editing ? true : false} id='shoe'>{player.shoe}</td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faGuitar} /> {" "} String</td>
                            <td contentEditable={editing ? true : false} id='string'>{player.string}</td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faVial} /> {" "} Tension</td>
                            <td contentEditable={editing ? true : false} id='tension'>{player.tension}</td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faGamepad} /> {" "} Other equipment</td>
                            <td contentEditable={editing ? true : false} id='equipment'>{player.equipment}</td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faTrophy} /> {" "} Tittle</td>
                            <td contentEditable={editing ? true : false} id='title'>{player.title}</td>
                        </tr>
                        <tr>
                            <td><FontAwesomeIcon icon={faAddressCard} /> {" "} SNS</td>
                            {editing ? (
                                <td contentEditable id='facebook'>{player.facebook}</td>
                            ) : (
                                <td>
                                    <Button variant="link"
                                        onClick={() => window.open(`${player.facebook}`)}>
                                        <FontAwesomeIcon icon={faFacebook} /> Facebook
                                    </Button>
                                </td>
                            )}
                        </tr>
                    </tbody>
                </Table>
            </Container>

        </Jumbotron>
    </div>;

}

export default PlayerDetailPage;
