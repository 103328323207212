import Papa from 'papaparse'
import { database } from '../../Firebase'

export const getTeams = async () => {
    try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const result = await _getTeamsCSV();
        return { result };
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。
        console.log("error!!");
        return error;
    }
};

const _getTeamsCSV = async () => {
    try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const response = await fetch("/data/team.csv");
        const reader = response.body.getReader()
        const result = await reader.read() // raw array
        const decoder = new TextDecoder('utf-8')
        const csv = decoder.decode(result.value) // the csv text
        const results = Papa.parse(csv, { header: true })
        const rows = results.data
        // console.log(rows);
        return rows;
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。
        console.log("error!!");
        return error;
    }
};

export const getPlayerById = async (id) => {
    console.log(id);
    try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const result = await _getPlayerById(id);
        return { result };
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。
        console.log("error!!");
        return error;
    }
};

const _getPlayerById = async (id) => {
    if (!id) return null;
    try {
        const playerRef = database.ref(`Player/${id}/`);
        const playerSnapshot = await playerRef.once('value');
        const playerList = playerSnapshot.val();

        const teamRef = database.ref('Team/');
        const teamSnapshot = await teamRef.once('value');
        const teamList = teamSnapshot.val();

        const adminRef = database.ref('Admin/');
        const adminSnapshot = await adminRef.once('value');
        const admintList = adminSnapshot.val();

        return [playerList, teamList, admintList];
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。
        console.log("error!!");
        return error;
    }
};

export const getTeamById = async (id) => {
    try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const result = await _getTeamByIdCSV(id);
        return { result };
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。
        console.log("error!!");
        return error;
    }
};

const _getTeamByIdCSV = async (id) => {
    if (!id) return null;
    try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const response = await fetch("/data/team.csv");
        const reader = response.body.getReader()
        const result = await reader.read() // raw array
        const decoder = new TextDecoder('utf-8')
        const csv = decoder.decode(result.value) // the csv text
        const results = Papa.parse(csv, { header: true })
        const rows = results.data || [];
        const row = rows.find(r => r.team_code && String(r.team_code).toLowerCase() === String(id).toLowerCase());
        return row;
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。
        console.log("error!!");
        return error;
    }
};

export const getPlayers = async () => {
    try {
        const result = await _getPlayers();
        return { result };
    } catch (error) {
        console.log("error!!");
        return error;
    }
};

const _getPlayers = async () => {
    try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const response = await fetch("/data/players.csv");
        const reader = response.body.getReader()
        const result = await reader.read() // raw array
        const decoder = new TextDecoder('utf-8')
        const csv = decoder.decode(result.value) // the csv text
        const results = Papa.parse(csv, { header: true })
        const rows = results.data || [];
        return rows;
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。
        console.log("error!!");
        return error;
    }
};

export const getCourts = async (tournamentId) => {
    try {
        const result = await _getCourts(tournamentId);
        return { result };
    } catch (error) {
        console.log("error!!");
        return error;
    }
};

const _getCourts = async (tournamentId) => {
    try {
        const courtRef = database.ref(`Tournament/${tournamentId}/court`);
        const courtSnapshot = await courtRef.once('value');
        const courtList = courtSnapshot.val();

        const adminRef = database.ref('Admin/');
        const adminSnapshot = await adminRef.once('value');
        const admintList = adminSnapshot.val();

        return [courtList, admintList];
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。 
        console.log("error!!");
        return error;
    }
};

export const getTournamentMatches = async (id) => {
    try {
        const result = await _getTournamentMatches(id);
        return { result };
    } catch (error) {
        console.log("error!!");
        return error;
    }
};

const _getTournamentMatches = async (id) => {
    try {
        const playerRef = database.ref('Player/');
        const playerSnapshot = await playerRef.once('value');
        const playerList = playerSnapshot.val();

        const tournamentRef = database.ref(`Tournament/${id}/`);
        const tournamentSnapshot = await tournamentRef.once('value');
        const tournament = tournamentSnapshot.val();

        const teamRef = database.ref('Team/');
        const teamSnapshot = await teamRef.once('value');
        const teamList = teamSnapshot.val();

        const adminRef = database.ref('Admin/');
        const adminSnapshot = await adminRef.once('value');
        const admintList = adminSnapshot.val();

        return [playerList, tournament, teamList, admintList];
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。
        console.log("error!!");
        return error;
    }
};

export const getTournamentPlayers = async (id) => {
    try {
        const result = await _getTournamentPlayers(id);
        return { result };
    } catch (error) {
        console.log("error!!");
        return error;
    }
};

const _getTournamentPlayers = async (id) => {
    try {
        const playerRef = database.ref('Player/');
        const playerSnapshot = await playerRef.once('value');
        const playerList = playerSnapshot.val();

        const tournamentRef = database.ref(`Tournament/${id}/`);
        const tournamentSnapshot = await tournamentRef.once('value');
        const tournament = tournamentSnapshot.val();

        const teamRef = database.ref('Team/');
        const teamSnapshot = await teamRef.once('value');
        const teamList = teamSnapshot.val();

        const adminRef = database.ref('Admin/');
        const adminSnapshot = await adminRef.once('value');
        const admintList = adminSnapshot.val();

        return [playerList, tournament, teamList, admintList];
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。
        console.log("error!!");
        return error;
    }
};

export const createNewPlayer = async (playerInfo) => {
    try {
        const result = await _createNewPlayer(playerInfo);
        return { result };
    } catch (error) {
        console.log("error!!");
        return error;
    }
};

const _createNewPlayer = async (playerInfo) => {
    try {
        const playerRef = database.ref('Player/');

        await playerRef.limitToLast(1).once('value').then(function(snapshot) {
            const newID = parseInt(Object.keys(snapshot.val())[0]) + 1;
            let newPlayer = {};
            newPlayer[newID] = playerInfo;
            playerRef.update(newPlayer);
        })
    } catch (error) {
        console.log("error!!");
        return error;
    }
};

export const dbUpdatePlayer = async (playerId, playerInfo) => {
    try {
        const result = await _dbUpdatePlayer(playerId, playerInfo);
        return { result };
    } catch (error) {
        console.log("error!!");
        return error;
    }
};

const _dbUpdatePlayer = async (playerId, playerInfo) => {
    try {
        const playerRef = database.ref(`Player/${playerId}/`);
        await playerRef.update(playerInfo);
    } catch (error) {
        console.log("error!!");
        return error;
    }
};

export const dbUpdateCourt = async (tournamentId, courtNumber, courtInfo) => {
    try {
        const result = await _dbUpdateCourt(tournamentId, courtNumber, courtInfo);
        return { result };
    } catch (error) {
        console.log("error!!");
        return error;
    }
};

const _dbUpdateCourt = async (tournamentId, courtNumber, courtInfo) => {
    try {
        const playerRef = database.ref(`Tournament/${tournamentId}/court/${courtNumber}/`);
        await playerRef.update(courtInfo);
    } catch (error) {
        console.log("error!!");
        return error;
    }
};

export const createNewMatch = async (tournamentId, levelId, matchInfo) => {
    try {
        const result = await _createNewMatch(tournamentId, levelId, matchInfo);
        return { result };
    } catch (error) {
        console.log("error!!");
        return error;
    }
};

const _createNewMatch = async (tournamentId, levelId, matchInfo) => {
    try {
        const matchRef = database.ref('Tournament/' + tournamentId + '/level/' + levelId + '/matches/');

        await matchRef.limitToLast(1).once('value').then(function(snapshot) {
            if (!snapshot.val()) {
                const levelRef = database.ref('Tournament/' + tournamentId + '/level/' + levelId + '/');
                const matches = {
                    matches: {
                        0: matchInfo
                    }
                }
                levelRef.update(matches);
            } else {
                const newID = parseInt(Object.keys(snapshot.val())[0]) + 1;
                let newMatch = {};
                newMatch[newID] = matchInfo;

                matchRef.update(newMatch);
            }
        })
    } catch (error) {
        console.log("error!!");
        return error;
    }
};

export const getEvents = async () => {
    try {
        const result = await _getEvents();
        return { result };
    } catch (error) {
        console.log("error!!");
        return error;
    }
};

const _getEvents = async () => {
    try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const response = await fetch("/data/events.csv");
        const reader = response.body.getReader()
        const result = await reader.read() // raw array
        const decoder = new TextDecoder('utf-8')
        const csv = decoder.decode(result.value) // the csv text
        const results = Papa.parse(csv, { header: true })
        const rows = results.data || [];
        return rows;
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。
        console.log("error!!");
        return error;
    }
};

export const getEventById = async (id) => {
    console.log(id);
    try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const result = await _getEventIdCSV(id);
        return { result };
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。
        console.log("error!!");
        return error;
    }
};

const _getEventIdCSV = async (id) => {
    if (!id) return null;
    try {
        //ここでGETメソッドを使用してgithubのプロフィールを取得します。
        const response = await fetch("/data/events.csv");
        const reader = response.body.getReader()
        const result = await reader.read() // raw array
        const decoder = new TextDecoder('utf-8')
        const csv = decoder.decode(result.value) // the csv text
        const results = Papa.parse(csv, { header: true })
        const rows = results.data || [];
        const row = rows.find(r => r.id && String(r.id).toLowerCase() === String(id).toLowerCase());
        return row;
    } catch (error) {
        //ここでリクエストに失敗した時の処理、メッセージを記述します。
        console.log("error!!");
        return error;
    }
};
