import React, { useEffect, useState } from 'react';

import "./IncomingMatchPage.scss"

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux'
import Spinner from 'react-bootstrap/Spinner';

import { fetchTournamentMatches } from "../../redux/actions";
import firebase from '../../Firebase';
import { toast } from 'react-toastify';

import { database } from '../../Firebase'
import moment from "moment";
import { func } from 'prop-types';


function IncomingMatchPage(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [players, setPlayers] = useState([]);
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tournament, setTournament] = useState([]);
    const [admins, setAdmins] = useState([]);

    const [courtOnePairOneScore, setCourtOnePairOneScore] = useState(0);
    const [courtOnePairTwoScore, setCourtOnePairTwoScore] = useState(0);
    const [courtTwoPairOneScore, setCourtTwoPairOneScore] = useState(0);
    const [courtTwoPairTwoScore, setCourtTwoPairTwoScore] = useState(0);
    const [courtThreePairOneScore, setCourtThreePairOneScore] = useState(0);
    const [courtThreePairTwoScore, setCourtThreePairTwoScore] = useState(0);
    const [courtFourPairOneScore, setCourtFourPairOneScore] = useState(0);
    const [courtFourPairTwoScore, setCourtFourPairTwoScore] = useState(0);

    const [courtOneCurrentMatchID, setCourtOneCurrentMatchID] = useState(0);
    const [courtOneNextMatchID, setCourtOneNextMatchID] = useState(0);
    const [courtTwoCurrentMatchID, setCourtTwoCurrentMatchID] = useState(0);
    const [courtTwoNextMatchID, setCourtTwoNextMatchID] = useState(0);
    const [courtThreeCurrentMatchID, setCourtThreeCurrentMatchID] = useState(0);
    const [courtThreeNextMatchID, setCourtThreeNextMatchID] = useState(0);
    const [courtFourCurrentMatchID, setCourtFourCurrentMatchID] = useState(0);
    const [courtFourNextMatchID, setCourtFourNextMatchID] = useState(0);
    

    


    const user = firebase.auth().currentUser;
    const tournamentId = props && props.match && props.match.params && parseInt(props.match.params.tournamentId);

    useEffect(() => {
        setLoading(true);
        dispatch(fetchTournamentMatches(tournamentId))
            .then(res => {
                setLoading(false);
                const results = res && res.payload && res.payload.response
                    && res.payload.response.result || {};

                setPlayers(results[0]);
                setTournament(results[1]);
                setTeams(results[2]);
                setAdmins(results[3]);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    
    useEffect(() => {
        if (tournamentId == 2 && tournament && tournament.level) {
            // set match id
            const courtOneCurrentMatchIDRef = database.ref(`Tournament/2/court/1/current_match_id`);
            const courtOneNextMatchIDRef = database.ref(`Tournament/2/court/1/next_match_id`);
            const courtTwoCurrentMatchIDRef = database.ref(`Tournament/2/court/2/current_match_id`);
            const courtTwoNextMatchIDRef = database.ref(`Tournament/2/court/2/next_match_id`);
            const courtThreeCurrentMatchIDRef = database.ref(`Tournament/2/court/3/current_match_id`);
            const courtThreeNextMatchIDRef = database.ref(`Tournament/2/court/3/next_match_id`);
            const courtFourCurrentMatchIDRef = database.ref(`Tournament/2/court/4/current_match_id`);
            const courtFourNextMatchIDRef = database.ref(`Tournament/2/court/4/next_match_id`);
            
            courtOneCurrentMatchIDRef.on('value', (snapshot) => {
                const updatedMatchID = snapshot.val();
                setCourtOneCurrentMatchID(updatedMatchID);
            });

            courtOneNextMatchIDRef.on('value', (snapshot) => {
                const updatedMatchID = snapshot.val();
                setCourtOneNextMatchID(updatedMatchID);
            });

            courtTwoCurrentMatchIDRef.on('value', (snapshot) => {
                const updatedMatchID = snapshot.val();
                setCourtTwoCurrentMatchID(updatedMatchID);
            });

            courtTwoNextMatchIDRef.on('value', (snapshot) => {
                const updatedMatchID = snapshot.val();
                setCourtTwoNextMatchID(updatedMatchID);
            });

            courtThreeCurrentMatchIDRef.on('value', (snapshot) => {
                const updatedMatchID = snapshot.val();
                setCourtThreeCurrentMatchID(updatedMatchID);
            });

            courtThreeNextMatchIDRef.on('value', (snapshot) => {
                const updatedMatchID = snapshot.val();
                setCourtThreeNextMatchID(updatedMatchID);
            });

            courtFourCurrentMatchIDRef.on('value', (snapshot) => {
                const updatedMatchID = snapshot.val();
                setCourtFourCurrentMatchID(updatedMatchID);
            });

            courtFourNextMatchIDRef.on('value', (snapshot) => {
                const updatedMatchID = snapshot.val();
                setCourtFourNextMatchID(updatedMatchID);
            });
    
        }
    }, [tournament.level]);

    useEffect(() => {
        if (courtOneCurrentMatchID && courtOneNextMatchID && courtTwoCurrentMatchID && courtTwoNextMatchID && courtThreeCurrentMatchID && courtThreeNextMatchID && courtFourCurrentMatchID && courtFourNextMatchID) {
            // set score
            let currentCourtOneLevel = courtOneCurrentMatchID.substring(0, 2) == 'AD' ? 1 : 2;
            let currentCourtOneMatchIndex = tournament.level[currentCourtOneLevel].matches.findIndex((match, index) => match.name == courtOneCurrentMatchID);

            let currentCourtTwoLevel = courtTwoCurrentMatchID.substring(0, 2) == 'AD' ? 1 : 2;
            let currentCourtTwoMatchIndex = tournament.level[currentCourtTwoLevel].matches.findIndex((match, index) => match.name == courtTwoCurrentMatchID);

            let currentCourtThreeLevel = courtThreeCurrentMatchID.substring(0, 2) == 'AD' ? 1 : 2;
            let currentCourtThreeMatchIndex = tournament.level[currentCourtThreeLevel].matches.findIndex((match, index) => match.name == courtThreeCurrentMatchID);

            let currentCourtFourLevel = courtFourCurrentMatchID.substring(0, 2) == 'AD' ? 1 : 2;
            let currentCourtFourMatchIndex = tournament.level[currentCourtFourLevel].matches.findIndex((match, index) => match.name == courtFourCurrentMatchID);

            let courtOnePairOneScoreRef = database.ref(`Tournament/2/level/${currentCourtOneLevel}/matches/${currentCourtOneMatchIndex}/pair_1/score`);
            let courtOnePairTwoScoreRef = database.ref(`Tournament/2/level/${currentCourtOneLevel}/matches/${currentCourtOneMatchIndex}/pair_2/score`);

            let courtTwoPairOneScoreRef = database.ref(`Tournament/2/level/${currentCourtTwoLevel}/matches/${currentCourtTwoMatchIndex}/pair_1/score`);
            let courtTwoPairTwoScoreRef = database.ref(`Tournament/2/level/${currentCourtTwoLevel}/matches/${currentCourtTwoMatchIndex}/pair_2/score`);

            let courtThreePairOneScoreRef = database.ref(`Tournament/2/level/${currentCourtThreeLevel}/matches/${currentCourtThreeMatchIndex}/pair_1/score`);
            let courtThreePairTwoScoreRef = database.ref(`Tournament/2/level/${currentCourtThreeLevel}/matches/${currentCourtThreeMatchIndex}/pair_2/score`);

            let courtFourPairOneScoreRef = database.ref(`Tournament/2/level/${currentCourtFourLevel}/matches/${currentCourtFourMatchIndex}/pair_1/score`);
            let courtFourPairTwoScoreRef = database.ref(`Tournament/2/level/${currentCourtFourLevel}/matches/${currentCourtFourMatchIndex}/pair_2/score`);

            courtOnePairOneScoreRef.on('value', (snapshot) => {
                const updatedScore = snapshot.val();
                setCourtOnePairOneScore(updatedScore);
            });
    
            courtOnePairTwoScoreRef.on('value', (snapshot) => {
                const updatedScore = snapshot.val();
                setCourtOnePairTwoScore(updatedScore);
            });

            courtTwoPairOneScoreRef.on('value', (snapshot) => {
                const updatedScore = snapshot.val();
                setCourtTwoPairOneScore(updatedScore);
            });

            courtTwoPairTwoScoreRef.on('value', (snapshot) => { 
                const updatedScore = snapshot.val();
                setCourtTwoPairTwoScore(updatedScore);
            });

            courtThreePairOneScoreRef.on('value', (snapshot) => {
                const updatedScore = snapshot.val();
                setCourtThreePairOneScore(updatedScore);
            });

            courtThreePairTwoScoreRef.on('value', (snapshot) => {
                const updatedScore = snapshot.val();
                setCourtThreePairTwoScore(updatedScore);
            });

            courtFourPairOneScoreRef.on('value', (snapshot) => {
                const updatedScore = snapshot.val();
                setCourtFourPairOneScore(updatedScore);
            });

            courtFourPairTwoScoreRef.on('value', (snapshot) => {
                const updatedScore = snapshot.val();
                setCourtFourPairTwoScore(updatedScore);
            });
    
        }
    }, [courtOneCurrentMatchID, courtOneNextMatchID, courtTwoCurrentMatchID, courtTwoNextMatchID, courtThreeCurrentMatchID, courtThreeNextMatchID, courtFourCurrentMatchID, courtFourNextMatchID]);

    if (loading) {
        return <Spinner animation="border" variant="primary" />;
    }

    function getMatchesById(id) {
        const levelName = id.substring(0, 2);
        const levelId = levelName == 'AD' ? 1 : 2;
        const level = tournament.level[levelId];

        for (let match of level.matches) {
            if (match.name == id) {
                return {
                    pair_1: {
                        player_1_name: getPlayerNameById(level.draw[match.pair_1.id].player_id_1),
                        player_2_name: getPlayerNameById(level.draw[match.pair_1.id].player_id_2),
                        score: match.pair_1.score
                    }, 
                    pair_2: {
                        player_1_name: getPlayerNameById(level.draw[match.pair_2.id].player_id_1),
                        player_2_name: getPlayerNameById(level.draw[match.pair_2.id].player_id_2),
                        score: match.pair_2.score
                    }
                }
            }
        }

        return {pair_1: {player_1_name: 'Sample', player_2_name: 'Sample', score: 0}, pair_2: {player_1_name: 'Sample', player_2_name: 'Sample', score: 0}};
    }

    function getPlayerNameById(playerId) {
        return players[playerId].name;
    }

    function getLastWordOfName(name) {
        const words = name.split(' ');
        return words[words.length - 1];
    }

    function getFirstPairScoreByCourtNumber(courtNumber) {
        switch (courtNumber) {
            case 1:
                return courtOnePairOneScore;
            case 2:
                return courtTwoPairOneScore;
            case 3:
                return courtThreePairOneScore;
            case 4:
                return courtFourPairOneScore;
        }
    }

    function getSecondPairScoreByCourtNumber(courtNumber) {
        switch (courtNumber) {
            case 1:
                return courtOnePairTwoScore;
            case 2:
                return courtTwoPairTwoScore;
            case 3:
                return courtThreePairTwoScore;
            case 4:
                return courtFourPairTwoScore;
        }
    }

    function getCurrentMatchIdByCourtNumber(courtNumber) {
        switch (courtNumber) {
            case 1:
                return courtOneCurrentMatchID;
            case 2:
                return courtTwoCurrentMatchID;
            case 3:
                return courtThreeCurrentMatchID;
            case 4:
                return courtFourCurrentMatchID;
        }
    }

    function getNextMatchIdByCourtNumber(courtNumber) {
        switch (courtNumber) {
            case 1:
                return courtOneNextMatchID;
            case 2:
                return courtTwoNextMatchID;
            case 3:
                return courtThreeNextMatchID;
            case 4:
                return courtFourNextMatchID;
        }
    }

    if (tournamentId == 2 && tournament && tournament.level && courtOneCurrentMatchID && courtOneNextMatchID && courtTwoCurrentMatchID && courtTwoNextMatchID && courtThreeCurrentMatchID && courtThreeNextMatchID && courtFourCurrentMatchID && courtFourNextMatchID) {

        return <div class='incoming-matches-page'>
            { 
                tournament.court.map((court, index) => {
                    const current_pair_1 = getMatchesById(getCurrentMatchIdByCourtNumber(index)).pair_1;
                    const current_pair_2 = getMatchesById(getCurrentMatchIdByCourtNumber(index)).pair_2;
                    const next_pair_1 = getMatchesById(getNextMatchIdByCourtNumber(index)).pair_1;
                    const next_pair_2 = getMatchesById(getNextMatchIdByCourtNumber(index)).pair_2;
                    return (
                        <div key={index} class='court'>
                            <h2 class='court-name'>Court {index}</h2>
                            <div class='match current'>
                                <h2>Current Match ({getCurrentMatchIdByCourtNumber(index)})</h2>
                                <div class='score-wrapper'>
                                    <div class='left-part'>
                                        <div class='score'>{getFirstPairScoreByCourtNumber(index)}</div>
                                        <div class='player player-one'>{current_pair_1.player_1_name}</div>
                                        <div class='player player-two'>{current_pair_1.player_2_name}</div>
                                    </div>
                                    
                                    <div class='score-vs-label'> - </div>
                                    <div class='right-part'>
                                        <div class='score'>{getSecondPairScoreByCourtNumber(index)}</div>
                                        <div class='player player-one'>{current_pair_2.player_1_name}</div>
                                        <div class='player player-two'>{current_pair_2.player_2_name}</div>
                                    </div>
                                </div>
                            </div>
                            <div class='match next'>
                                <span>Next Match: </span>
                                <span class='next-team'>
                                    {getLastWordOfName(next_pair_1.player_1_name)} / {getLastWordOfName(next_pair_1.player_2_name)} vs {getLastWordOfName(next_pair_2.player_1_name)} / {getLastWordOfName(next_pair_2.player_2_name)} ({getNextMatchIdByCourtNumber(index)})
                                </span>
                            </div>
                        </div>
                    )
                })
            } 
        </div>;
    }

    return <div></div>;

}

export default IncomingMatchPage;
