import React from 'react';
import Card from 'react-bootstrap/Card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import styles from "./ScoreItem.module.scss";
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { faFutbol } from '@fortawesome/free-solid-svg-icons'


function ScoreItem({ players = [], score = 0, serve = false, onClick, team }) {

    return <Card className={classnames("text-center", styles.ScoreItem)} onClick={onClick}>
        <Card.Header className={styles.Header}>
            {team}
        </Card.Header>
        <Card.Body>
            <div className={styles.Score}>
                <div className={styles.name}>
                    {players.map((p, index) => <div key={index}>{p}</div>)}
                </div>
                <div className={styles.score}>{score}</div>
                <div className={styles.serve}>{serve ? <FontAwesomeIcon icon={faFutbol} /> : " "}</div>
            </div>
        </Card.Body>
    </Card>;

}

ScoreItem.propTypes = {
    players: PropTypes.arrayOf(PropTypes.string),
    team: PropTypes.string,
    score: PropTypes.number,
    serve: PropTypes.bool,
    onClick: PropTypes.func
};

export default ScoreItem;