import { defineMessages } from 'react-intl';

export default defineMessages({
    tournamentPage: {
        id: 'src.components.HomePage.tournamentPage',
        defaultMessage: 'Bavija Championships 2023'
    },
    realtimeScoreboard: {
        id: 'src.components.RealtimeScoreboard.realtimeScoreboard',
        defaultMessage: 'Realtime Score Board'
    },
    appName: {
        id: 'src.components.common.appName',
        defaultMessage: 'VBAN'
    },
    login: {
        id: 'src.components.common.login',
        defaultMessage: 'Login'
    },
    logout: {
        id: 'src.components.common.logout',
        defaultMessage: 'Logout'
    },
    cancel: {
        id: 'src.components.common.cancel',
        defaultMessage: 'Cancel'
    },
    ok: {
        id: 'src.components.common.ok',
        defaultMessage: 'Ok'
    },
    score: {
        id: 'src.components.common.score',
        defaultMessage: 'Score'
    },
    name: {
        id: 'src.components.common.name',
        defaultMessage: 'Name'
    },
    playerNames: {
        id: 'src.components.common.playerNames',
        defaultMessage: 'Player Names'
    },
    table: {
        id: 'src.components.common.table',
        defaultMessage: 'Table'
    },
    add: {
        id: 'src.components.common.add',
        defaultMessage: 'Add'
    },
    single: {
        id: 'src.components.common.single',
        defaultMessage: 'Single'
    },
    double: {
        id: 'src.components.common.double',
        defaultMessage: 'Double'
    },
    doubleMix: {
        id: 'src.components.common.doubleMix',
        defaultMessage: 'Double / Mixes'
    },
    generate: {
        id: 'src.components.common.generate',
        defaultMessage: 'Generate'
    },
    players: {
        id: 'src.components.common.players',
        defaultMessage: 'Players'
    },
    rest: {
        id: 'src.components.common.rest',
        defaultMessage: 'Rest'
    },
    gender: {
        id: 'src.components.common.gender',
        defaultMessage: 'Gender'
    },
    male: {
        id: 'src.components.common.male',
        defaultMessage: 'Male'
    },
    female: {
        id: 'src.components.common.female',
        defaultMessage: 'Female'
    },
    dropdrag: {
        id: 'src.components.common.dropdrag',
        defaultMessage: 'Drop Drag Table'
    },
    randomGen: {
        id: 'src.components.common.randomGen',
        defaultMessage: 'Generate Random Table'
    },
    badminton: {
        id: 'src.components.common.badminton',
        defaultMessage: 'Badminton'
    },
    close: {
        id: 'src.components.common.close',
        defaultMessage: 'Close'
    },
    signin: {
        id: 'src.components.common.signin',
        defaultMessage: 'Sign in'
    },
    signout: {
        id: 'src.components.common.signout',
        defaultMessage: 'Sign out'
    },
    email: {
        id: 'src.components.common.email',
        defaultMessage: 'Email'
    },
    password: {
        id: 'src.components.common.password',
        defaultMessage: 'Password'
    },
    tel: {
        id: 'src.components.common.tel',
        defaultMessage: 'Tel'
    },
    register: {
        id: 'src.components.common.register',
        defaultMessage: 'Register'
    },
    login_here: {
        id: 'src.components.common.login_here',
        defaultMessage: 'Login here'
    },
    new_register: {
        id: 'src.components.common.new_register',
        defaultMessage: 'Register here'
    },
    login_as_guest: {
        id: 'src.components.common.login_as_guest',
        defaultMessage: 'Login as guest'
    },
    quick_view: {
        id: 'src.components.common.quick_view',
        defaultMessage: 'Quick view'
    },
    view_details: {
        id: 'src.components.common.view_details',
        defaultMessage: 'View Details'
    },
    club_name: {
        id: 'src.components.common.club_name',
        defaultMessage: 'Club name'
    },
    contact: {
        id: 'src.components.common.contact',
        defaultMessage: 'Contact'
    },
    actions: {
        id: 'src.components.common.actions',
        defaultMessage: 'Actions'
    },
    club_list: {
        id: 'src.components.common.club_list',
        defaultMessage: 'Clubs List'
    },
    policy: {
        id: 'src.components.common.policy',
        defaultMessage: 'Policy'
    },
    schedule: {
        id: 'src.components.common.schedule',
        defaultMessage: 'Schedule'
    },
    location: {
        id: 'src.components.common.location',
        defaultMessage: 'Location'
    },
    members: {
        id: 'src.components.common.members',
        defaultMessage: 'Members'
    },
    no_of_people: {
        id: 'src.components.common.no_of_people',
        defaultMessage: '{no} members'
    },
    awards: {
        id: 'src.components.common.awards',
        defaultMessage: 'Awards'
    },
    team_not_found: {
        id: 'src.components.common.team_not_found',
        defaultMessage: 'Team not found!'
    },
    lock: {
        id: 'src.components.common.lock',
        defaultMessage: 'Lock'
    },
    unlock: {
        id: 'src.components.common.unlock',
        defaultMessage: 'Unlock'
    },
    profile: {
        id: 'src.components.common.profile',
        defaultMessage: 'Profile'
    },
    guest: {
        id: 'src.components.common.guest',
        defaultMessage: 'Guest'
    },
    last_logged_in: {
        id: 'src.components.common.last_logged_in',
        defaultMessage: 'Last logged in'
    },
    total_members: {
        id: 'src.components.common.total_members',
        defaultMessage: 'Total members'
    },
    total_clubs: {
        id: 'src.components.common.total_clubs',
        defaultMessage: 'Total clubs'
    },
    no_of_clubs: {
        id: 'src.components.common.no_of_clubs',
        defaultMessage: '{no} clubs'
    },
    view_by_gender: {
        id: 'src.components.common.view_by_gender',
        defaultMessage: 'View by gender'
    },
    view_by_age: {
        id: 'src.components.common.view_by_age',
        defaultMessage: 'View by age'
    },
    view_by_level: {
        id: 'src.components.common.view_by_level',
        defaultMessage: 'View by level'
    },
    dashboard: {
        id: 'src.components.common.dashboard',
        defaultMessage: 'Dashboard'
    },
    level_no: {
        id: 'src.components.common.level_no',
        defaultMessage: 'Level {level}'
    },
    events: {
        id: 'src.components.common.events',
        defaultMessage: 'Events'
    },
    event: {
        id: 'src.components.common.event',
        defaultMessage: 'Event'
    },
    home: {
        id: 'src.components.common.home',
        defaultMessage: 'Home'
    },
    details: {
        id: 'src.components.common.details',
        defaultMessage: 'Details'
    },
    no_data: {
        id: 'src.components.common.no_data',
        defaultMessage: 'No data!'
    },
});
