import React, { useEffect, useState } from 'react';
import "./TournamentDrawMixTwo.scss"
import { useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { fetchTournamentPlayers } from "../../redux/actions";
import firebase from '../../Firebase';
import { toast } from 'react-toastify';
import { addNewMatch } from "../../redux/actions";

function TournamentDrawMixTwo() {
    const dispatch = useDispatch();
    const [players, setPlayers] = useState([]);
    const [tournament, setTournament] = useState([]);
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [admins, setAdmins] = useState([]);
    const user = firebase.auth().currentUser;

    function renderNationResult() {
        const level = tournament.level[7];

        const switchPage = () => {
            const partOne = document.querySelector('.part-one');
            const partTwo = document.querySelector('.part-two');
            partOne.classList.toggle('hidden');
            partTwo.classList.toggle('hidden');
        }

        return <div class="mix-two-result-wrapper">
                <div class="header-wrapper">
                    <img src="images/header.png" />
                </div>
                <div class="body-wrapper">
                    <div class="draw-title">
                        { level.name }
                    </div>

                    <div class="result-wrapper part-one">
                        { level.group.map((group, _) => renderNationGroupDetail(group)) }
                    </div>

                    <div class="result-wrapper part-two hidden">
                        { level.group.map((group, _) => renderNationGroupDetailTwo(group)) }
                    </div>
                    <div class="switch-button" onClick={switchPage}>
                        <img src="images/next.png" />
                    </div>
                </div>
                <div class="footer-wrapper">
                    <img src="images/footer.png" />
                </div>
            </div>
    }

    function renderNationGroupDetail(group) {
        const groupName = 'Group ' + group.name;

        if (group.name > 'C') {
            return '';
        }

        return <div class="group-wrapper">
                <div class="group-name">
                    { groupName }
                </div>
                <div class="pair-wrapper">
                    { group.pair_ids.split(',').map((pair_id, index) => renderNationPairDetail(pair_id, index)) }
                </div>
            </div>
    }

    function renderNationGroupDetailTwo(group) {
        const groupName = 'Group ' + group.name;

        if (group.name < 'D') {
            return '';
        }

        return <div class="group-wrapper">
                <div class="group-name">
                    { groupName }
                </div>
                <div class="pair-wrapper">
                    { group.pair_ids.split(',').map((pair_id, index) => renderNationPairDetail(pair_id, index)) }
                </div>
            </div>
    }

    function renderNationPairDetail(pair_id, index) {
        const level = tournament.level[7];

        if (pair_id == '') {
            return '';
        }

        const pair = level.draw[pair_id];

        const teamFlag = getFlagFromPairId(pair.player_id_1, pair.player_id_2);

        let player_name_1 = players[pair.player_id_1].name;

        if (user && admins.includes(user.email)) {
            player_name_1 += ' (' + pair_id + ')';
        }


        return <div class="pair-detail">
                <div class="team-name">
                    <img src={'images/' + teamFlag + '.png'} />
                </div>
                <div class="player-wrapper">
                    <div class="player-name">
                        { player_name_1 }
                    </div>
                    <div class="player-name">
                        { players[pair.player_id_2].name }
                    </div>
                </div>
            </div>
    }

    function getFlagFromPairId(id_1, id_2) {
        if(!teams.length) {return 'unknow'}

        let team_id = -1;

        if(players[id_1].team_id == players[id_2].team_id) {
            team_id = players[id_1].team_id;
        }

        switch(team_id) {
            case '0':
            case 0:
                return 'tudo';
            case '1':
            case 1:
                return 'vietnambado';
            case '2':
            case 2:
                return 'edogawa';
            case '3':
            case 3:
                return 'yokohama';
            case '4':
            case 4:
                return 'shinmatsudo';
            case '5':
            case 5:
                return 'ebina';
            case '6':
            case 6:
                return 'yamanashi';
            case '7':
            case 7:
                return 'kokubunji';
            case '8':
            case 8:
                return 'kyoto';
            case '9':
            case 9:
                return 'shizuoka';
            case '10':
            case 10:
                return 'techminton';
            case '11':
            case 11:
                return 'kanuma';
            case '12':
            case 12:   
                return 'nishioaichi';
            case '13':
            case 13:
                return 'ishioka';
            case '14':
            case 14:
                return 'omiya';
            case '15':
            case 15:
                return 'gifu';
            case '16':
            case 16:
                return 'fukayaebina';
            case '17':
            case 17:
                return 'kawaguchi';
            case '18':
            case 18:   
                return 'tochigi';
            case '19':
            case 19:
                return 'chibad';
            case '20':
            case 20:
                return 'nagoya';
            case '21':
            case 21:
                return 'misato';
            case '22':
            case 22:
                return 'okazakiaichi';
            case '23':
            case 23:
                return 'nagano';
            default:
                return 'lienquan';
        }
    }

    useEffect(() => {
        setLoading(true);
        dispatch(fetchTournamentPlayers(1))
            .then(res => {
                setLoading(false);
                const results = res && res.payload && res.payload.response
                    && res.payload.response.result || {};

                setPlayers(results[0]);
                setTournament(results[1]);
                setTeams(results[2]);
                setAdmins(results[3]);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <Spinner animation="border" variant="primary" />;
    }

    const reloadPair = () => {
        dispatch(fetchTournamentPlayers(1))
            .then(res => {
                setLoading(false);
                const results = res && res.payload && res.payload.response
                    && res.payload.response.result || {};

                setTournament(results[1]);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }

    function handleCreateMatch() {
        let pair_id_1 = document.getElementById('pair-id-1').value
        let pair_id_2 = document.getElementById('pair-id-2').value

        if (pair_id_1 == '' || pair_id_2 == '') {
            toast.error('Please input pair id!');
            return;
        }

        pair_id_1 = Number(pair_id_1);
        pair_id_2 = Number(pair_id_2);

        const matchInfo = {
            games: {
                0: {
                    pair_1: {
                        id: pair_id_1,
                        score: 0,
                        win: 0
                    },
                    pair_2: {
                        id: pair_id_2,
                        score: 0,
                        win: 0
                    },
                },
                1: {
                    pair_1: {
                        id: pair_id_1,
                        score: 0,
                        win: 0
                    },
                    pair_2: {
                        id: pair_id_2,
                        score: 0,
                        win: 0
                    },
                },
                2: {
                    pair_1: {
                        id: pair_id_1,
                        score: 0,
                        win: 0
                    },
                    pair_2: {
                        id: pair_id_2,
                        score: 0,
                        win: 0
                    },
                }
            }
        };

        const levelId = 7;
        const tournamentId = 1;
        dispatch(addNewMatch({tournamentId, levelId, matchInfo}))
            .then(res => {
                toast.success("Match created successfully!");

            })
            .catch(error => {
                toast.error('Match creation failed!');
                console.log(error);
            });


    }

    if (tournament && tournament.level ) {
        return <div class='result-draw'>
            {
                user && admins.includes(user.email) &&
                <div class="create-match">
                    <input type="text" id="pair-id-1" placeholder="ID cặp 1" />
                    <input type="text" id="pair-id-2" placeholder="ID cặp 2" />
                    <button class="btn btn-primary" onClick={handleCreateMatch}>Create Match</button>
                </div>
            }
            { renderNationResult() }
            <div class="reload-draw">
                <button class="btn btn-primary" onClick={reloadPair}>Reload Pair</button>
            </div>
        </div>
    } else {
        return <div>
            Nothing here !
        </div>;
    }
}

export default TournamentDrawMixTwo;
