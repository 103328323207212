import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, InputGroup, Dropdown, Accordion, Card, ButtonGroup, Alert } from "react-bootstrap";
import styles from "./RandomTable.module.scss"
import { useIntl } from 'react-intl';
import messages from './messages';
import commonMessages from '../../common/messages';
import ScoreItem from '../ScoreItem';
import Table from 'react-bootstrap/Table';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlusSquare, faEdit, faPlay } from '@fortawesome/free-solid-svg-icons'
import { Permutation } from 'js-combinatorics';
import { GENDER, PROPERTIES, PlayerModel } from '../../models/Player';
import PlayerBox from '../PlayerBox';
import RGL, { Responsive, WidthProvider } from "react-grid-layout";
import GridLayout from 'react-grid-layout';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
const ReactGridLayout = WidthProvider(RGL);
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const SIDES = {
    LEFT: "LEFT",
    RIGHT: "RIGHT"
}
const MODES = {
    SINGLE: "SINGLE",
    DOUBLE: "DOUBLE"
}

const EDIT_MODES = {
    ADD: "ADD",
    EDIT: "EDIT"
}
const MAX_PLAYERS = 100;
const DEFAULT_PLAYERS = 16;
const WIDTH = 500;

function Scoreboard() {
    const intl = useIntl();
    const [noOfPlayers, setNoOfPlayers] = useState(DEFAULT_PLAYERS);
    const [players, setPlayers] = useState(
        Array(DEFAULT_PLAYERS).fill().map((a, i) => new PlayerModel(String(i + 1))))
    const [mode, setMode] = useState(MODES.DOUBLE);
    const [permu, setPermu] = useState([]);
    const [rest, setRest] = useState([]);
    const [newStart, setNewStart] = useState(DEFAULT_PLAYERS);
    const [showNameEdit, setShowNameEdit] = useState(false);
    const [editPlayer, setEditPlayer] = useState(new PlayerModel("Name"));
    const [editMode, setEditMode] = useState(EDIT_MODES.ADD);
    const [editIndex, setEditIndex] = useState(-1);
    const [width, setWidth] = useState(WIDTH);
    useEffect(() => {
        const w = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        setWidth(w > 500 ? w / 2 : w);
    }, [])

    function onDropDownSelect(i) {
        setNoOfPlayers(i + 1);
        resetGen();
        if (i + 1 > noOfPlayers) {
            setPlayers(players.concat(
                Array(i + 1 - noOfPlayers).fill()
                    .map((a, index) => new PlayerModel(String(newStart + index + 1)))
            ));
            setNewStart(newStart + i + 1 - noOfPlayers);
        } else if (i + 1 < noOfPlayers) {
            setPlayers(players.slice(0, i + 1));
        }
    }
    function onSelectMode(mode) {
        setMode(mode);
    }
    function onGenerate() {
        const it = new Permutation(players);
        const sample = it.sample();
        const length = sample.length;
        const pair = mode === MODES.DOUBLE ? 4 : 2;
        const matches = Math.floor(length / pair);
        const restNo = length - matches * pair;
        const res = [];
        for (let i = 0; i < matches; i++) {
            const left = Array(pair / 2).fill(0).map((v, index) => sample[i * pair + index]);
            const right = Array(pair / 2).fill(0).map((v, index) => sample[i * pair + pair / 2 + index]);
            res.push({
                [SIDES.LEFT]: left,
                [SIDES.RIGHT]: right,
            });
        }
        setPermu(res);
        if (restNo > 0) {
            setRest(sample.slice(matches * pair));
        } else {
            setRest([]);
        }

    }

    function resetGen() {
        setPermu([]);
        setRest([]);
    }

    function onAdd() {
        setEditMode(EDIT_MODES.ADD);
        setShowNameEdit(true);
    }
    function onDelete(index) {
        const newPlayers = players.slice();
        newPlayers.splice(index, 1);
        setPlayers(newPlayers);
        setNoOfPlayers(newPlayers.length);
        resetGen();
    }
    function onEdit(index) {
        setEditMode(EDIT_MODES.EDIT);
        setEditIndex(index);
        const newPlayer = PlayerModel.clone(players[index]);
        setEditPlayer(newPlayer);
        setShowNameEdit(true);
    }
    function onCloseNameEdit() {
        setShowNameEdit(false);
    }
    function onConfirmEdit(mode) {
        if (mode === EDIT_MODES.ADD) {
            if (editPlayer.name) {
                const newPlayers = players.slice();
                newPlayers.push(PlayerModel.clone(editPlayer));
                setPlayers(newPlayers);
            }
        } else {
            const newPlayers = players.slice();
            newPlayers[editIndex] = PlayerModel.clone(editPlayer);
            setPlayers(newPlayers);
        }
        setShowNameEdit(false);
    }
    function isNameValid(editPlayer, players = [], mode) {
        if (!editPlayer.name) {
            return false;
        }
        if (mode === EDIT_MODES.EDIT) {
            return true;
        }
        return players.findIndex(p => String(p.name) === String(editPlayer.name)) < 0;
    }
    function onEditChange(property, val) {
        const newPlayer = PlayerModel.clone(editPlayer);
        newPlayer[property] = val;
        setEditPlayer(newPlayer);
    }
    function generateDOM() {
        return players.map((p, i) => <div key={i}>
            {/* <PlayerBox player={new PlayerModel(String(i))} /> */}
            <PlayerBox player={p} />
            {/* <span className="text">{i}</span> */}
        </div>);
    }

    function generateLayout() {
        return players.map((a, i) => {
            return {
                x: i - 4 * Math.floor(i / 4),
                y: Math.floor(i / 4),
                w: 1,
                h: 1,
                i: i.toString(),
                minW: 1, maxW: 1,
                isBounded: true
            }
        });
    }
    return <div className={styles.RandomTable}>
        <div className={cn("mb-2", styles.Control)}>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {intl.formatMessage(messages.noOfPlayers, { no: noOfPlayers })}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {Array(MAX_PLAYERS).fill()
                        .map((p, i) => <Dropdown.Item onSelect={() => onDropDownSelect(i)}
                            key={i}>{i + 1}</Dropdown.Item>)}
                </Dropdown.Menu>
            </Dropdown>
        </div>
        <Accordion defaultActiveKey="1" className={styles.Accordion}>
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        {intl.formatMessage(commonMessages.playerNames)}
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <ButtonGroup className={styles.ButtonGroup}>
                            <Button variant="primary"
                                onClick={() => onAdd()}>
                                {intl.formatMessage(commonMessages.add)}
                                {" "}
                                <FontAwesomeIcon icon={faPlusSquare} />
                            </Button>
                        </ButtonGroup>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{intl.formatMessage(commonMessages.name)}</th>
                                    <th>{intl.formatMessage(commonMessages.actions)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {players.map((p, index) => <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td><PlayerBox player={p} /></td>
                                    <td>
                                        <Button variant="primary" className={styles.Button}
                                            onClick={() => onEdit(index)}
                                        ><FontAwesomeIcon icon={faEdit} /></Button>
                                        <Button variant="danger" className={styles.Button}
                                            onClick={() => onDelete(index)}
                                        ><FontAwesomeIcon icon={faTrash} /></Button>
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table></Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        {intl.formatMessage(commonMessages.randomGen)}
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>
                        <Form.Group>
                            <Form.Check
                                onChange={(e) => onSelectMode(MODES.SINGLE)}
                                checked={mode === MODES.SINGLE}
                                type="radio"
                                label={intl.formatMessage(commonMessages.single)}
                                name="formHorizontalRadios"
                                id="formHorizontalRadios1"
                            />
                            <Form.Check
                                onChange={(e) => onSelectMode(MODES.DOUBLE)}
                                checked={mode === MODES.DOUBLE}
                                type="radio"
                                label={intl.formatMessage(commonMessages.doubleMix)}
                                name="formHorizontalRadios"
                                id="formHorizontalRadios2"
                            />
                        </Form.Group>
                        <ButtonGroup className={styles.ButtonGroup}>
                            <Button variant="primary" onClick={onGenerate}>
                                {intl.formatMessage(commonMessages.generate)}
                                {" "}
                                <FontAwesomeIcon icon={faPlay} />
                            </Button>
                        </ButtonGroup>

                        {rest.length > 0 && <Alert variant="success">
                            <Alert.Heading>{intl.formatMessage(commonMessages.rest)}</Alert.Heading>
                            <p className="mb-0">
                                {rest.map((r, i) => <div key={i}>{r.name}</div>)}
                            </p>
                        </Alert>}
                        {permu.length > 0 && <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{intl.formatMessage(commonMessages.name)}</th>
                                    <th>{intl.formatMessage(commonMessages.name)}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {permu.map((p, index) => <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{p[SIDES.LEFT].map((g, i) => <div key={i}><PlayerBox player={g} /></div>)}</td>
                                    <td>{p[SIDES.RIGHT].map((g, i) => <div key={i}><PlayerBox player={g} /></div>)}</td>
                                </tr>)}
                            </tbody>
                        </Table>}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                        {intl.formatMessage(commonMessages.dropdrag)}
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body className={styles.dropBody}>
                        <GridLayout
                            layout={generateLayout()}
                            onLayoutChange={() => { }}
                            width={width}
                            isResizable={false}
                            rowHeight={64}
                            autoSize={true}
                            cols={4}
                            className="layout"
                            verticalCompact={false}
                        >
                            {generateDOM()}
                        </GridLayout>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>

        <Modal
            show={showNameEdit}
            onHide={onCloseNameEdit}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title">
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {intl.formatMessage(commonMessages.name)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>@</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        className="textFeedback"
                        as="input"
                        placeholder={intl.formatMessage(commonMessages.name)}
                        value={editPlayer.name}
                        onChange={e => onEditChange(PROPERTIES.NAME, e.target.value)}
                        type="text"
                        isInvalid={!isNameValid(editPlayer, players, editMode)}
                    />
                </InputGroup>
                <Form.Group>
                    <Form.Check
                        onChange={(e) => onEditChange(PROPERTIES.GENDER, GENDER.MALE)}
                        checked={editPlayer.gender === GENDER.MALE}
                        type="radio"
                        label={intl.formatMessage(commonMessages.male)}
                        name="maleHorizontalRadios"
                        id="maleHorizontalRadios1"
                    />
                    <Form.Check
                        onChange={(e) => onEditChange(PROPERTIES.GENDER, GENDER.FEMALE)}
                        checked={editPlayer.gender === GENDER.FEMALE}
                        type="radio"
                        label={intl.formatMessage(commonMessages.female)}
                        name="femaleHorizontalRadios"
                        id="femaleHorizontalRadios2"
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCloseNameEdit}>
                    {intl.formatMessage(commonMessages.cancel)}
                </Button>
                <Button variant="primary" onClick={() => onConfirmEdit(editMode)}
                    disabled={!isNameValid(editPlayer, players, editMode)}>
                    {intl.formatMessage(commonMessages.ok)}
                </Button>
            </Modal.Footer>
        </Modal>
    </div>;

}

export default Scoreboard;