import React, { useEffect, useState } from 'react';

import styles from "./RealtimeScoreboard.module.scss"
import './RealtimeScoreboard.scss';

import { useIntl } from 'react-intl';
import commonMessages from "../../common/messages";

import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';
import Breadcrumb from 'react-bootstrap/Breadcrumb'

import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'

import { database } from '../../Firebase'

function RealtimeScoreboard(props) {
    const intl = useIntl();
    const [rightscore, setRightscore] = useState(0);
    const [leftscore, setLeftscore] = useState(0);
    const rightscoreRef = database.ref('Tournament/1/level/1/matches/0/games/0/pair_1/score');
    const leftscoreRef = database.ref('Tournament/1/level/1/matches/0/games/0/pair_2/score');

    const increaseRightScore = () => {
        rightscoreRef.transaction((currentScore) => (currentScore || 0) + 1);
    };

    const decreaseRightScore = () => {
        if (rightscore > 0) {
            rightscoreRef.transaction((currentScore) => (currentScore || 0) - 1);
        }
    };

    const increaseLeftScore = () => {
        leftscoreRef.transaction((currentScore) => (currentScore || 0) + 1);
    };

    const decreaseLeftScore = () => {
        if (leftscore > 0) {
            leftscoreRef.transaction((currentScore) => (currentScore || 0) - 1);
        }
    };

    useEffect(() => {
        leftscoreRef.on('value', (snapshot) => {
            const updatedScore = snapshot.val();
            setLeftscore(updatedScore);
        });

        rightscoreRef.on('value', (snapshot) => {
            const updatedScore = snapshot.val();
            setRightscore(updatedScore);
        });
    
        return () => {
            leftscoreRef.off('value');
            rightscoreRef.off('value');
        };
      }, []);

    return (
        <div className={styles.RealtimeScoreboard}>
            <Breadcrumb className={styles.Breadcrumb}>
                <Breadcrumb.Item href="/"><FontAwesomeIcon icon={faHome} />{" "}{intl.formatMessage(commonMessages.home)}</Breadcrumb.Item>
                <Breadcrumb.Item active>{intl.formatMessage(commonMessages.realtimeScoreboard)}</Breadcrumb.Item>
            </Breadcrumb>
            <Jumbotron fluid>
                <Container>
                    <div class='duel-participant'>
                        <div class='home-area'>
                            <div class='participant-doubles-wrapper'>
                                <div class='participant-doubles participant-doubles-1'>
                                    <a class='participant-link' href='https://www.facebook.com/bui.d.binh.9' title='Show profile'>
                                        <img class='participant-image' alt='Bui Duc Binh' src='images/player/player_1.jpg'></img>
                                    </a>
                                    <div class='participant-name'>
                                        <a href='https://www.facebook.com/bui.d.binh.9'>Bui Duc Binh</a>
                                    </div>
                                    <div class='score-control'>
                                        <button class='minus-button' onClick={decreaseLeftScore}>-</button>
                                    </div>
                                </div>
                                <div class='participant-doubles participant-doubles-2'>
                                    <a class='participant-link' href='https://www.facebook.com/tudamminh.itchiba' title='Show profile'>
                                        <img class='participant-image' alt='Dam Minh Tu' src='images/player/player_2.jpg'></img>
                                    </a>
                                    <div class='participant-name'>
                                        <a href='https://www.facebook.com/tudamminh.itchiba'>Dam Minh Tu</a>
                                    </div>
                                    <div class='score-control'>
                                        <button class='plus-button' onClick={increaseLeftScore}>+</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class='score'>
                            <div class='start-time'>
                                <div>04.08.2023 14:05</div>
                            </div>
                            <div class='match-info'>
                                <div class='detail-score-wrapper'>
                                    <div class='match-info-item'>
                                        <span class='home-score'>{leftscore}</span>
                                        <span class="detail-score-divider">-</span>
                                        <span class='away-score'>{rightscore}</span>
                                    </div>
                                </div>
                                <div class='detail-score-status'>
                                    <span class="detail-status">1ST GAME</span>
                                </div>
                            </div>
                        </div>
                        <div class='away-area'>
                            <div class='participant-doubles-wrapper'>
                                <div class='participant-doubles participant-doubles-1'>
                                    <a class='participant-link' href='https://www.facebook.com/moi.met.735' title='Show profile'>
                                        <img class='participant-image' alt='Nguyen Viet Ha' src='images/player/player_3.jpg'></img>
                                    </a>
                                    <div class='participant-name'>
                                        <a href='https://www.facebook.com/moi.met.735'>Nguyen Viet Ha</a>
                                    </div>
                                    <div class='score-control'>
                                        <button class='minus-button' onClick={decreaseRightScore}>-</button>
                                    </div>
                                </div>
                                <div class='participant-doubles participant-doubles-2'>
                                    <a class='participant-link' href='https://www.facebook.com/trunghieu.nguyen.3572846' title='Show profile'>
                                        <img class='participant-image' alt='Nguyen Trung Hieu' src='images/player/player_4.jpg'></img>
                                    </a>
                                    <div class='participant-name'>
                                        <a href='https://www.facebook.com/trunghieu.nguyen.3572846'>Trung Hieu</a>
                                    </div>
                                    <div class='score-control'>
                                        <button class='plus-button' onClick={increaseRightScore}>+</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </Jumbotron>
        </div>
    );

}

export default RealtimeScoreboard;
