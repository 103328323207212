const auth = (state = [], action) => {
    switch (action.type) {
        case 'AUTH_USER/pending':
            return {
                ...state,
                user: {
                    id: action.id,
                    data: action.payload,
                    pending: true,
                    completed: false,
                    error: false
                }
            }
        case 'AUTH_USER/fulfilled':
            return {
                ...state,
                user: {
                    id: action.id,
                    data: action.payload,
                    pending: true,
                    completed: false,
                    error: false
                }
            }
        case 'AUTH_USER':
            return {
                ...state,
                user: {
                    id: action.id,
                    data: action.payload && action.payload.data,
                    pending: false,
                    completed: true,
                    error: false
                }
            }
        default:
            return state
    }
}

export default auth