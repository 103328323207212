import React from 'react';
import Calendar from "../GoogleCalendar";
import { css } from "@emotion/react";
const API_KEY = "AIzaSyAKRccvN3VytQelIMrtiLDspFza_0Ko8A0";

let calendars = [
    { calendarId: "vban.contact@gmail.com" },
];
let calStyles = {
    //you can use object styles (no import required)
    calendar: {
        borderWidth: "3px", //make outer edge of calendar thicker
    },

    //you can also use emotion's string styles
    today: css`
    /* highlight today by making the text red and giving it a red border */
     color: red;
     border: 1px solid red;
   `,
    tooltip: css`
     opacity: 100;
   `
}
function MyCalendar() {
    return <div>
        <Calendar apiKey={API_KEY} calendars={calendars} styles={calStyles} language="VN" />
    </div>;

}
export default MyCalendar;