import React, { useEffect, useState } from 'react';

import "./MatchDetailPage.scss"

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux'
import Spinner from 'react-bootstrap/Spinner';

import { fetchTournamentPlayers } from "../../redux/actions";
import firebase from '../../Firebase';
import { toast } from 'react-toastify';

import { database } from '../../Firebase'
import moment from "moment";


function MatchDetailPage(props) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [players, setPlayers] = useState([]);
    const [teams, setTeams] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tournament, setTournament] = useState([]);
    const [admins, setAdmins] = useState([]);

    const [gameOnePairOneScore, setGameOnePairOneScore] = useState(0);
    const [gameOnePairTwoScore, setGameOnePairTwoScore] = useState(0);
    const [gameTwoPairOneScore, setGameTwoPairOneScore] = useState(0);
    const [gameTwoPairTwoScore, setGameTwoPairTwoScore] = useState(0);
    const [gameThreePairOneScore, setGameThreePairOneScore] = useState(0);
    const [gameThreePairTwoScore, setGameThreePairTwoScore] = useState(0);

    const [gameOnePairOneItScore, setGameOnePairOneItScore] = useState(0);
    const [gameOnePairTwoItScore, setGameOnePairTwoItScore] = useState(0);

    const user = firebase.auth().currentUser;
    const tournamentId = props && props.match && props.match.params && parseInt(props.match.params.tournamentId);
    const levelId = props && props.match && props.match.params && parseInt(props.match.params.level_id);
    const matchId = props && props.match && props.match.params && parseInt(props.match.params.match_id);

    const gameOnePairOneScoreRef = database.ref(`Tournament/1/level/${levelId}/matches/${matchId}/games/0/pair_1/score`);
    const gameOnePairTwoScoreRef = database.ref(`Tournament/1/level/${levelId}/matches/${matchId}/games/0/pair_2/score`);
    const gameTwoPairOneScoreRef = database.ref(`Tournament/1/level/${levelId}/matches/${matchId}/games/1/pair_1/score`);
    const gameTwoPairTwoScoreRef = database.ref(`Tournament/1/level/${levelId}/matches/${matchId}/games/1/pair_2/score`);
    const gameThreePairOneScoreRef = database.ref(`Tournament/1/level/${levelId}/matches/${matchId}/games/2/pair_1/score`);
    const gameThreePairTwoScoreRef = database.ref(`Tournament/1/level/${levelId}/matches/${matchId}/games/2/pair_2/score`);

    const gameOnePairOneItScoreRef = database.ref(`Tournament/2/level/${levelId}/matches/${matchId}/pair_1/score`);
    const gameOnePairTwoItScoreRef = database.ref(`Tournament/2/level/${levelId}/matches/${matchId}/pair_2/score`);

    useEffect(() => {
        setLoading(true);
        dispatch(fetchTournamentPlayers(tournamentId))
            .then(res => {
                setLoading(false);
                const results = res && res.payload && res.payload.response
                    && res.payload.response.result || {};

                setPlayers(results[0]);
                setTournament(results[1]);
                setTeams(results[2]);
                setAdmins(results[3]);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
        
        gameOnePairOneItScoreRef.on('value', (snapshot) => {
            const updatedScore = snapshot.val();
            changeShuttleToUpper();
            setGameOnePairOneItScore(updatedScore);
        });

        gameOnePairTwoItScoreRef.on('value', (snapshot) => {
            const updatedScore = snapshot.val();
            changeShuttleToLower();
            setGameOnePairTwoItScore(updatedScore);
        });

        gameOnePairOneScoreRef.on('value', (snapshot) => {
            const updatedScore = snapshot.val();
            changeShuttleToUpper();
            setGameOnePairOneScore(updatedScore);
        });

        gameOnePairTwoScoreRef.on('value', (snapshot) => {
            const updatedScore = snapshot.val();
            changeShuttleToLower();
            setGameOnePairTwoScore(updatedScore);
        });

        gameTwoPairOneScoreRef.on('value', (snapshot) => {
            const updatedScore = snapshot.val();
            changeShuttleToUpper();
            setGameTwoPairOneScore(updatedScore);
        });

        gameTwoPairTwoScoreRef.on('value', (snapshot) => {
            const updatedScore = snapshot.val();
            changeShuttleToLower();
            setGameTwoPairTwoScore(updatedScore);
        });

        gameThreePairOneScoreRef.on('value', (snapshot) => {
            const updatedScore = snapshot.val();
            changeShuttleToUpper();
            setGameThreePairOneScore(updatedScore);
        });

        gameThreePairTwoScoreRef.on('value', (snapshot) => {
            const updatedScore = snapshot.val();
            changeShuttleToLower();
            setGameThreePairTwoScore(updatedScore);
        });
    
        return () => {
            gameOnePairOneScoreRef.off('value');
            gameOnePairTwoScoreRef.off('value');
            gameTwoPairOneScoreRef.off('value');
            gameTwoPairTwoScoreRef.off('value');
            gameThreePairOneScoreRef.off('value');
            gameThreePairTwoScoreRef.off('value');
        };
    }, []);

    if (loading) {
        return <Spinner animation="border" variant="primary" />;
    }

    function getNameFromPairId(pair_id) {
        if (tournamentId == 1 && levelId == 1) {
            switch(pair_id) {
                case 1:
                    return 'Tú / Dũng';
                case 2:
                    return 'Bình / Hoàng';
                case 3:
                    return 'Miya / Seyama';
                case 4:
                    return 'V.Anh / Curtis';
                case 5:
                    return 'Liang / Sikai';
                case 6:
                    return 'Yang / Wei';
                case 7:
                    return 'Phương / Nga';
                default:
                    return 'Allu / Hùng';
            }
        } else {
            const player_1_name = getNamePlayerOneFromPairId(pair_id);
            const player_2_name = getNamePlayerTwoFromPairId(pair_id);
            return player_1_name.split(' ').filter(n => n).slice(-1) + ' / ' + player_2_name.split(' ').filter(n => n).slice(-1);
        }
    }

    function changeShuttleToUpper() {
        [...document.querySelectorAll('.upper-player-wrapper')].map((item) => {
            item.querySelector('.shuttle-cock').classList.remove('hidden');
        });

        [...document.querySelectorAll('.lower-player-wrapper')].map((item) => {
            item.querySelector('.shuttle-cock').classList.add('hidden');
        });
    }

    function changeShuttleToLower() {
        [...document.querySelectorAll('.upper-player-wrapper')].map((item) => {
            item.querySelector('.shuttle-cock').classList.add('hidden');
        });

        [...document.querySelectorAll('.lower-player-wrapper')].map((item) => {
            item.querySelector('.shuttle-cock').classList.remove('hidden');
        });
    }

    function gameOne (games) {
        const nation_one = getFlagFromPairId(games[0].pair_1.id);
        const nation_two = getFlagFromPairId(games[0].pair_2.id);

        return <div class="score-board">
            <div class={levelId == 1 ? 'upper-player-wrapper' : 'upper-player-wrapper team-wrapper'}>
                <div class={levelId == 1 ? 'country-flag' : 'team-flag'}>
                    <img src={'https://vban.org/images/' + nation_one + '.png'} />
                </div>
                
                <div class="player-name">
                    { getNameFromPairId(games[0].pair_1.id) }
                </div>
                
                <div class="shuttle-cock hidden">
                    <img src="https://i.imgur.com/QqLPhJ1.png" alt="shuttle flag" width="15" height="15"></img>
                </div>
            </div>
            
            <div class="upper-score-current-game center">
                { gameOnePairOneScore }
            </div>
            
            <div class={levelId == 1 ? 'lower-player-wrapper' : 'lower-player-wrapper team-wrapper'}>
                <div class={levelId == 1 ? 'country-flag' : 'team-flag'}>
                <img src={'https://vban.org/images/' + nation_two + '.png'} />
                </div>
                
                <div class="player-name">
                    { getNameFromPairId(games[0].pair_2.id) }
                </div>
                
                <div class="shuttle-cock">
                    <img src="https://i.imgur.com/QqLPhJ1.png" alt="vietnam flag" width="15" height="15"></img>
                </div>
            </div>
            
            <div class="lower-score-current-game center">
                { gameOnePairTwoScore }
            </div>
        </div>
    }

    function gameItOne (game) {
        const nation_one = getFlagFromPairId(game.pair_1.id);
        const nation_two = getFlagFromPairId(game.pair_2.id);

        return <div class="score-board">
            <div class='upper-player-wrapper'>
                <div class='country-flag'>
                    <img src={'https://vban.org/images/' + nation_one + '.png'} />
                </div>
                
                <div class="player-name">
                    { getNameFromPairId(game.pair_1.id) }
                </div>
                
                <div class="shuttle-cock hidden">
                    <img src="https://i.imgur.com/QqLPhJ1.png" alt="shuttle flag" width="15" height="15"></img>
                </div>
            </div>
            
            <div class="upper-score-current-game center">
                { gameOnePairOneItScore }
            </div>
            
            <div class='lower-player-wrapper'>
                <div class='country-flag'>
                <img src={'https://vban.org/images/' + nation_two + '.png'} />
                </div>
                
                <div class="player-name">
                    { getNameFromPairId(game.pair_2.id) }
                </div>
                
                <div class="shuttle-cock">
                    <img src="https://i.imgur.com/QqLPhJ1.png" alt="vietnam flag" width="15" height="15"></img>
                </div>
            </div>
            
            <div class="lower-score-current-game center">
                { gameOnePairTwoItScore }
            </div>
        </div>
    }

    function gameTwo (games) {
        if (levelId == 5) { return; }

        const nation_one = getFlagFromPairId(games[0].pair_1.id);
        const nation_two = getFlagFromPairId(games[0].pair_2.id);

        return <div class="score-board">
            <div class={levelId == 1 ? 'upper-player-wrapper' : 'upper-player-wrapper team-wrapper'}>
                <div class={levelId == 1 ? 'country-flag' : 'team-flag'}>
                    <img src={'https://vban.org/images/' + nation_one + '.png'} />
                </div>
                
                <div class="player-name">
                    { getNameFromPairId(games[0].pair_1.id) }
                </div>
                
                <div class="shuttle-cock hidden">
                    <img src="https://i.imgur.com/QqLPhJ1.png" alt="shuttle flag" width="15" height="15"></img>
                </div>
            </div>
            
            <div class="upper-score-previous-game center">
                { gameOnePairOneScore }
            </div>
            
            <div class="upper-score-current-game center">
                { gameTwoPairOneScore }
            </div>
            
            <div class={levelId == 1 ? 'lower-player-wrapper' : 'lower-player-wrapper team-wrapper'}>
                <div class={levelId == 1 ? 'country-flag' : 'team-flag'}>
                <img src={'https://vban.org/images/' + nation_two + '.png'} />
                </div>
                
                <div class="player-name">
                    { getNameFromPairId(games[0].pair_2.id) }
                </div>
                
                <div class="shuttle-cock">
                    <img src="https://i.imgur.com/QqLPhJ1.png" alt="vietnam flag" width="15" height="15"></img>
                </div>
            </div>
            
            <div class="lower-score-previous-game center">
                { gameOnePairTwoScore }
            </div>
            
            <div class="lower-score-current-game center">
                { gameTwoPairTwoScore }
            </div>
        </div>
    }

    function gameThree (games) {
        if (levelId == 5) { return; }

        const nation_one = getFlagFromPairId(games[0].pair_1.id);
        const nation_two = getFlagFromPairId(games[0].pair_2.id);

        return <div class="score-board">
            <div class={levelId == 1 ? 'upper-player-wrapper' : 'upper-player-wrapper team-wrapper'}>
                <div class={levelId == 1 ? 'country-flag' : 'team-flag'}>
                    <img src={'https://vban.org/images/' + nation_one + '.png'} />
                </div>
                
                <div class="player-name">
                    { getNameFromPairId(games[0].pair_1.id) }
                </div>
                
                <div class="shuttle-cock hidden">
                    <img src="https://i.imgur.com/QqLPhJ1.png" alt="shuttle flag" width="15" height="15"></img>
                </div>
            </div>
            
            <div class="upper-score-previous-game center">
                { gameOnePairOneScore }
            </div>
            
            <div class="upper-score-previous-game center">
                { gameTwoPairOneScore }
            </div>

            <div class="upper-score-current-game center">
                { gameThreePairOneScore }
            </div>
            
            <div class={levelId == 1 ? 'lower-player-wrapper' : 'lower-player-wrapper team-wrapper'}>
                <div class={levelId == 1 ? 'country-flag' : 'team-flag'}>
                <img src={'https://vban.org/images/' + nation_two + '.png'} />
                </div>
                
                <div class="player-name">
                    { getNameFromPairId(games[0].pair_2.id) }
                </div>
                
                <div class="shuttle-cock">
                    <img src="https://i.imgur.com/QqLPhJ1.png" alt="shuttle flag" width="15" height="15"></img>
                </div>
            </div>
            
            <div class="lower-score-previous-game center">
                { gameOnePairTwoScore }
            </div>
            
            <div class="lower-score-previous-game center">
                { gameTwoPairTwoScore }
            </div>

            <div class="lower-score-current-game center">
                { gameThreePairTwoScore }
            </div>
        </div>
    }

    function getNamePlayerOneFromPairId(pair_id) {
        const player_id = tournament.level[levelId].draw[pair_id].player_id_1;
        return players[player_id].name;
    }

    function getNamePlayerTwoFromPairId(pair_id) {
        const player_id = tournament.level[levelId].draw[pair_id].player_id_2;
        return players[player_id].name;
    }

    function scoreboardControllerOne(games) {
        return <div class='duel-participant'>
            <div class='home-area'>
                <div class='participant-doubles-wrapper'>
                    <div class='participant-doubles participant-doubles-1'>
                        <a class='participant-link' title='Show profile'>
                            <img class='participant-image' src='https://vban.org/images/player/player.png'></img>
                        </a>
                        <div class='participant-name'>
                            <a>{ getNamePlayerOneFromPairId(games[0].pair_1.id) }</a>
                        </div>
                        {
                            user && admins.includes(user.email) &&
                            <div class='score-control'>
                                <button class='minus-button' onClick={decreaseOneOneScore}>-</button>
                            </div>
                        }
                        
                    </div>
                    <div class='participant-doubles participant-doubles-2'>
                        <a class='participant-link' title='Show profile'>
                            <img class='participant-image' src='https://vban.org/images/player/player.png'></img>
                        </a>
                        <div class='participant-name'>
                            <a>{ getNamePlayerTwoFromPairId(games[0].pair_1.id) }</a>
                        </div>
                        {
                            user && admins.includes(user.email) &&
                            <div class='score-control'>
                                <button class='plus-button' onClick={increaseOneOneScore}>+</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div class='score'>
                <div class='start-time'>
                    <div>{moment().format("DD-MM-YYYY hh:mm:ss")}</div>
                </div>
                <div class='match-info'>
                    <div class='detail-score-wrapper'>
                        <div class='match-info-item'>
                            <span class='home-score'>{gameOnePairOneScore}</span>
                            <span class="detail-score-divider">-</span>
                            <span class='away-score'>{gameOnePairTwoScore}</span>
                        </div>
                    </div>
                    <div class='detail-score-status'>
                        <span class="detail-status">1ST GAME</span>
                    </div>
                </div>
            </div>
            <div class='away-area'>
                <div class='participant-doubles-wrapper'>
                    <div class='participant-doubles participant-doubles-1'>
                        <a class='participant-link' title='Show profile'>
                            <img class='participant-image' src='https://vban.org/images/player/player.png'></img>
                        </a>
                        <div class='participant-name'>
                            <a>{ getNamePlayerOneFromPairId(games[0].pair_2.id) }</a>
                        </div>
                        {
                            user && admins.includes(user.email) &&
                            <div class='score-control'>
                                <button class='minus-button' onClick={decreaseOneTwoScore}>-</button>
                            </div>
                        }
                    </div>
                    <div class='participant-doubles participant-doubles-2'>
                        <a class='participant-link' title='Show profile'>
                            <img class='participant-image' src='https://vban.org/images/player/player.png'></img>
                        </a>
                        <div class='participant-name'>
                            <a>{ getNamePlayerTwoFromPairId(games[0].pair_2.id) }</a>
                        </div>
                        {
                            user && admins.includes(user.email) &&
                            <div class='score-control'>
                                <button class='plus-button' onClick={increaseOneTwoScore}>+</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }

    function scoreboardControllerItOne(game) {
        return <div class='duel-participant'>
            <div class='home-area'>
                <div class='participant-doubles-wrapper'>
                    <div class='participant-doubles participant-doubles-1'>
                        <a class='participant-link' title='Show profile'>
                            <img class='participant-image' src='https://vban.org/images/player/player.png'></img>
                        </a>
                        <div class='participant-name'>
                            <a>{ getNamePlayerOneFromPairId(game.pair_1.id) }</a>
                        </div>
                        {
                            user && admins.includes(user.email) &&
                            <div class='score-control'>
                                <button class='minus-button' onClick={decreaseOneOneItScore}>-</button>
                            </div>
                        }
                        
                    </div>
                    <div class='participant-doubles participant-doubles-2'>
                        <a class='participant-link' title='Show profile'>
                            <img class='participant-image' src='https://vban.org/images/player/player.png'></img>
                        </a>
                        <div class='participant-name'>
                            <a>{ getNamePlayerTwoFromPairId(game.pair_1.id) }</a>
                        </div>
                        {
                            user && admins.includes(user.email) &&
                            <div class='score-control'>
                                <button class='plus-button' onClick={increaseOneOneItScore}>+</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div class='score'>
                <div class='start-time'>
                    <div>{moment().format("DD-MM-YYYY hh:mm:ss")}</div>
                </div>
                <div class='match-info'>
                    <div class='detail-score-wrapper'>
                        <div class='match-info-item'>
                            <span class='home-score'>{gameOnePairOneItScore}</span>
                            <span class="detail-score-divider">-</span>
                            <span class='away-score'>{gameOnePairTwoItScore}</span>
                        </div>
                    </div>
                    <div class='detail-score-status'>
                        <span class="detail-status">1ST GAME</span>
                    </div>
                </div>
            </div>
            <div class='away-area'>
                <div class='participant-doubles-wrapper'>
                    <div class='participant-doubles participant-doubles-1'>
                        <a class='participant-link' title='Show profile'>
                            <img class='participant-image' src='https://vban.org/images/player/player.png'></img>
                        </a>
                        <div class='participant-name'>
                            <a>{ getNamePlayerOneFromPairId(game.pair_2.id) }</a>
                        </div>
                        {
                            user && admins.includes(user.email) &&
                            <div class='score-control'>
                                <button class='minus-button' onClick={decreaseOneTwoItScore}>-</button>
                            </div>
                        }
                    </div>
                    <div class='participant-doubles participant-doubles-2'>
                        <a class='participant-link' title='Show profile'>
                            <img class='participant-image' src='https://vban.org/images/player/player.png'></img>
                        </a>
                        <div class='participant-name'>
                            <a>{ getNamePlayerTwoFromPairId(game.pair_2.id) }</a>
                        </div>
                        {
                            user && admins.includes(user.email) &&
                            <div class='score-control'>
                                <button class='plus-button' onClick={increaseOneTwoItScore}>+</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }

    function scoreboardControllerTwo(games) {
        if (levelId == 5) { return; }

        return <div class='duel-participant'>
            <div class='home-area'>
                <div class='participant-doubles-wrapper'>
                    <div class='participant-doubles participant-doubles-1'>
                        <a class='participant-link' title='Show profile'>
                            <img class='participant-image' src='https://vban.org/images/player/player.png'></img>
                        </a>
                        <div class='participant-name'>
                            <a>{ getNamePlayerOneFromPairId(games[0].pair_1.id) }</a>
                        </div>
                        {
                            user && admins.includes(user.email) &&
                            <div class='score-control'>
                                <button class='minus-button' onClick={decreaseTwoOneScore}>-</button>
                            </div>
                        }
                    </div>
                    <div class='participant-doubles participant-doubles-2'>
                        <a class='participant-link' title='Show profile'>
                            <img class='participant-image' src='https://vban.org/images/player/player.png'></img>
                        </a>
                        <div class='participant-name'>
                            <a>{ getNamePlayerTwoFromPairId(games[0].pair_1.id) }</a>
                        </div>
                        {
                            user && admins.includes(user.email) &&
                            <div class='score-control'>
                                <button class='plus-button' onClick={increaseTwoOneScore}>+</button>
                            </div>
                        }                   
                    </div>
                </div>
            </div>
            <div class='score'>
                <div class='start-time'>
                    <div>{moment().format("DD-MM-YYYY hh:mm:ss")}</div>
                </div>
                <div class='match-info'>
                    <div class='detail-score-wrapper'>
                        <div class='match-info-item'>
                            <span class='home-score'>{gameTwoPairOneScore}</span>
                            <span class="detail-score-divider">-</span>
                            <span class='away-score'>{gameTwoPairTwoScore}</span>
                        </div>
                    </div>
                    <div class='detail-score-status'>
                        <span class="detail-status">2ND GAME</span>
                    </div>
                </div>
            </div>
            <div class='away-area'>
                <div class='participant-doubles-wrapper'>
                    <div class='participant-doubles participant-doubles-1'>
                        <a class='participant-link' title='Show profile'>
                            <img class='participant-image' src='https://vban.org/images/player/player.png'></img>
                        </a>
                        <div class='participant-name'>
                            <a>{ getNamePlayerOneFromPairId(games[0].pair_2.id) }</a>
                        </div>
                        {
                            user && admins.includes(user.email) &&
                            <div class='score-control'>
                                <button class='minus-button' onClick={decreaseTwoTwoScore}>-</button>
                            </div>
                        }
                    </div>
                    <div class='participant-doubles participant-doubles-2'>
                        <a class='participant-link' title='Show profile'>
                            <img class='participant-image' src='https://vban.org/images/player/player.png'></img>
                        </a>
                        <div class='participant-name'>
                            <a>{ getNamePlayerTwoFromPairId(games[0].pair_2.id) }</a>
                        </div>
                        {
                            user && admins.includes(user.email) &&
                            <div class='score-control'>
                                <button class='plus-button' onClick={increaseTwoTwoScore}>+</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }

    function scoreboardControllerThree(games) {
        if (levelId == 5) { return; }

        return <div class='duel-participant'>
            <div class='home-area'>
                <div class='participant-doubles-wrapper'>
                    <div class='participant-doubles participant-doubles-1'>
                        <a class='participant-link' title='Show profile'>
                            <img class='participant-image' src='https://vban.org/images/player/player.png'></img>
                        </a>
                        <div class='participant-name'>
                            <a>{ getNamePlayerOneFromPairId(games[0].pair_1.id) }</a>
                        </div>
                        {
                            user && admins.includes(user.email) &&
                            <div class='score-control'>
                                <button class='minus-button' onClick={decreaseThreeOneScore}>-</button>
                            </div>
                        }
                    </div>
                    <div class='participant-doubles participant-doubles-2'>
                        <a class='participant-link' title='Show profile'>
                            <img class='participant-image' src='https://vban.org/images/player/player.png'></img>
                        </a>
                        <div class='participant-name'>
                            <a>{ getNamePlayerTwoFromPairId(games[0].pair_1.id) }</a>
                        </div>
                        {
                            user && admins.includes(user.email) &&
                            <div class='score-control'>
                                <button class='plus-button' onClick={increaseThreeOneScore}>+</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div class='score'>
                <div class='start-time'>
                    <div>{moment().format("DD-MM-YYYY hh:mm:ss")}</div>
                </div>
                <div class='match-info'>
                    <div class='detail-score-wrapper'>
                        <div class='match-info-item'>
                            <span class='home-score'>{gameThreePairOneScore}</span>
                            <span class="detail-score-divider">-</span>
                            <span class='away-score'>{gameThreePairTwoScore}</span>
                        </div>
                    </div>
                    <div class='detail-score-status'>
                        <span class="detail-status">3RD GAME</span>
                    </div>
                </div>
            </div>
            <div class='away-area'>
                <div class='participant-doubles-wrapper'>
                    <div class='participant-doubles participant-doubles-1'>
                        <a class='participant-link' title='Show profile'>
                            <img class='participant-image' src='https://vban.org/images/player/player.png'></img>
                        </a>
                        <div class='participant-name'>
                            <a>{ getNamePlayerOneFromPairId(games[0].pair_2.id) }</a>
                        </div>
                        {
                            user && admins.includes(user.email) &&
                            <div class='score-control'>
                                <button class='minus-button' onClick={decreaseThreeTwoScore}>-</button>
                            </div>
                        }
                    </div>
                    <div class='participant-doubles participant-doubles-2'>
                        <a class='participant-link' title='Show profile'>
                            <img class='participant-image' src='https://vban.org/images/player/player.png'></img>
                        </a>
                        <div class='participant-name'>
                            <a>{ getNamePlayerTwoFromPairId(games[0].pair_2.id) }</a>
                        </div>
                        {
                            user && admins.includes(user.email) &&
                            <div class='score-control'>
                                <button class='plus-button' onClick={increaseThreeTwoScore}>+</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }

    const increaseOneOneItScore = () => {
        if (gameOnePairOneItScore >=31) { return; }

        gameOnePairOneItScoreRef.transaction((currentScore) => (currentScore || 0) + 1);
    };

    const decreaseOneOneItScore = () => {
        if (gameOnePairOneItScore > 0) {
            gameOnePairOneItScoreRef.transaction((currentScore) => (currentScore || 0) - 1);
        }
    };

    const increaseOneTwoItScore = () => {
        if (gameOnePairTwoItScore >=31) { return; }

        gameOnePairTwoItScoreRef.transaction((currentScore) => (currentScore || 0) + 1);
    };

    const decreaseOneTwoItScore = () => {
        if (gameOnePairTwoItScore > 0) {
            gameOnePairTwoItScoreRef.transaction((currentScore) => (currentScore || 0) - 1);
        }
    };

    const increaseOneOneScore = () => {
        if (levelId == 5 && gameOnePairOneScore >=31) { return; }
        if (levelId != 5 && gameOnePairOneScore >=21) { return; }

        gameOnePairOneScoreRef.transaction((currentScore) => (currentScore || 0) + 1);
    };

    const decreaseOneOneScore = () => {
        if (gameOnePairOneScore > 0) {
            gameOnePairOneScoreRef.transaction((currentScore) => (currentScore || 0) - 1);
        }
    };

    const increaseOneTwoScore = () => {
        if (levelId == 5 && gameOnePairTwoScore >=31) { return; }
        if (levelId != 5 && gameOnePairTwoScore >=21) { return; }

        gameOnePairTwoScoreRef.transaction((currentScore) => (currentScore || 0) + 1);
    };

    const decreaseOneTwoScore = () => {
        if (gameOnePairTwoScore > 0) {
            gameOnePairTwoScoreRef.transaction((currentScore) => (currentScore || 0) - 1);
        }
    };

    const increaseTwoOneScore = () => {
        if (levelId == 5 && gameTwoPairOneScore >=31) { return; }
        if (levelId != 5 && gameTwoPairOneScore >=21) { return; }

        gameTwoPairOneScoreRef.transaction((currentScore) => (currentScore || 0) + 1);
    };

    const decreaseTwoOneScore = () => {
        if (gameTwoPairOneScore > 0) {
            gameTwoPairOneScoreRef.transaction((currentScore) => (currentScore || 0) - 1);
        }
    };

    const increaseTwoTwoScore = () => {
        if (levelId == 5 && gameTwoPairTwoScore >=31) { return; }
        if (levelId != 5 && gameTwoPairTwoScore >=21) { return; }

        gameTwoPairTwoScoreRef.transaction((currentScore) => (currentScore || 0) + 1);
    };

    const decreaseTwoTwoScore = () => {
        if (gameTwoPairTwoScore > 0) {
            gameTwoPairTwoScoreRef.transaction((currentScore) => (currentScore || 0) - 1);
        }
    };

    const increaseThreeOneScore = () => {
        if (levelId == 5 && gameThreePairOneScore >=31) { return; }
        if (levelId != 5 && gameThreePairOneScore >=21) { return; }

        gameThreePairOneScoreRef.transaction((currentScore) => (currentScore || 0) + 1);
    };

    const decreaseThreeOneScore = () => {
        if (gameThreePairOneScore > 0) {
            gameThreePairOneScoreRef.transaction((currentScore) => (currentScore || 0) - 1);
        }
    };

    const increaseThreeTwoScore = () => {
        if (levelId == 5 && gameThreePairTwoScore >=31) { return; }
        if (levelId != 5 && gameThreePairTwoScore >=21) { return; }

        gameThreePairTwoScoreRef.transaction((currentScore) => (currentScore || 0) + 1);
    };

    const decreaseThreeTwoScore = () => {
        if (gameThreePairTwoScore > 0) {
            gameThreePairTwoScoreRef.transaction((currentScore) => (currentScore || 0) - 1);
        }
    };

    function getFlagFromPairId(pair_id) {
        if (tournamentId == 2 && levelId == 1) {
            switch(pair_id) {
                case 4:
                    return 'japan';
                case 5:
                    return 'china';
                case 1:
                case 10:
                case 11:
                    return 'indonesia';
                case 7:
                case 8:
                    return 'india';
                default:
                    return 'vietnam';
            }
        }

        if (tournamentId == 2 && levelId == 2) {
            switch(pair_id) {
                case 12:
                case 13:
                case 14:
                    return 'india';
                default:
                    return 'vietnam';
            }
        }

        if (levelId == 1) {
            switch(pair_id) {
                case 3:
                    return 'japan';
                case 4:
                    return 'america';
                case 5:
                case 6:
                    return 'china';
                case 8:
                    return 'indonesia';
                default:
                    return 'vietnam';
            }
        } else {
            const pair = tournament.level[levelId].draw[pair_id]
            const player_id_1 = pair.player_id_1;
            const player_id_2 = pair.player_id_2;

            const player_1_team = players[player_id_1].team_id;
            const player_2_team = players[player_id_2].team_id;

            if (player_1_team == player_2_team) {
                return getFlagFromTeamId(player_1_team);
            } else {
                return 'lienquan'
            }
        }
    }

    function getFlagFromTeamId(team_id) {
        switch(team_id) {
            case '0':
            case 0:
                return 'tudo';
            case '1':
            case 1:
                return 'vietnambado';
            case '2':
            case 2:
                return 'edogawa';
            case '3':
            case 3:
                return 'yokohama';
            case '4':
            case 4:
                return 'shinmatsudo';
            case '5':
            case 5:
                return 'ebina';
            case '6':
            case 6:
                return 'yamanashi';
            case '7':
            case 7:
                return 'kokubunji';
            case '8':
            case 8:
                return 'kyoto';
            case '9':
            case 9:
                return 'shizuoka';
            case '10':
            case 10:
                return 'techminton';
            case '11':
            case 11:
                return 'kanuma';
            case '12':
            case 12:   
                return 'nishioaichi';
            case '13':
            case 13:
                return 'ishioka';
            case '14':
            case 14:
                return 'omiya';
            case '15':
            case 15:
                return 'gifu';
            case '16':
            case 16:
                return 'fukayaebina';
            case '17':
            case 17:
                return 'kawaguchi';
            case '18':
            case 18:   
                return 'tochigi';
            case '19':
            case 19:
                return 'chibad';
            case '20':
            case 20:
                return 'nagoya';
            case '21':
            case 21:
                return 'misato';
            case '22':
            case 22:
                return 'okazakiaichi';
            case '23':
            case 23:
                return 'nagano';
            default:
                return 'lienquan';
        }
    }

    if (tournament && tournament.level) {
        const games = tournament.level[levelId].matches[matchId].games;
        let itgame = "";

        if (tournamentId == 2) {
            itgame = tournament.level[levelId].matches[matchId];
        }

        return <div class='match-page'>
            {
                user && admins.includes(user.email) &&
                <div class='match-page-livestream'>
                    <div class='game-1'>
                        { tournamentId == 2 ? gameItOne(itgame) : gameOne(games) }
                    </div>
                    <div class='game-2'>
                        { tournamentId == 2 ? '' : gameTwo(games) }
                    </div>
                    <div class='game-3'>
                        { tournamentId == 2 ? '' : gameThree(games) }
                    </div>
                </div>
            }
            <div class='scoreboard-controller'>
                <div class='match-name'>
                    {tournament.level[levelId].name}
                </div>
                <div class='game-1'>
                    <div class='game-1-title'>
                        <span>Game 1</span>
                    </div>
                    { tournamentId == 2 ? scoreboardControllerItOne(itgame) : scoreboardControllerOne(games) }
                </div>
                {
                    tournamentId != 2 && levelId != 5 &&
                    <div class='game-2'>
                        <div class='game-2-title'>
                            <span>Game 2</span>
                        </div>
                        { scoreboardControllerTwo(games) }
                    </div>
                }
                {
                    tournamentId != 2 && levelId != 5 &&
                    <div class='game-3'>
                        <div class='game-3-title'>
                            <span>Game 3</span>
                        </div>
                        { scoreboardControllerThree(games) }
                    </div>
                }
            </div>
        </div>;
    }

    return <div></div>;

}

export default MatchDetailPage;
