import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form, InputGroup } from "react-bootstrap";
import styles from "./Scoreboard.module.scss"
import { useIntl } from 'react-intl';
import messages from './messages';
import commonMessages from '../../common/messages';
import ScoreItem from '../ScoreItem';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUserFriends, faRedoAlt, faExchangeAlt, faEdit, faPlusSquare } from '@fortawesome/free-solid-svg-icons'

const SIDES = {
    LEFT: "LEFT",
    RIGHT: "RIGHT"
}
const INIT_SCORE = {
    [SIDES.LEFT]: 0,
    [SIDES.RIGHT]: 0,
};
function Scoreboard() {
    const intl = useIntl();
    const [showChangeEnds, setShowChangeEnds] = useState(false);
    const [showNameEdit, setShowNameEdit] = useState(false);
    const [serve, setServe] = useState(SIDES.LEFT);
    const [score, setScore] = useState(INIT_SCORE);
    const [players, setPlayers] = useState({
        [SIDES.LEFT]: ["P1", "P2"],
        [SIDES.RIGHT]: ["P3", "P4"],
    });
    const [teams, setTeams] = useState({
        [SIDES.LEFT]: intl.formatMessage(messages.team, { team: 1 }),
        [SIDES.RIGHT]: intl.formatMessage(messages.team, { team: 2 }),
    });
    const [editTeams, setEditTeams] = useState({
        [SIDES.LEFT]: intl.formatMessage(messages.team, { team: 1 }),
        [SIDES.RIGHT]: intl.formatMessage(messages.team, { team: 2 }),
    });
    const [editPlayers, setEditPlayers] = useState({
        [SIDES.LEFT]: [],
        [SIDES.RIGHT]: [],
    })
    const [games, setGames] = useState([]);
    function onClick(side) {
        setServe(side);
        const newScore = Object.assign({}, score);
        newScore[side] = newScore[side] + 1;
        setScore(newScore);
    }
    function onNew() {
        const newGames = games.slice();
        newGames.push({
            [SIDES.LEFT]: {
                players: players[SIDES.LEFT],
                score: score[SIDES.LEFT]
            },
            [SIDES.RIGHT]: {
                players: players[SIDES.RIGHT],
                score: score[SIDES.RIGHT]
            }
        })
        setGames(newGames);
        onReset();
    }
    function onReset() {
        setServe(SIDES.LEFT);
        setScore(INIT_SCORE);
    }
    function onChangeEnds(keepScore) {
        const newPlayers = {
            [SIDES.LEFT]: players[SIDES.RIGHT],
            [SIDES.RIGHT]: players[SIDES.LEFT],
        }
        setPlayers(newPlayers);
        if (keepScore) {
            const newScore = {
                [SIDES.LEFT]: score[SIDES.RIGHT],
                [SIDES.RIGHT]: score[SIDES.LEFT],
            }
            setScore(newScore);
        } else {
            setScore(INIT_SCORE);
        }
        handleClose();
    }
    function handleClose() {
        setShowChangeEnds(false);
    }
    function keepScore() {
        onChangeEnds(true);
    }
    function resetScore() {
        onChangeEnds(false);
    }
    function showChangeEndModal() {
        setShowChangeEnds(true);
    }
    function switchServe() {
        if (score[SIDES.LEFT] > 0 || score[SIDES.RIGHT] > 0) {
            //Do nothing
        } else {
            if (serve === SIDES.LEFT) {
                setServe(SIDES.RIGHT);
            } else if (serve === SIDES.RIGHT) {
                setServe(SIDES.LEFT);
            }
        }
    }
    function onEdit() {
        setEditPlayers(players);
        setEditTeams(teams);
        setShowNameEdit(true);
    }
    function newInstance(ob) {
        return JSON.parse(JSON.stringify(ob));
    }
    function onConfirmEdit() {
        setShowNameEdit(false);
        setPlayers(newInstance(editPlayers));
        setTeams(newInstance(editTeams));
    }

    function onCloseNameEdit() {
        setShowNameEdit(false);
    }
    function onInputChange(side, index, val = "") {
        const newPlayers = JSON.parse(JSON.stringify(editPlayers));
        newPlayers[side][index] = val;
        setEditPlayers(newPlayers);
    }
    function onEditTeamChange(side, val = "") {
        const newTeams = JSON.parse(JSON.stringify(editTeams));
        newTeams[side] = val;
        setEditTeams(newTeams);
    }
    function onSingle() {
        setPlayers({
            [SIDES.LEFT]: ["P1"],
            [SIDES.RIGHT]: ["P2"],
        });
        onReset();
    }
    function onDouble() {
        setPlayers({
            [SIDES.LEFT]: ["P1", "P2"],
            [SIDES.RIGHT]: ["P3", "P4"],
        });
        onReset();
    }
    return <div className={styles.Scoreboard}>
        <div className="mb-2">
            <Button variant="primary" size="lg" onClick={onSingle} className={styles.Button}>
                <FontAwesomeIcon icon={faUser} />
            </Button>
            <Button variant="primary" size="lg" onClick={onDouble} className={styles.Button}>
                <FontAwesomeIcon icon={faUserFriends} />
            </Button>
            <Button variant="primary" size="lg" onClick={onNew} className={styles.Button}>
                <FontAwesomeIcon icon={faPlusSquare} />
            </Button>
            <Button variant="primary" size="lg" onClick={onReset} className={styles.Button}>
                <FontAwesomeIcon icon={faRedoAlt} />
            </Button>
            <Button variant="primary" size="lg" onClick={showChangeEndModal} className={styles.Button}>
                <FontAwesomeIcon icon={faExchangeAlt} />
            </Button>
            <Button variant="primary" size="lg" onClick={onEdit} className={styles.Button}>
                <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Button disabled={score[SIDES.LEFT] > 0 || score[SIDES.RIGHT] > 0} variant="primary" size="lg"
                onClick={switchServe} className={styles.Button}>
                {intl.formatMessage(messages.changeServe)}
            </Button>
        </div>
        <ScoreItem team={teams[SIDES.LEFT]} players={players[SIDES.LEFT]} score={score[SIDES.LEFT]} serve={serve === SIDES.LEFT}
            onClick={() => onClick(SIDES.LEFT)} />
        <ScoreItem team={teams[SIDES.RIGHT]} players={players[SIDES.RIGHT]} score={score[SIDES.RIGHT]} serve={serve === SIDES.RIGHT}
            onClick={() => onClick(SIDES.RIGHT)} />

        <hr />
        <Table striped bordered hover variant="dark">
            <thead>
                <tr>
                    <th>#</th>
                    <th>{teams[SIDES.LEFT]}</th>
                    <th>{teams[SIDES.RIGHT]}</th>
                    <th>{intl.formatMessage(commonMessages.score)}</th>
                </tr>
            </thead>
            <tbody>
                {games.map((g, index) => <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{g[SIDES.LEFT].players.map((p, i) => <div key={i}>{p}</div>)}</td>
                    <td>{g[SIDES.RIGHT].players.map((p, i) => <div key={i}>{p}</div>)}</td>
                    <td>{g[SIDES.LEFT].score}-{g[SIDES.RIGHT].score}</td>
                </tr>)}
            </tbody>
        </Table>
        <Modal show={showChangeEnds} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Change Ends</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {intl.formatMessage(commonMessages.cancel)}
                </Button>
                <Button variant="primary" onClick={keepScore}>
                    {intl.formatMessage(messages.keepScore)}
                </Button>
                <Button variant="primary" onClick={resetScore}>
                    {intl.formatMessage(messages.resetScore)}
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={showNameEdit}
            onHide={onCloseNameEdit}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    {intl.formatMessage(messages.editNames)}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>@</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        className="textFeedback"
                        as="input"
                        placeholder={intl.formatMessage(messages.playerName)}
                        value={editTeams[SIDES.LEFT]}
                        onChange={e => onEditTeamChange(SIDES.LEFT, e.target.value)}
                        type="text"
                        isInvalid={!editTeams[SIDES.LEFT]}
                    />
                </InputGroup>
                {editPlayers[SIDES.LEFT].map((p, i) => <Form.Control key={i}
                    className="textFeedback"
                    as="input"
                    placeholder={intl.formatMessage(messages.playerName)}
                    value={p}
                    onChange={e => onInputChange(SIDES.LEFT, i, e.target.value)}
                    type="text"
                />)}
                <hr />
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>@</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        className="textFeedback"
                        as="input"
                        placeholder={intl.formatMessage(messages.playerName)}
                        value={editTeams[SIDES.RIGHT]}
                        onChange={e => onEditTeamChange(SIDES.RIGHT, e.target.value)}
                        type="text"
                        isInvalid={!editTeams[SIDES.RIGHT]}
                    />
                </InputGroup>
                {editPlayers[SIDES.RIGHT].map((p, i) => <Form.Control key={i}
                    className="textFeedback"
                    as="input"
                    placeholder={intl.formatMessage(messages.playerName)}
                    value={p}
                    onChange={e => onInputChange(SIDES.RIGHT, i, e.target.value)}
                    type="text"
                />)}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCloseNameEdit}>
                    {intl.formatMessage(commonMessages.cancel)}
                </Button>
                <Button variant="primary" onClick={onConfirmEdit}
                    disabled={!editTeams[SIDES.LEFT] || !editTeams[SIDES.RIGHT]}>
                    {intl.formatMessage(commonMessages.ok)}
                </Button>
            </Modal.Footer>
        </Modal>
    </div>;

}

export default Scoreboard;