import React, { useState, useEffect } from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import firebase from './Firebase';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { LANG_KEY } from "./lib/constants";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { IntlProvider } from "react-intl";

//screens
// import Home from './components/Home';
import Profile from './components/Profile';
import SignInOrUp from './components/SignInOrUp';
import SignUp from './components/SignUp';
import HomePage from './components/HomePage';
import TeamPage from './components/TeamPage';
import TournamentPage from './components/TournamentPage';
import TournamentDraw from './components/TournamentDraw';
import TournamentDrawInternational from './components/TournamentDrawInternational';
import TournamentDrawMenOne from './components/TournamentDrawMenOne';
import TournamentDrawMenTwo from './components/TournamentDrawMenTwo';
import TournamentDrawMenThree from './components/TournamentDrawMenThree';
import TournamentDrawMixFour from './components/TournamentDrawMixFour';
import TournamentDrawMixOne from './components/TournamentDrawMixOne';
import TournamentDrawMixTwo from './components/TournamentDrawMixTwo';
import PlayerDetailPage from './components/PlayerDetailPage';
import MatchDetailPage from './components/MatchDetailPage';
import IncomingMatchPage from './components/IncomingMatchPage';
import DashboardPage from './components/DashboardPage';
import EventsPage from './components/EventsPage';
import EventDetailPage from './components/EventDetailPage';
import RealtimeScoreboard from './components/RealtimeScoreboard';
import Calendar from './components/Calendar';

import messageEn from './i18n/en.json';
import messageJa from './i18n/ja.json';
import messageVn from './i18n/vi.json';

import Auth from './Auth';


import { Provider } from 'react-redux'
// import { createStore } from 'redux'
import configureStore from './redux/configureStore'
// import { useIntl } from 'react-intl';

import history from "./lib/utils/router-utils";
import Header from "./components/Header";
import Footer from "./components/Footer"
import CourtManagePage from './components/CourtManagePage';
import CurrentMatchDetailPage from './components/CurrentMatchDetailPage';
const store = configureStore();

function App(props) {
    const [language, setLanguage] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState(null);
    useEffect(() => {
        const localLang = localStorage.getItem(LANG_KEY);
        if (localLang) {
            setSelectedLanguage(localLang)
        }
    }, []);

    const getLanguage = () => selectedLanguage || navigator.userLanguage || (navigator.languages && navigator.languages.length && navigator.languages[0]) || navigator.language || navigator.browserLanguage || navigator.systemLanguage || 'en';

    function getLocale(lang = "en") {
        if (lang.toUpperCase().indexOf("JA") > -1 || lang.toUpperCase().indexOf("JP") > -1) {
            return "ja";
        }
        if (lang.toUpperCase().indexOf("VN") > -1 || lang.toUpperCase().indexOf("VI") > -1) {
            return "vi";
        }
        return "en";
    }
    function getMessage(lang = "en") {
        if (lang.toUpperCase().indexOf("JA") > -1 || lang.toUpperCase().indexOf("JP") > -1) {
            return messageJa;
        }
        if (lang.toUpperCase().indexOf("VN") > -1 || lang.toUpperCase().indexOf("VI") > -1) {
            return messageVn;
        }
        return messageEn;
    }

    function handleClose() {
        if (language) {
            setSelectedLanguage(language);
            localStorage.setItem(LANG_KEY, language);
        }
    }

    function onLangChanged(lang) {
        setLanguage(lang);
    }
    const lang = getLanguage();

    const modal = <Modal show={!selectedLanguage}>
        <Modal.Header closeButton>
            <Modal.Title>Ngôn ngữ (言語)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <div className="mb-3">
                    <Form.Check onChange={() => onLangChanged("vn")} checked={language === "vn"} label="Tiếng Việt" type={'radio'} id={`inline-${'radio'}-1`} />
                    <Form.Check onChange={() => onLangChanged("jp")} checked={language === "jp"} label="日本語" type={'radio'} id={`inline-${'radio'}-2`} />
                </div>
            </Form>

        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={handleClose} disabled={!language}>
                OK
            </Button>
        </Modal.Footer>
    </Modal>;

    return (
        <IntlProvider locale={getLocale(lang)} messages={getMessage(lang)} defaultLocale="en">
            <Provider store={store}>
                <Router history={history}>
                    <Header />
                    {modal}
                    <Switch>
                        <Route exact path="/signin" component={SignInOrUp} />
                        <Route exact path="/signup" component={SignUp} />
                        <Route exact path="/homepage" component={HomePage} />
                        <Route exact path="/dashboard" component={DashboardPage} />
                        <Route exact path="/homepage/:teamId" component={TeamPage} />
                        <Route exact path="/events/:eventId" component={EventDetailPage} />
                        <Route exact path="/events" component={EventsPage} />
                        <Route exact path="/calendar" component={Calendar} />
                        <Route exact path="/tournaments/:tournamentId" component={TournamentPage} />
                        <Route exact path="/tournaments/:tournamentId/draw" component={TournamentDraw} />
                        <Route exact path="/tournament-draw-international" component={TournamentDrawInternational} />
                        <Route exact path="/tournaments/:tournamentId/draw-men-one" component={TournamentDrawMenOne} />
                        <Route exact path="/tournaments/:tournamentId/draw-men-two" component={TournamentDrawMenTwo} />
                        <Route exact path="/tournament-draw-men-three" component={TournamentDrawMenThree} />
                        <Route exact path="/tournament-draw-mix-four" component={TournamentDrawMixFour} />
                        <Route exact path="/tournament-draw-mix-one" component={TournamentDrawMixOne} />
                        <Route exact path="/tournament-draw-mix-two" component={TournamentDrawMixTwo} />
                        <Route exact path="/tournaments/:tournamentId/levels/:level_id/matches/:match_id" component={MatchDetailPage} />
                        <Route exact path="/tournaments/:tournamentId/incoming-matches" component={IncomingMatchPage} />
                        <Route exact path="/tournaments/:tournamentId/courts/:courtId" component={CurrentMatchDetailPage} />
                        {/* 以下認証のみ */}
                        <Auth>
                            <Switch>
                                <Route exact path="/" component={HomePage} />
                                <Route exact path="/profile" component={Profile} />
                                <Route exact path="/players/:playerid" component={PlayerDetailPage} />
                                <Route exact path="/tournaments/:tournamentId/courts" component={CourtManagePage} />
                                <Route exact path="/scoreboard" component={RealtimeScoreboard} />
                                <Route render={() => <p>not found.</p>} />
                            </Switch>
                        </Auth>
                    </Switch>
                    <Footer />
                </Router>
            </Provider>
            <ToastContainer
                position="top-left"
                hideProgressBar={true}
                autoClose={2000}
                theme="dark"
            />
        </IntlProvider>
    );
}
export default App;
